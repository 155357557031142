.unremoveable-header-container {
  .back-arrow {
    position: absolute;
    left: 0;
    top: 0;
    margin: 25px;
    font-size: 25px;
    cursor: pointer;
  }
}

.user-info-dialog-footer-content {
  .p-button:enabled:active,
  .p-button:enabled:focus {
    background: rgb(54, 39, 228);
    color: white;
  }
  .no-icon {
    margin-right: 0rem !important;
  }

  .p-button {
    background: rgb(54, 39, 228);
  }

  button {
    // margin: 0rem !important;
    @media screen and (max-width: 1000px) {
      width: 100% !important;
    }
  }
}

.user-info-container {
  .p-calendar,
  .p-dropdown,
  .p-inputnumber {
    width: 100%;
  }

  .input-container {
    padding-bottom: 1rem;

    > label {
      padding-bottom: 0.5rem;
      margin-bottom: 0rem;
    }

    .phone-input {
      input {
        width: 100%;
        height: 56px;
        border: 1px solid rgba(0, 0, 0, 0.38);
      }
      .form-control:focus {
        box-shadow: 0 0 0 1px #3f51b5;
      }
    }
  }
}

.p-dialog-header {
  color: rgba(0, 0, 0, 0.87) !important;
}

.smart-investing-dialog {
  .smart-investing-container {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    .single-investing-option {
      color: white !important;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      > p:nth-child(1) {
        display: flex;
        align-items: center;
        gap: 1rem;

        font-size: 1.8125rem;
        font-weight: 700;
        flex-wrap: wrap;
      }

      > p:nth-child(2) {
        color: rgba(255, 255, 255, 0.5);
        font-size: 0.875rem;
        font-weight: 400;
      }

      button {
        width: 100%;
      }
    }
  }

  .p-dialog-header {
    background: #3627e4 !important;
    color: white !important;
  }

  .p-dialog-content {
    background: linear-gradient(
      180deg,
      #3627e4 30.73%,
      #0096c6 100%
    ) !important;
    color: white !important;
    padding-bottom: 2.5rem !important;
  }
}
.p-component-overlay {
  backdrop-filter: blur(20px);
}
