@import '../../reuse/mixins.scss';
@import '../../reuse/variables.scss';
.forecast-container {
  .toggle-icons-container {
    @include flex(row, flex-end, center);
    margin-bottom: 15px;
    .toggle-icon {
      width: 25px;
      height: 25px;
      cursor: pointer;
      margin-left: 10px;
    }
  }
  .forecast-table-title {
    font-family: 'Akkurat-Bold';
    color: #3627e4;
    text-align: center;
    font-size: 25px;
  }
  .forecast-table {
    .forecast-header {
      display: flex;
      .header {
        font-family: 'Akkurat-Bold';
        font-size: 16px;
        width: 33%;
      }
      .header1 {
        font-family: 'Akkurat-Bold';
        font-size: 16px;
        text-align: center;
        width: 33%;
      }
      .header2 {
        font-family: 'Akkurat-Bold';
        font-size: 16px;
        width: 33%;
        text-align: right;
      }
      .header2-center {
        font-family: 'Akkurat-Bold';
        font-size: 16px;
        width: 33%;
        padding-right: 5%;
        text-align: right;
      }
    }
    .grey-background {
      background-color: rgba(146, 149, 161, 0.1);
      padding: 10px 0;
      margin-bottom: 5px;
    }
    .forecast-row {
      display: flex;
      .forecast-row-label {
        font-size: 16px;
        font-family: 'Akkurat-Light';
        width: 33%;
      }
      .forecast-row-value {
        font-size: 16px;
        width: 33%;
        text-align: center;
        font-family: 'Akkurat-Light';
        .arrow {
          margin-bottom: 3px;
          margin-left: 3px;
        }
      }
      .forecast-row-value-right {
        font-size: 16px;
        width: 33%;
        text-align: right;
        font-family: 'Akkurat-Light';
        .arrow {
          margin-bottom: 3px;
          margin-left: 3px;
        }
      }
      .forecast-row-value-right-center {
        font-size: 16px;
        width: 33%;
        text-align: right;
        font-family: 'Akkurat-Light';
        padding-right: 5%;
        .arrow {
          margin-bottom: 3px;
          margin-left: 3px;
        }
      }
      .percent-value {
        font-size: 16px;
        width: 33%;
        text-align: right;
      }
      .positive {
        color: $primary-color;
      }
      .negative {
        color: $negative-color;
      }
      .blue-dot {
        height: 20px;
        width: 20px;
        margin-left: 5px;
        cursor: pointer;
        background-color: $primary-color;
        border-radius: 50%;
        margin-bottom: 1rem;
        @include flex(row, center, center);

        .blue-dot-i {
          font-size: 10px;
          margin: 0;
          color: white;
        }
      }
    }
  }
  .financial-forecast-table {
    .financial-forecast-tabs {
      display: flex;
      width: 50%;
      margin: auto;
      justify-content: space-between;
      margin-bottom: 25px;
      @media only screen and (max-width: 769px) {
        width: 100%;
      }
    }
    .financial-table-data {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 35px;
      .item {
        min-width: 50px;
      }
      .header {
        font-family: 'Akkurat-Bold';
        font-size: 16px;
      }
      .header-right {
        font-family: 'Akkurat-Bold';
        font-size: 16px;
        width: 25%;
        text-align: right;
      }
      .header-center {
        font-family: 'Akkurat-Bold';
        font-size: 16px;
        width: 25%;
        text-align: center;
      }
      .forecast-row-value {
        font-size: 16px;
        width: 25%;
        text-align: center;
        font-family: 'Akkurat-Light';
        .arrow {
          margin-bottom: 3px;
          margin-left: 3px;
        }
      }
    }
    .financial-table-chart {
      width: 100%;
      overflow: auto;
      margin-top: 30px;
      @include flex(row, flex-start, center);

      .table-cols-label {
        padding-right: 10px;
        border-right: 1px solid rgba(128, 128, 128, 0.3);
        margin-top: 50px;
        .header {
          font-family: 'Akkurat-Bold';
          font-size: 16px;
          margin-bottom: 5px;
        }
      }

      .col-header {
        @include flex(row, space-between, flex-end);
        height: 50px;
        border-bottom: 1px solid rgba(128, 128, 128, 0.3);
        padding-bottom: 5px;
        .header {
          font-family: 'Akkurat-Bold';
          font-size: 16px;
          margin: 0 10px;
          width: 50px;
        }
      }

      .table-cols {
        @include flex(row, space-between, center);
        .table-col {
          margin: 0 10px;
          .header {
            font-family: 'Akkurat-Bold';
            font-size: 16px;
            margin: 0;
          }
          .table-value {
            margin-bottom: 5px;
            width: 50px;
            color: $primary-color;
          }

          .table-value-grey {
            margin-bottom: 5px;
            width: 50px;
            color: grey;
          }
        }
      }
    }
    .forecast-header {
      display: flex;
      .header {
        font-family: 'Akkurat-Bold';
        font-size: 16px;
        width: 25%;
      }
      .header-right {
        font-family: 'Akkurat-Bold';
        font-size: 16px;
        width: 25%;
        text-align: right;
      }
      .header-center {
        font-family: 'Akkurat-Bold';
        font-size: 16px;
        width: 25%;
        text-align: center;
      }
    }
    .grey-background {
      background-color: rgba(146, 149, 161, 0.1);
      padding: 10px 0;
      margin-bottom: 5px;
    }
    .forecast-row {
      display: flex;
      .forecast-row-label {
        font-size: 16px;
        width: 25%;
        font-family: 'Akkurat-Light';
      }
      .forecast-row-value {
        font-size: 16px;
        width: 25%;
        text-align: center;
        font-family: 'Akkurat-Light';
        .arrow {
          margin-bottom: 3px;
          margin-left: 3px;
        }
      }
      .forecast-row-value-right {
        font-size: 16px;
        width: 25%;
        text-align: right;
        font-family: 'Akkurat-Light';
        .arrow {
          margin-bottom: 3px;
          margin-left: 3px;
        }
      }
      .percent-value {
        font-size: 16px;
        width: 25%;
        text-align: right;
      }
      .positive {
        color: $primary-color;
      }
      .negative {
        color: $negative-color;
      }
    }
  }
  .risk-forecast-card {
    width: 100%;
    text-align: center;
    padding: 25px;
    border: 1px solid $primary-color;
    border-radius: 15px;
    margin: 15px 0;
    .risk-forecast-card-header {
    }
    .risk-forecast-card-content {
      .risk-forecast-card-bold {
        font-weight: bold;
      }
    }
  }
}

.risk-forecast-cards {
  display: flex;
  .forecast-chart-container {
    padding: 25px 15px 5px;
    margin-top: 5%;
    border-radius: 15px;
    display: flex;
    // border: 1px solid rgb(63, 70, 246);
    flex-direction: column;
    width: 50%;
    margin-bottom: 20px;
    justify-content: space-between;
    height: 200px;
    margin-left: 15px;
    box-shadow: rgb(255 255 255 / 92%) -3px -2px 4px,
      rgb(86 86 86 / 20%) 3px 1px 5px, rgb(255 255 255 / 21%) 0px 0px 2px inset;
    .forecast-chart-header {
      margin: 0px;
      font-size: 15px;
      font-family: Akkurat-Bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.75;
      letter-spacing: 0.56px;
      text-align: center;
      color: rgb(39, 39, 72);
    }
    .forecast-chart-bold {
      font-weight: bold;
      font-size: 22px;
      margin-right: 5px;
      margin-bottom: 0px;
      color: rgb(63, 70, 246);
      text-align: center;
    }
    .forecast-chart-wrapper {
      width: 100%;
      height: 250px;
    }
    @media only screen and (max-width: 1000px) {
      width: 100%;
    }
  }
  @media only screen and (max-width: 1000px) {
    flex-direction: column;
  }
}
