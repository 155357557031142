.sticky-table-table {
  width: 100%;
}

.watchlist-header-container {
  flex-wrap: wrap;

  @media screen and (max-width: 525px) {
    width: 100%;
    gap: 1rem;

    > div {
      width: 100% !important;
    }

    > div:first-child {
      margin-right: 0px !important;
    }

    > div:last-child {
      padding: 1rem 0 !important;
    }
  }
}
