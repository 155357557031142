.alpaca-migration-modal {
  width: 100%;
  max-width: 740px;
  .larger-text {
    font-size: 18px;
  }
  .alpaca-migration-additional-information {
    width: full;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: 20px;
    margin-bottom: 20px;
    .additional-information-input {
      width: 100%;
    }
  }
  .multiple-checkbox {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: 20px;
  }
  .agreement-container {
    label {
      color: #272748;
      font-size: 18px;
      font-weight: 400;
      margin-bottom: 1rem;
    }

    p {
      color: #272748;
      font-size: 13px;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 1rem;
    }

    a {
      display: flex;
      width: 100%;
      margin-bottom: 1rem;
    }
  }
  .checkbox-container {
    display: flex;
    align-items: start;
    gap: 0.5rem;
    // padding-bottom: 0.5rem;

    // padding-bottom: 1rem;
    background-color: #eee;
    padding: 12px;
    border-radius: 10px;
    label,
    li {
      color: #454545 !important;
      font-size: 14px !important;
    }
    > label {
      margin-bottom: 0rem;
      padding-bottom: 0rem;
    }
    ul {
      list-style-position: inside;
    }
    ul ol {
      margin-left: 12px;
    }
    .p-checkbox.p-component {
      margin-top: 6px;
    }
    .letter-list {
      list-style-type: lower-alpha;
      list-style-position: inside;
    }
  }
}
