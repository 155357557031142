@import '../reuse/mixins.scss';
@import '../reuse/variables.scss';

.timeout-page {
  width: 100vw;
  height: 100vh;
  @include flex(row, center, center);

  .message {
    font-size: 2rem;
    color: $primary-color;
    text-align: center;
    cursor: pointer;
  }
}
