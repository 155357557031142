.newt-main-container {
  width: 100%;
  height: 100%;
  display: flex;
}
.newt-thread-history-container {
  padding: 6px;
  max-width: 260px;
  position: absolute;
  top: 6px;
  left: 6px;
  z-index: 1;
  background-color: #f6f6f6;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  &.active {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    width: 100%;
  }
  .btns-container {
    display: flex;
    gap: 6px;
    .new-thread-icon-btn {
      border: none;
      background: linear-gradient(
        45deg,
        rgb(62, 77, 240) 58.48%,
        rgb(255, 73, 237) 86.77%
      );
      color: white;
      outline: none;
      height: 32px;
      aspect-ratio: 1;
      border-radius: 6px;
      padding-bottom: 2px;
    }
  }
  .toggle-side-menu-btn {
    border: none;
    outline: none;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    background-color: transparent; // Changed from 'none' to 'transparent'
    padding: 8px;
    border-radius: 6px;
    transition: background-color 0.5s; // Moved the transition property here
    &:hover {
      background-color: #c6c6c6;
    }
    img {
      height: 16px;
      object-fit: contain;
    }
  }
  .newt-threads-container {
    width: 100%;
    flex-grow: 1;
    overflow: auto;
    margin-top: 6px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    button {
      transition: background-color 0.5s; // Moved the transition property here
      border: none;
      outline: none;
      padding: 8px;
      border-radius: 8px;
      font-size: 14px;
      &.new-thread-btn {
        display: flex;
        justify-content: space-between;
        padding-right: 48px;
        align-items: center;
        background: linear-gradient(
          90deg,
          rgb(62, 77, 240) 58.48%,
          rgb(255, 73, 237) 112.77%
        );
        color: white;
      }
      &:hover {
        background-color: #c6c6c6;
      }
    }
  }
}

.new-thread-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: auto;
  margin-top: 20vh;
  .newt-home-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 12px;
  }
  .newt-example-prompt-container {
    display: flex;
    flex-direction: column;
  }
  .newt-categories-container {
    display: flex;
    width: 100%;
    gap: 12px;
    justify-content: center;
    flex-wrap: wrap;
    padding: 12px 0;
    button {
      border: none;
      border-radius: 16px;
      height: 24px;
      padding: 16px 24px;
      background-color: #e7e7e7;
      display: flex;
      align-items: center;
      justify-content: center;
      outline: none;
    }
  }
  .newt-prompts {
    width: 100%;
    flex-grow: 1;
    min-height: 1px;
    overflow: auto;
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
    padding: 12px;
    justify-content: center;
    .sample-prompt {
      background-color: #e2e6ee;
      border-radius: 6px;
      padding: 12px 24px;
      min-height: 100px;
      font-size: 14px;
      display: flex;
      gap: 6px;
      cursor: pointer;
      &:hover {
        background-color: #d2d4d7;
      }
    }
  }
}
.newt-input-container {
  width: 100%;
  display: flex;
  justify-content: center;
  .input-wrapper {
    width: 100%;
    max-width: 1000px;
    padding: 6px 20px;
    border: 1px solid gray;
    border-radius: 100px;
    display: flex;
    gap: 10px;
    background-color: #f6f6f6;
    input {
      width: 100%;
      border: none;
      outline: none;
      background-color: #f6f6f6;
    }
  }
}
.newt-rewrite-btn {
  width: 76px;
  font-size: 14px;
  border: none;
  background: transparent;
  border-radius: 64px;
  &:hover {
    background-color: #e9e9e9 !important;
  }
}
.small-circular-container {
  height: 36px;
  aspect-ratio: 1;
  border-radius: 40px;
  border: none;
  background-color: #e7e7e7;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.5s;
  &:hover {
    background-color: black;
    color: white;
  }
}
.newt-messages-container {
  display: flex;
  flex-direction: column;
  justify-content: start;
  flex: 1 1 auto;
  width: 100%;
  padding: 54px 10px 84px 10px;
  overflow: auto;
  gap: 12px;
  position: relative;
  .newt-floating-input-container {
    position: fixed;
    bottom: 20px;
    left: 0;
    width: 100%;
    box-sizing: border-box;
  }
  .chat-container {
    &.hidden {
      display: none;
    }

    &.active {
      height: 100px;
      min-height: 100px;
    }
  }
  .user-prompt-container {
    .prompt-message {
      font-size: 24px;
      color: #3627e4;
    }
    .loading-dots {
      display: inline-flex;
      gap: 0.25rem; /* gap-1 */
      animation: pulse 1s infinite;

      .dot {
        width: 6px; /* w-[6px] */
        height: 6px; /* h-[6px] */
        background-color: #3f46f6; /* bg-[#3F46F6] */
        border-radius: 50%; /* rounded-full */
      }
    }

    @keyframes pulse {
      0%,
      100% {
        opacity: 1;
      }
      50% {
        opacity: 0.5;
      }
    }
  }

  .container-button {
    border: 1px solid #e2e8f0;
    border-radius: 0.375rem; // Tailwind's rounded-md equivalent
    padding: 0.375rem; // Tailwind's p-1.5 equivalent
    background-color: #f7f7f7;
    font-size: 0.75rem; // Tailwind's text-xs equivalent
    outline: none;
  }

  .button-content {
    display: flex;
    align-items: center;
    gap: 0.25rem; // Tailwind's gap-1 equivalent
    color: #3f46f6; // Tailwind's text-primary equivalent
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border: none;
    background-color: transparent;
    &:hover {
      text-decoration: underline; // Tailwind's hover:underline equivalent
    }
  }

  .newt-response-container {
    background-color: white;
    border-radius: 8px;
    padding: 12px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    .newt-icon-content-container {
      display: flex;
      gap: 4px;
      .avatar-container {
        aspect-ratio: 1 / 1; /* aspect-square */
        border-radius: 50%; /* rounded-full */
        width: 2.5rem; /* w-10 */
        height: 2.5rem; /* h-10 */
        display: flex;
        justify-content: center;
        align-items: center;

        .avatar-image {
          object-fit: contain; /* object-contain */
          width: 2rem; /* w-8 */
        }
      }
    }
    .reponse-content {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding-top: 8px;
    }
    .response-header-container {
      display: flex;
      gap: 6px;
    }
    .response-content-container {
      width: 100%;
      // max-height: 100vh;
      overflow: auto;
      .newt-table-container {
        width: 100%;
        .action-buttons-container {
          display: flex;
          align-items: center;
          gap: 1rem;
          justify-content: space-between;
          padding: 1rem 0;
          font-size: 0.75rem; // Tailwind's text-xs equivalent
          flex-wrap: wrap;
        }

        .prompt-buttons {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          flex-wrap: wrap;
        }

        .button-wrapper {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          justify-content: flex-end;
          flex-wrap: wrap;
        }

        .full-width {
          width: 100%;
        }

        .download-button {
          display: flex;
          align-items: center;
          gap: 0.25rem; // Adjust the gap as per your design
        }

        .icon-small {
          font-size: 1rem; // Tailwind's text-base equivalent
        }
        .table-container {
          width: 100%;
          overflow-x: auto;
        }

        .table {
          width: 100%;
          font-size: 0.875rem; // Tailwind's text-sm equivalent
        }

        .table-head {
          font-weight: 600; // Tailwind's font-semibold equivalent
          letter-spacing: 0.05em; // Tailwind's tracking-wide equivalent
          text-align: left; // Tailwind's text-left equivalent
          color: #1a202c; // Tailwind's text-gray-900 equivalent
          background-color: #f7fafc; // Tailwind's bg-gray-100 equivalent
          text-transform: uppercase; // Tailwind's uppercase equivalent
        }

        .table-header-cell {
          padding: 0.75rem; // Tailwind's px-3 py-2 equivalent
          white-space: nowrap;
          border: 1px solid #e2e8f0; // Tailwind's border equivalent
        }

        .table-body {
          background-color: #ffffff; // Tailwind's bg-white equivalent
        }

        .table-row {
          border-bottom: 1px solid #e2e8f0; // Tailwind's border equivalent
        }

        .table-cell {
          padding: 0.75rem; // Tailwind's px-3 py-2 equivalent
          white-space: nowrap;
          border: 1px solid #e2e8f0; // Tailwind's border equivalent
        }

        .symbol-column {
          width: 150px; // Tailwind's w-[150px] equivalent
        }

        .price-up {
          color: #48bb78; // Tailwind's text-green-600 equivalent
        }

        .price-down {
          color: #f56565; // Tailwind's text-red-600 equivalent
        }

        .price-neutral {
          color: #718096; // Tailwind's text-gray-600 equivalent
        }

        .pagination-container {
          display: flex;
          justify-content: center;
          padding: 1rem 0; // Tailwind's py-2 equivalent
        }

        .pagination-button {
          border-radius: 9999px; // Tailwind's rounded-full equivalent
          display: flex;
          justify-content: center;
          align-items: center;
          transition: color 0.3s ease;
          &:disabled {
            color: #cbd5e0; // Tailwind's text-[#d3d3d3] equivalent
          }
        }
        .pagination-button-active {
          border: 1px solid #000 !important; // Tailwind's border border-black equivalent
          width: 2rem; // Tailwind's w-8 equivalent
          height: 2rem; // Tailwind's h-8 equivalent
          border-radius: 9999px;
          color: #3627e4;
        }
        .pagination {
          display: flex;
          align-items: center;
          gap: 1.5rem; // Tailwind's gap-6 equivalent
          button {
            border: none;
            width: 2rem; // Tailwind's w-8 equivalent
            height: 2rem; // Tailwind's h-8 equivalent
            border-radius: 9999px; // Tailwind's rounded-full equivalent
            outline: none;
            background-color: transparent;
          }
        }
      }
    }
  }
}

.grouped-data-content {
  display: flex;
  flex-direction: column;
  gap: 4rem; /* gap-16 */
  .group-level-title {
    font-size: 16px;
    font-weight: bold;
  }
  @media (max-width: 1024px) {
    flex-direction: column;
    gap: 4rem;
  }
  .level-container {
    display: flex;
    justify-content: space-between;
    gap: 2rem;

    @media (max-width: 1024px) {
      flex-direction: column;
      gap: 2rem;
    }
  }
  .level-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow: auto;
  }
  .newt-group-content-container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem; /* gap-2 */
    .group-title {
      font-size: 0.875rem; /* text-sm */
      background-color: #f6f8fc;
      padding: 0.5rem 0.75rem; /* py-2 px-3 */
      font-weight: 600; /* font-semibold */
    }

    .download-button {
      font-size: 1rem; /* text-base */
    }
    .table-container {
      width: 100%;
      overflow: auto;
      max-height: 500px;

      @media (max-width: 1024px) {
        max-height: auto;
      }
    }
    .news-container {
      display: flex;
      justify-content: space-between;
      padding-bottom: 1rem; /* pb-4 */
      gap: 1rem; /* gap-4 */
      border-bottom: 2px solid #f7f7f7; /* border-b-2 border-[#f7f7f7] */
      padding: 10px 0;
      @media (max-width: 1024px) {
        flex-direction: column; /* lg:flex-col */
      }

      .news-content {
        display: flex;
        flex-direction: column;
        gap: 2rem; /* gap-8 */
        flex: 2 1 0%;
        justify-content: space-between;

        @media (max-width: 1024px) {
          flex: none; /* lg:flex-none */
          gap: 0.5rem; /* lg:gap-2 */
        }
      }

      .news-link {
        display: flex;
        flex-direction: column;
        gap: 0.5rem; /* gap-2 */
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }

        .news-title,
        .news-text {
          white-space: normal; /* whitespace-normal */
          display: flex;
          tracking: tight;
          line-height: tight;
          overflow: hidden;
          text-overflow: ellipsis;

          @media (max-width: 1024px) {
            display: block;
          }
        }

        .news-title {
          font-size: 1rem; /* text-base */
          font-weight: bold; /* font-bold */
        }

        .news-text {
          font-size: 0.875rem; /* text-sm */
        }
      }

      .news-info {
        display: flex;
        justify-content: space-between;
        gap: 1rem; /* gap-4 */
        font-size: 0.75rem; /* text-xs */
        color: #808080; /* text-[#808080] */
        font-weight: 300; /* font-light */

        @media (max-width: 1024px) {
          display: none; /* lg:hidden */
        }
      }

      .news-image-link {
        max-width: 12.5rem; /* max-w-[200px] */
        max-height: 6.875rem; /* max-h-[110px] */
        flex: 1;
        overflow: hidden;
        border-radius: 0.375rem; /* rounded-md */

        @media (max-width: 1024px) {
          max-width: none; /* lg:max-w-none */
          max-height: none; /* lg:max-h-none */
        }

        .news-image {
          width: 100%; /* w-full */
          height: 100%; /* h-full */
          object-fit: cover; /* object-cover */
          object-position: top; /* object-top */
        }
      }

      .news-info-lg {
        display: none;
        justify-content: space-between;
        gap: 1rem; /* gap-4 */
        font-size: 0.75rem; /* text-xs */
        color: #808080; /* text-[#808080] */
        font-weight: 300; /* font-light */

        @media (max-width: 1024px) {
          display: flex; /* lg:flex */
        }
      }
    }

    .newt-table {
      width: 100%;
      font-size: 0.875rem; /* text-sm */

      .newt-table-header {
        font-weight: 600; /* font-semibold */
        letter-spacing: 0.05em; /* tracking-wide */
        text-align: left;
        color: #1f2937; /* text-gray-900 */
        background-color: #f3f4f6; /* bg-gray-100 */
        text-transform: uppercase;
        border-bottom: 1px solid #d1d5db; /* border */

        @media (max-width: 1024px) {
          text-transform: none;
        }
      }

      .newt-table-header-cell {
        padding: 0.5rem 0.75rem; /* px-3 py-2 */
        white-space: nowrap;
      }

      .newt-table-body {
        background-color: #ffffff; /* bg-white */
      }

      .newt-table-cell {
        padding: 0.5rem 0.75rem; /* px-3 py-2 */
        border-bottom: 1px solid #d1d5db; /* border */

        &.link-text {
          text-decoration: underline;
          color: #1d4ed8; /* text-primary */
        }

        @media (max-width: 1024px) {
          white-space: normal;
        }
      }
    }
    .structured-text-container {
      display: flex;
      flex-direction: column;
      gap: 0.75rem; /* Adds space between rows */

      .structured-text-row {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #e5e7eb; /* border-gray-200 */
        padding: 0.375rem 0.75rem; /* px-3 py-1.5 */
      }

      .structured-text-label,
      .structured-text-value {
        font-size: 0.875rem; /* text-sm */
      }
    }
    .trade-preview-redirect-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 320px;
      padding: 14px;
      border-radius: 14px;
      border: 2px solid #e0e0e0;
      background-color: white;
      &:hover {
        border: 2px solid #3f46f6;
        cursor: pointer;
      }
      .redirect-link-container {
        text-decoration: none;
        color: black;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 6px;
      }
      .top-container {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        .symbol-icon-text {
          display: flex;
          gap: 4px;
          align-items: center;
          p {
            margin-bottom: 0;
          }
          img {
            height: 52px;
            aspect-ratio: 1;
            object-fit: contain;
          }
          .no-symbol-icon {
            border-radius: 6px;
            background-color: #c6c6c6;
            height: 52px;
            aspect-ratio: 1;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
      .company-name,
      .current-price {
        width: 100%;
      }
      .company-name {
        color: #718096;
        font-size: 14px;
      }
      .current-price {
        font-size: 18px;
        display: flex;
        gap: 6px;
      }
      .trade-details {
        display: flex;
        flex-direction: column;
        gap: 4px;
        width: 100%;
        background: #ebebeb;
        border-radius: 12px;
        padding: 12px;
        .trade-detail {
          display: flex;
          justify-content: space-between;
          width: 100%;
        }
      }
      .execute-text {
        font-size: 14px;
        margin-top: 14px;
        text-align: center;
        color: #3f46f6;
        text-decoration: underline;
        display: flex;
        align-items: center;
      }
    }
  }
  .download-container {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    justify-content: flex-end;
    flex-wrap: wrap;

    @media (max-width: 1024px) {
      width: 100%; /* w-full */
    }
  }
}

.skeleton-container {
  display: flex;
  flex-direction: column;
  gap: 1rem; /* gap-4 */

  .skeleton-card {
    border-radius: 0.375rem; /* rounded-md */
    background-color: white; /* bg-white */
    padding: 12px; /* py-6 px-5 */
    height: auto; /* h-auto */
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .skeleton-content {
      display: flex;
      gap: 4px; /* gap-2 */
      flex-grow: 1;
      width: 100%; /* w-full */

      .avatar-container {
        aspect-ratio: 1 / 1; /* aspect-square */
        border-radius: 50%; /* rounded-full */
        width: 2.5rem; /* w-10 */
        height: 2.5rem; /* h-10 */
        display: flex;
        justify-content: center;
        align-items: center;

        .avatar-image {
          object-fit: contain; /* object-contain */
          width: 2rem; /* w-8 */
        }
      }

      .text-container {
        width: 100%; /* w-full */
        display: flex;
        flex-direction: column;
        gap: 0.75rem; /* gap-3 */
        overflow: hidden;
        padding-top: 0.375rem; /* pt-[6px] */
        font-size: 0.875rem; /* text-sm */

        .loading-animation {
          animation: pulse 2s infinite; /* animate-pulse */

          .short-line {
            height: 2rem; /* h-8 */
            background-color: #e5e7eb; /* bg-gray-200 */
            border-radius: 0.375rem; /* rounded-md */
            max-width: 40rem; /* max-w-[40rem] */
            margin-bottom: 1rem; /* mb-4 */
          }

          .long-line {
            height: 6rem; /* h-24 */
            background-color: #e5e7eb; /* bg-gray-200 */
            border-radius: 0.375rem; /* rounded-md */
            margin-bottom: 0.625rem; /* mb-2.5 */
          }
        }
      }
    }
  }
}
