@import '../reuse/mixins.scss';
@import '../reuse/variables.scss';

.promotions {
  @include flex(column, center, center);

  .promo-big-text {
    font-family: 'Akkurat-Bold';
    font-size: 26px;
  }

  .promo-medium-text {
    font-family: 'Akkurat-Bold';
    font-size: 20px;
  }

  .promo-small-text {
    font-size: 14px;
    font-family: 'Akkurat-Bold';
  }

  .promo-light-text {
    font-family: 'Akkurat-Light';
  }

  .right-text {
    text-align: right;
  }

  .no-margin {
    margin: 0;
  }

  .arrow-btn {
    font-size: 18px;
    color: rgba(128, 128, 128, 0.8);
    margin: 0;
    margin-left: 5px;
  }

  .promo-alert-text {
    font-size: 15px;
  }

  .promo-row {
    @include flex(row, space-between, center);
  }

  .promo-row-left {
    @include flex(row, flex-start, center);
    text-align: left;
  }

  .requirements {
    margin-top: 15px;
    .requirement {
      font-size: 15px;
    }
    p {
      margin: 0;
    }
  }

  .warning-icon {
    width: 15px;
    height: 15px;
    margin-right: 5px;
  }

  .promo {
    margin-top: 20px;
    text-align: center;

    .promo-input {
      height: 50px;
    }

    .promo-btn {
      width: 100px;
      height: 50px;
      border: none;
      border-radius: 15px;
      background-color: $primary-color;
      color: white;
      font-size: 15px;
      margin-left: 15px;
    }

    .message-block {
      margin-top: 30px;
    }
  }

  .rewards {
    margin-top: 20px;

    .reward-card {
      flex-direction: column;
    }
    .reward-text {
      text-align: center;
      font-size: 20px;
    }
  }

  .p-tabview .p-tabview-nav {
    border: none;
    margin: 0;
    background-color: transparent;
    display: flex;
    justify-content: center;
    width: 450px;
    @media screen and (max-width: 769px) {
      width: 300px;
    }
  }

  .p-tabview .p-tabview-panels {
    padding: 20px 0;
    background-color: transparent;
  }

  .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    text-decoration: none;
    background-color: transparent;
  }

  .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    background-color: transparent;
  }
}
