@import '../reuse/mixins.scss';
@import '../reuse/variables.scss';

// Text styles
.bold-title {
  font-size: 50px;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: -1.8px;
  &.small {
    font-size: 38px;
    font-weight: 400;
  }
  &.subtitle {
    font-size: 50px;
  }
  @media screen and (min-width: 1920px) {
    font-size: 70px;
  }
}
.header-title {
  font-size: 50px;
  letter-spacing: -2px;
  line-height: 50px;
  @media screen and (min-width: 1920px) {
    font-size: 65px;
    letter-spacing: -2px;
    line-height: 65px;
  }
}
.content {
  font-size: 21px;
  letter-spacing: -1px;
  line-height: 26px;
  font-family: 'Akkurat-Light';
  font-weight: lighter;
}
.sub-text {
  size: 10px;
  color: #4d4f5e;
}
.text-black {
  color: black;
}

// Spacing
.spacer {
  width: 100%;
  height: 400px;
}
.navbar-spacer {
  height: 75px;
  width: 100%;
}

// Round Blue button
.rounded-blue-button {
  height: 68px;
  width: 100%;
  background-color: #3627e4;
  font-size: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 900;
  color: white;
  border: none;
  border-radius: 68px;
  a:hover {
    text-decoration: none;
  }
  &.inverse {
    background-color: white;
    color: #3627e4;
  }
}

// Carousel Styles
.slider-container,
.ff-slider {
  .p-carousel-prev {
    display: none;
  }
  .p-carousel-next {
    display: none;
  }
  .p-carousel .p-carousel-indicators .p-carousel-indicator {
    margin-right: 0.25rem;
    margin-left: 0.25rem;
  }
  .p-carousel-indicator button {
    width: 0.5rem !important;
    height: 0.5rem !important;
    border-radius: 0.5rem !important;
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
  .p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
    background-color: #1a1d35;
  }
}

// DESKTOP STYLES: Desktop reordering
@media screen and (min-width: 1000px) {
  .white-container,
  .gradient-container {
    .desktop-row {
      display: flex;
      &.align-center {
        align-items: center;
      }
      &.justify-between {
        justify-content: space-between;
      }
      &.gap-100 {
        gap: 100px;
      }
      &.gap-20 {
        gap: 20px;
      }
    }
  }
}

.white-container {
  background-color: white;
  display: flex;
  justify-content: center;
  width: 100%;
  .header-section {
    max-width: 1920px;
    width: 100%;
    .inner-container {
      // border-radius: 30px;
      padding: 40px 50px;
      p {
        text-align: center;
        &:first-child {
          margin-bottom: 20px;
        }
        &:not(:first-child) {
          margin-bottom: 40px;
        }
      }
      .desktop-side {
        display: flex;
        flex: 1;
        flex-direction: column;
      }
      .get-app-wrapper {
        width: 100%;
      }
      .slider-container {
        margin-bottom: 40px;
        max-width: 300px;
        align-self: center;
        .p-carousel-indicators {
          padding-top: 0rem !important;
        }
        .outer-edge {
          border-radius: 30px 30px 0 30px;
          width: 100%;
          padding: 10px;
          .slider-content {
            border-radius: 26px 26px 0 26px;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 354px;
            background-color: #f6f6f6;
            text-align: center;
            gap: 10px;
            p {
              font-size: 30px;
              margin-bottom: 0;
            }
            .product-description {
              font-size: 16px;
              width: 80%;
            }
          }
        }
      }
    }
    // DESKTOP STYLES: .header-section
    @media screen and (min-width: 1000px) {
      .inner-container {
        color: #1a1d35;
        padding: 100px 200px;
        .slider {
          justify-content: center;
          margin-right: 40px;
          .slider-container {
            margin-bottom: 0px;
            align-self: end;
          }
        }
        .text p {
          text-align: left;
          max-width: 600px;
        }
        .get-app-wrapper {
          max-width: 350px;
        }
      }
    }
  }
}

.gradient-container {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 3%,
    rgba(220, 217, 255, 1) 25%,
    rgba(0, 150, 198, 1) 94%,
    rgba(255, 255, 255, 1) 100%
  );
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  .centered-content {
    max-width: 1920px;
    width: 100%;
  }
  // broker section
  .broker-section {
    padding: 8px;
    text-align: center;
    color: white;
    .partnered-brokers {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 40px;
    }
  }

  // hand section
  .hand-text-section {
    display: flex;
    flex-direction: column;
    gap: 40px;
    text-align: center;
    color: white;
    padding: 60px 40px;
    p {
      position: relative;
      z-index: 2;
    }
    .desktop-text {
      display: none;
    }
    .invest-wisely-text {
      &.mobile {
        margin-bottom: 50px;
      }
      &.desktop {
        display: none;
      }
    }
    .hand-animation-container {
      width: 100%;
      height: 600px;
      position: relative;
      z-index: 1;
      img {
        position: absolute;
        top: -133px;
        left: calc(50% + 230px);
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        width: 816.79px;
        height: 1200px;
      }
      .animation-container {
        height: 525px;
        width: 256px;
        position: absolute;
        top: 70px;
        left: calc(50% + 6px);
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        overflow: hidden;
        border-radius: 30px;
        video {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
    }
    .hand-animation-container-left-hand {
      width: 100%;
      height: 600px;
      position: relative;
      z-index: 1;
      img {
        position: absolute;
        top: 0;
        right: calc(50% + 163px);
        -webkit-transform: translateX(50%);
        transform: translateX(50%);
        width: 676px;
        height: 1046.77px;
      }
      .animation-container {
        height: 515px;
        width: 240px;
        position: absolute;
        top: 11px;
        right: calc(50% + 0px);
        transform: translateX(50%);
        overflow: hidden;
        border-radius: 30px;
        video {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
    }
    .card-container {
      display: flex;
      flex-direction: column;
      gap: 24px;
      z-index: 2;
      margin-top: 0px;
      &.desktop {
        display: none;
      }
      .feature-card {
        display: flex;
        gap: 20px;
        background-color: white;
        padding: 20px;
        border-radius: 24px;
        box-shadow: 0px 4px 4px 0px #00000040;
        &:hover {
          text-decoration: none;
        }
        .icon-side {
          display: flex;
          align-items: center;
          img {
            height: 50px;
            width: 50px;
            object-fit: contain;
          }
        }
        .text-side {
          color: black;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          text-align: left;
          font-size: 14px;
          .card-title {
            font-size: 18px;
          }
        }
      }
    }
    .buy-stocks-container {
      margin-top: 100px;
      max-width: 600px;
      &.desktop {
        display: none;
      }
      .buy-stocks-title {
        font-size: 50px;
        font-weight: 700;
      }
    }
    .stock-cards {
      display: flex;
      justify-content: center;
      gap: 12px;
      margin-top: 40px;
      .stock-card {
        height: 90px;
        width: 90px;
        border-radius: 8px;
        background-color: white;
        box-shadow: 0px 0px 8px 0px #00000020;
        color: black;
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        font-size: 12px;
        text-align: left;
        .ticker-icon-border {
          border-radius: 2px;
          height: 22px;
          width: 22px;
          min-width: 22px;
          max-width: 22px;
          min-height: 22px;
          max-height: 22px;
          border: 1px solid #f1eff2;
          overflow: hidden;
          display: flex;
          justify-content: center;
          align-items: center;
          .ticker-icon {
            height: 18px;
            width: 18px;
            object-fit: contain;
          }
        }
        .price-block {
          display: flex;
          align-items: center;
          border-radius: 2px;
          background-color: #6f6f6f;
          padding: 0 2px;
          color: white;
          gap: 4px;
          &.positive {
            background-color: #2dad00;
          }
          &.negative {
            background-color: #ff835b;
          }
        }
      }
    }
    // DESKTOP STYLES: .hand-text-section
    @media screen and (min-width: 1000px) {
      padding: 40px 200px;
      text-align: left;
      margin-top: 212px;
      .mobile-text {
        display: none;
      }
      .desktop-text {
        display: flex;
        margin: 40px 0;
      }
      .invest-wisely-text {
        &.mobile {
          display: none;
        }
        &.desktop {
          display: block;
        }
      }
      .text-content {
        flex: 4;
      }
      .hand-animation-container {
        flex: 2;
        position: relative;
        margin-top: -200px;
        img {
          position: absolute;
          top: 0;
          left: 0;
          transform: none;
          width: 808.41px;
          height: 1187.7px;
        }
        .animation-container {
          height: 519px;
          width: 253px;
          position: absolute;
          top: 201px;
          left: 57px;
          overflow: hidden;
          border-radius: 30px;
          transform: none;
          video {
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }
      }
      .hand-animation-container-left-hand {
        flex: 2;
        position: relative;
        img {
          position: absolute;
          top: 0;
          right: 0;
          transform: none;
          width: 727px;
          height: 1070px;
        }
        .animation-container {
          height: 526px;
          width: 257px;
          position: absolute;
          top: 12px;
          right: 60px;
          overflow: hidden;
          border-radius: 30px;
          transform: none;
          video {
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }
      }
      .card-container {
        &.mobile {
          display: none;
        }
        &.desktop {
          display: flex;
          max-width: 438px;
          margin-top: 40px;
        }
      }
      .buy-stocks-container {
        &.desktop {
          display: flex;
          flex-direction: column;
        }
      }
      .stock-cards {
        justify-content: start;
      }
    }
  }

  .future-finance-section {
    padding: 30px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    color: white;
    text-align: center;
    .ff-image-container {
      width: 100%;
      border-radius: 35px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .ff-slider {
      color: black;
      display: flex;
      flex-direction: column;
      gap: 20px;
      background-color: white;
      border-radius: 24px;
      padding: 20px;
      .ff-card {
        padding: 50px 0;
        display: flex;
        flex-direction: column;
        gap: 28px;
        background-color: white;
        color: black;
        font-size: 25px;
        height: 420px;
      }
    }
    .ff-title {
      display: none;
    }
    .info-box-container {
      display: none;
    }

    .free-trial-button {
      width: 100%;
    }

    .app-store-buttons {
      display: flex;
      gap: 12px;
      align-items: center;
      flex-direction: column;
      a,
      .icon-container {
        width: 100%;
        display: flex;
        cursor: pointer;
      }
      img {
        width: 100%;
        object-fit: contain;
        object-position: center;
      }
    }

    // DESKTOP STYLES
    @media screen and (min-width: 1000px) {
      align-items: center;
      margin-top: 100px;
      .bold-title {
        max-width: 1200px;
      }
      .content {
        max-width: 800px;
      }
      .ff-image-container {
        max-width: 1174px;
      }
      .ff-info {
        width: 100%;
        max-width: 1055px;
        height: 528px;
        background-color: white;
        border-radius: 24px;
        display: flex;
        margin-top: -100px;
        color: black;
        justify-content: space-around;
        align-items: center;
        flex-direction: column;
        .ff-title {
          font-size: 30px;
          font-family: 'Akkurat-Light';
          font-weight: lighter;
          display: block;
        }
        .info-box-container {
          display: flex;
          justify-content: space-between;
          .info-box {
            padding: 0 50px;
            display: flex;
            flex-direction: column;
            gap: 40px;
            background-color: white;
            color: black;
            font-size: 25px;
            height: 310px;
            text-align: left;
            &:not(:last-child) {
              border-right: 1px solid #00000029;
            }
          }
        }
      }
      .ff-slider {
        display: none;
      }
      .free-trial-button {
        max-width: 415px;
      }
      .app-store-buttons {
        margin-top: 0;
        a {
          margin-bottom: 0 !important;
        }
        img {
          width: 100%;
        }
      }
    }
  }

  .disclaimer-text-section {
    color: gray;
    font-size: 10px;
    text-align: center;
    align-items: center;
    padding: 40px;
    p {
      color: gray;
      max-width: 1200px;
    }
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}
