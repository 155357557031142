@import '../reuse/mixins.scss';
@import '../reuse/variables.scss';
.create-watchlist {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 15px 0;
}

.name-container {
  margin-bottom: 15px;
}

.search-container {
  position: relative;
}

.results-container {
  max-height: 200px;
  overflow-y: auto;
  z-index: 999;
  background-color: #f6f6f6;
  position: absolute;
  width: 100%;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  .result-item {
    display: flex;
    padding: 15px;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    .ticker {
      margin: 0;
      font-size: 12;
    }
  }
  .result-item:hover {
    background-color: rgba(128, 128, 128, 0.5);
  }
}

.selected-stocks {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 15px;
  flex-wrap: wrap;
  .selected-stock {
    width: 100px;
    height: 50px;
    border-radius: 10px;
    color: white;
    background-color: $primary-color;
    font-size: 15px;
    font-family: 'Akkurat-Bold';
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-right: 15px;
    margin-bottom: 15px;
    .close-btn {
      margin: 0;
      font-size: 0.6rem;
      cursor: pointer;
      background: #222121;
      padding: 0.3rem;
      border-radius: 50%;
      width: 1.5rem;
      height: 1.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.create-button-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .create-button {
    width: 150px;
    height: 50px;
    background-color: $primary-color;
    color: white;
    font-size: 15px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    border-radius: 35px;
    margin-bottom: 15px;
  }
  .error {
    margin: 0;
  }
  .success {
    margin: 0;
  }
}
