.upload-documents-container {
  display: flex;
  flex-direction: column;
  // gap: 3rem;
  .single-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    > p {
      // margin-bottom: -0.7rem;
      color: #1c1b63;
      font-size: 1.1rem;
    }

    > p:last-child {
      color: seagreen;
      text-align: center;

      > span {
        padding-right: 0.5rem;
      }
    }

    .p-dropdown {
      width: 100%;
    }

    .p-fileupload .p-fileupload-content {
      border: 1px solid #888;
      margin-top: 1rem;
      // border-top: none;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
    .p-fileupload .p-fileupload-buttonbar {
      // border: 1px solid #0569ff;
      padding: 0rem;
      background-color: #fcfcfc;
      .p-button-icon {
        display: none;
      }
    }

    .p-fileupload .p-progressbar {
      display: none;
    }

    // hr {
    //   border-bottom: 2px solid #828080;
    //   padding-bottom: 3rem;
    //   margin-top: 2.5rem;
    // }

    // hr:last-child {
    //   display: none;
    // }
  }

  // > div.single-container:last-child {
  //   border-bottom: none;
  //   border: none;
  // }

  .empty-upload-container {
    display: flex;

    align-items: center;
    justify-content: center;
    gap: 1rem;

    i {
      font-size: 2rem !important;
      border-radius: 50%;
      background: #dee2e6;
      color: #929394;
      padding: 1rem;
    }
  }

  .p-fileupload .p-fileupload-buttonbar .p-button {
    width: 100%;
    // background-color: transparent;
    // color: #3627e4;
    padding: 1rem 0rem;
    font-weight: 700;
    margin-right: 0rem;
  }

  .upload-content-container {
    display: flex;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;

    .upload-content-part1-container {
      width: max-content;
      display: flex;
      flex-wrap: wrap;

      img {
        width: 60px;
      }

      span {
        text-align: left;
        padding-left: 1rem;
        margin: auto;
        min-width: 150px;
      }
    }
  }

  .no-icon {
    margin-left: 0rem;
  }

  .p-button:disabled {
    background-color: rgba(0, 0, 0, 0.12) !important;
  }

  .p-button {
    background: #3432df;
  }

  .submit-button {
    margin-bottom: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #3432df;

    .p-button-label {
      flex: none !important;
    }
  }

  .pi {
    font-size: 1rem !important;
  }
}
