@import '../../../reuse/mixins.scss';
@import '../../../reuse/variables.scss';

.robo-basic-info-container {
  text-align: center;
  padding: 50px 0;
  .robo-name {
    font-family: Akkurat-Bold;
    font-size: 16px;
    margin: 0;
  }
  .robo-value {
    font-size: 43px;
    font-family: Akkurat-Light;
    margin: 0;
    color: $robo-color;
  }
  .robo-details-container {
    border-bottom: 0.25px solid rgba(0, 0, 0, 0.1);
    border-top: 0.25px solid rgba(0, 0, 0, 0.1);
    padding: 15px;
    margin-top: 15px;
    margin-bottom: 15px;
    .robo-net-container {
      display: flex;
      width: 60%;
      margin: auto;
      justify-content: space-between;
      @media screen and (max-height: 1000px) {
        width: 100%;
      }
      .robo-net-label {
        margin: 0;
      }
    }
  }
}
