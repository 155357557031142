@import '../reuse/mixins.scss';
@import '../reuse/variables.scss';

.confirmACH-subheader {
  font-size: 15px;
  text-align: center;
}

.confirmACH-inputs-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 15px;
}

.confirmACH-button-container {
  display: flex;
  justify-content: center;
}

.confirmACH-message-container {
  padding-right: 20px;
  text-align: right;
}

.confirmACH-success {
  color: $primary-color;
  text-align: center;
}
.confirmACH-error {
  text-align: center;
  color: red;
}
