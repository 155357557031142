@import '../reuse/mixins.scss';
@import '../reuse/variables.scss';
.promo-intro-page {
    // background: linear-gradient(180deg, #4346f8 0%, #9d8aff 100%);
    background: linear-gradient(180deg, #4346f8 0%, #dadcff 35%, #dadcff 100%);
    color: white;
    min-width: 100vw;
    min-height: 100vh;
    padding: 45px;
    background-repeat: no-repeat;
    position: relative;
    z-index: 1;
    overflow: hidden;
    padding-bottom: 50px;
    @include flex(column, center, flex-start);
    @media only screen and (max-width: 769px) {
        padding: 15px;
        padding-bottom: 50px;
    }
    .promo-intro-center {
        max-width: 500px;
        @include flex(column, center, center);
        text-align: center;
        margin: auto;
    }
    .investall-logo-img {
        width: 113px;
        height: 30px;
        object-fit: contain;
        cursor: pointer;
    }
    .promo-intro-small-text {
        font-size: 14px;
        line-height: 20px;
    }
    .promo-intro-big-text {
        font-size: 39px;
        line-height: 42px;
        font-family: 'Akkurat-Bold';
    }
    .green-text {
        color: #a0ff73;
        font-family: 'Akkurat-Bold';
    }
    .promo-intro-light {
        font-family: 'Akkurat-Light';
    }
    .desktop-btn {
        width: 300px;
        height: 60px;
        background-color: white;
        color: $primary-color;
        font-family: 'Akkurat-Bold';
        font-size: 23px;
        @include flex(row, center, center);
        border-radius: 35px;
        border: none;
    }
    .mobile-btn {
        width: 300px;
        height: 60px;
        background-color: white;
        color: $primary-color;
        font-size: 23px;
        font-weight: bold;
        @include flex(row, center, center);
        border-radius: 35px;
        border: none;
        bottom: 25px;
    }
    .mobile-btn-background {
        width: 100%;
        position: fixed;
        bottom: 0;
        height: 80px;
        background-color: #dadcff;
        @include flex(row, center, center);
    }
    .promo-intro-medium-text {
        font-size: 21px;
        line-height: 31px;
    }
    .promo-intro-bold {
        font-family: 'Akkurat-Bold';
    }
    .promo-intro-ul {
        font-size: 12px;
        line-height: 14px;
        margin-left: 35px;
        text-align: left;
        width: 80%;
    }
    .purple-container {
        background: #7b61ff;
        border-radius: 18px;
        padding: 20px;
        width: 95%;
        @include flex(column, flex-start, flex-start);
    }
    .promo-intro-black {
        font-weight: bold;
    }
    .promo-intro-row {
        @include flex(row, center, flex-start);
    }
    .sub-icon {
        width: 44px;
        height: 38px;
        margin-right: 15px;
    }
    .cool-bubble {
        width: 138%;
        height: 500px;
        @media only screen and (max-width: 600px) {
            height: 360px;
            margin-right: 55px;
        }
    }
    .black-text {
        color: black;
    }
    .promo-intro-left {
        text-align: left;
    }
    .promo-intro-center-item {
        margin-left: auto;
        margin-right: auto;
    }
    .space-bottom-small {
        margin-bottom: 5px;
    }
    .space-bottom-large {
        margin-bottom: 50px;
        // @media only screen and (max-width: 769px) {
        //     margin-bottom: 25px;
        // }
    }
    .subtle-text {
        color: rgba(0, 0, 0, 0.5);
        width: 75%;
        font-size: 10px;
    }
    .space-top-large {
        margin-top: 50px;
    }
}