@import '../reuse/mixins.scss';
@import '../reuse/variables.scss';

.personalization-dialog.p-dialog {
  .p-dialog-header {
    padding: 0.5rem 1rem;
  }
}

.personalization-header {
  @include flex(row, center, center);
  .form-back-btn {
    background-color: white;
    border: none;
    height: 20px;
    width: 20px;
    @include flex(row, center, center);
  }
  .progress-bar-container {
    width: 100%;
    @include flex(row, center, center);
    .progress-bar {
      width: 200px;
      height: 6px;
      background-color: lightgray;
      border-radius: 20px;
      .step-indicator {
        height: 100%;
        background-color: blue;
        border-radius: 18px;
        &.hidden {
          background-color: white;
        }
      }
    }
  }
}

.qa-container {
  .skip-button-container {
    width: 100%;
    @include flex(row, end, center);
    .skip-button {
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
      font-size: 12px;
    }
  }
}

.question-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  .answers-container {
    @include flex(column, center, start);
    gap: 12px;
    margin-bottom: 20px;
    .answer-container {
      border: 2px solid white;
      width: 100%;
      @include flex(row, space-between, start);
      gap: 30px;
      box-shadow: 0px 2px 5px 0px #56565633;
      border-radius: 10px;
      padding: 18px;
      &:hover {
        border: 2px solid $primary-color;
        cursor: pointer;
      }
      .answer {
        width: 100%;
        .sub-text {
          font-size: 10px;
          color: $primary-color;
        }
      }
    }
  }
}
.selector-list {
  @include flex(column, start, start);
  gap: 10px;
  width: 100%;
  max-height: 380px;
  overflow-y: auto;
  margin-bottom: 20px;
  .list-item {
    @include flex(row, space-between, center);
    height: auto;
    width: 100%;
    border-radius: 140px;
    border: 1px solid lightgray;
    padding: 8px 20px 8px 8px;
    cursor: pointer;
    gap: 20px;
    &.selected {
      border: 1px solid $primary-color;
      background-color: $primary-color;
      color: white;

      .item-title {
        color: white;
      }
    }
    .list-item-content {
      @include flex(row, start, center);
      gap: 12px;
      flex: 1;
      .item-icon {
        height: 44px;
        width: 44px;
        border-radius: 22px;
        object-fit: contain;
      }
      .item-title {
        font-size: 20px;
        line-height: 1;
        flex-wrap: wrap;
        flex: 1;
      }
    }
    .checked-container {
      color: white;
    }
  }
}

.strategy-select-container {
  @include flex(column, start, start);
  width: 100%;
  gap: 60px;
  margin-bottom: 40px;
  .center-content {
    @include flex(column, center, center);
    width: 100%;
    gap: 10px;
    .strategy-card-label {
      text-align: center;
      color: #1966d3;
    }
  }
  .strategy-card {
    border-radius: 24px;
    color: white;
    padding: 20px;
    @include flex(column, start, start);
    gap: 20px;
    cursor: pointer;
    .card-title {
      font-size: 20px;
      font-weight: bold;
    }
    &.expert {
      background: radial-gradient(
        40.37% 91.83% at 0% 85.62%,
        #24a1db 22.92%,
        #3627e4 100%
      );
    }
    &.custom {
      background: radial-gradient(
        43.24% 98.37% at 0% 79.08%,
        #e76ae7 13.02%,
        #7b61ff 100%
      );
    }
    .strategy-card-description-container {
      @include flex(row, start, start);
      gap: 20px;
      text-align: start;
    }
  }
}

.strategy-info-container {
  .description-title {
    font-size: 20px !important;
    font-weight: bold;
  }
  .strategy-info-header {
    padding: 30px;
    text-align: center;
    color: white;
    @include flex(column, space-between, center);
    gap: 20px;
    margin: 0 -1.5rem;
    &.custom {
      background-color: #7c62ff;
    }
    &.expert {
      background-color: $primary-color;
    }
    .sub-title {
      font-size: 16px;
      color: white;
    }
    .main-title {
      font-size: 30px;
      font-weight: bold;
      color: white;
    }
    p {
      color: white;
    }
  }
  .strategy-info-description {
    margin: 20px 0;
    .description-title {
      margin-bottom: 20px;
    }
    p {
      font-size: 12px;
      color: #272727;
    }
  }
  .strategy-returns-container {
    margin-bottom: 20px;
    .return-row {
      @include flex(row, space-between, center);
      font-size: 16px;
      width: 100%;
      margin-top: 10px;
      font-weight: 500;
    }
  }
  .investment-calc-container {
    background-color: $primary-color;
    color: white;
    padding: 34px;
    margin: 0 -1.5rem;
    p {
      color: white;
    }
    .p-slider {
      height: 12px;
      margin: 10px 0;
      background: #3e53b4;
    }
    .p-slider-range {
      background: white;
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }
    .p-slider-handle {
      background: white;
      transform: scale(1);
    }
    .row {
      @include flex(row, space-between, center);
      margin: 0 0 10px 0;

      .title {
        flex-wrap: wrap;
        width: 70%;
        text-align: start;
      }
      .icon {
        font-size: 60px;
      }
    }
    .year-selector {
      width: 100%;
      @include flex(row, center, center);
      border-radius: 10px;
      overflow: hidden;
      margin: 20px 0;
      .year {
        flex: 1;
        background-color: #7c62ff;
        height: 46px;
        @include flex(row, center, center);
        cursor: pointer;
        &.selected {
          background-color: white;
          color: black;
        }
      }
    }
    .return-amount {
      font-weight: bold;
      font-size: 30px;
      margin: 10px 0;
    }
    .disclaimer {
      color: lightgray;
      font-size: 10px;
    }
  }
  .strategy-info-allocations {
    margin: 20px 0;
    @include flex(column, start, start);
    gap: 10px;
    .allocation-row {
      @include flex(row, space-between, center);
      width: 100%;
      font-size: 20px;
    }
  }
}

.personalization-full-width-btn {
  width: 100%;
  @include flex(row, center, center);
  margin-bottom: 20px;
  background-color: $primary-color !important;
  &.p-button-loading {
    border-radius: 2rem !important;
    width: 100% !important;
    gap: 10px;
  }
}
