@import '../reuse/mixins.scss';
@import '../reuse/variables.scss';

.unopened-card {
  box-shadow: -3px -2px 4px rgba(255, 255, 255, 0.92),
    3px 1px 5px rgba(86, 86, 86, 0.2),
    inset 0px 0px 2px rgba(255, 255, 255, 0.21);
  border-radius: 19px;
  color: white;
  padding: 15px;
  text-align: center;
  margin: 25px 0;
  min-height: 150px;
  .unopened-card-header {
    font-weight: bold;
    font-size: 27px;
  }
  .unopened-card-details {
    font-family: Akkurat-Bold;
    font-size: 12px;
    padding-bottom: 1rem;
  }
  .unopened-card-btn {
    background: white;
    font-size: 13px;
    font-weight: bold;
    width: 160px;
    height: 35px;
    border-radius: 140px;
  }
  .stock {
    color: $primary-color;
  }
  .crypto {
    color: $primary-color;
  }
  .robo {
    color: $robo-color;
  }
  .goal {
    color: $goals-color;
  }
  .aistock {
    color: $group-color;
  }
  .aicrypto {
    color: $group-color;
  }
}

.stock {
  background: $primary-color;
}
.crypto {
  background: $primary-color;
}
.robo {
  background: $robo-color;
}
.goal {
  background: $goals-color;
}
.aistock {
  background: $group-color;
}
.aicrypto {
  background: $group-color;
}
