@import '../reuse/mixins.scss';
@import '../reuse/variables.scss';

.dashboard {
  display: flex;
  width: 100%;
  margin: 0;
  padding: 25px;
  position: relative;
  height: 100%;
  flex-direction: column;
  @media screen and (max-width: 1000px) {
    height: auto;
    padding: 0;
  }
  .notification-toggle-container {
    // position: absolute;
    right: 0;
    padding-right: 15px;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: flex-end;

    .active-notif-icon-container {
      position: relative;
      padding: 7px 7px 0 0;
      .count-container {
        width: 18px;
        height: 18px;
        position: absolute;
        right: 0;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 80%;
        color: white;
        background: $primary-color;
        border-radius: 50%;
      }
    }
  }
  .notification-container {
    position: fixed;
    z-index: 999;
    top: 0;
    right: 0;
    height: 100vh;
    width: 100%;
  }

  .notifications {
    position: absolute;
    top: 5%;
    right: 3%;
    min-width: 10%;
    overflow: auto;
    z-index: 100;
    margin-right: 25;
    background-color: #f9f9f9;
    box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
    padding-left: 5;
    padding-right: 5;
    @media screen and (max-width: 1000px) {
      margin-right: 0;
      top: 25%;
      right: 0;
    }
  }
  .kyc-msg {
    // position: absolute;
    // left: 40%;
    // cursor: pointer;
    text-align: center;
    text-decoration: underline;
    cursor: pointer;
    margin: 0;
  }

  .dashboard-header {
    font-weight: bold;
    position: absolute;
    font-size: 16px;
    color: #272748;
    font-weight: bold;
    @media screen and (max-width: 1000px) {
      position: relative;
      padding: 15px;
    }
  }

  .top-row {
    // height: 55%;
    width: 100%;
    display: flex;
    padding-top: 25px;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 1000px) {
      flex-direction: column;
      padding: 0;
    }
    .left-col {
      // width: 50%;
      width: 100%;
      height: 100%;
      @media screen and (max-width: 1000px) {
        width: 100%;
        height: 500px;
      }
      .portfolio-details {
        width: 100%;
        height: 40%;
        min-height: 175px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .heading-container {
          text-align: center;
          .header {
            font-size: 13px;
            color: #c4c4c4;
            font-weight: bold;
          }

          .portfolio-amount {
            font-family: Akkurat-Light;
            font-size: 43px;
            color: $primary-color;
          }
        }

        .investment-net-container {
          display: flex;
          flex-direction: column;
          .investment-net-row {
            display: flex;
            justify-content: space-between;
            width: 80%;
            margin: auto;
            .label {
              font-size: 24px;
              margin: 0;
              margin-right: 25px;
              // margin-right: 15px;
            }

            .value {
              font-size: 24px;
              color: $primary-color;
              margin: 0;
              margin-left: 25px;
            }

            .negative {
              color: $negative-color;
            }
          }
        }
      }
      .balance-history-graph-container {
        width: 100%;
        height: 60%;
        padding: 15px;
        .graph-container {
          height: 80%;
          max-height: 80%;
          width: 100%;
        }
        .graph-tabs {
          display: flex;
          justify-content: space-evenly;
          height: 20%;
          max-height: 20%;
          align-items: center;
          .graph-tab {
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            font-size: 15px;
            width: 70px;
            height: 30px;
            color: #a7a7a7;
          }
          .selected {
            color: $primary-color;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07),
              -3px -2px 4px rgba(255, 255, 255, 0.92),
              3px 1px 5px rgba(86, 86, 86, 0.2),
              inset 0px 0px 2px rgba(255, 255, 255, 0.21);
            border-radius: 19px;
          }
        }
      }
    }
    .right-col {
      height: 100%;
      // width: 50%;
      width: 100%;
      @media screen and (max-width: 1000px) {
        width: 100%;
        height: 500px;
      }
      .portfolio-allocations-container {
        width: 100%;
        padding: 0 15px;
        height: 100%;
        .portfolio-allocations-chart-container {
          // height: 90%;
          height: 500px;
          width: 100%;
          // width: 700px;
          // min-width: ;

          @media screen and (max-width: 1000px) {
            height: 90%;
          }
        }
        .header {
          font-size: 16px;
          color: #272748;
          font-weight: bold;
          text-align: left;
          margin: 0;
          // padding-bottom: 15px;
          padding-top: 1rem;
          text-transform: uppercase;
        }
      }
    }
  }
  .bottom-row {
    height: 45%;
    width: 100%;
    @media screen and (max-width: 1000px) {
      width: 100%;
      height: auto;
    }
    .my-investments-container {
      width: 100%;
      height: 100%;
      padding: 15px 15px 0 15px;
      .header {
        font-size: 16px;
        color: #272748;
        font-weight: bold;
        text-align: left;
        margin: 0;
        padding-bottom: 15px;
      }
      .p-tabview .p-tabview-nav {
        border: none;
        margin: 0;
      }

      .p-tabview .p-tabview-panels {
        padding: 20px 0;
      }

      .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
        text-decoration: none;
      }

      .holdings-data {
        height: 80%;
      }
    }
  }
}

.highcharts-container {
  width: 100% !important;
  height: 100% !important;
}

.pi {
  font-size: 12px !important;
}

.new-dashboard-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  max-width: 1440px;
  .dashboard {
    width: 65%;
    flex-direction: column;

    @media screen and (max-width: 1000px) {
      width: 100%;
    }
  }

  .accounts-information {
    // background-color: #ffffff;
    width: 35%;
    @media screen and (max-width: 1000px) {
      width: 100%;
    }
  }
}
