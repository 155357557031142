.unremoveable-signout {
  color: red;
}

.unremoveable-header-container .unremoveable-header {
  font-size: 1.5rem;
}

.personalization-content {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .question-container {
    label {
      margin-bottom: 0rem;
    }
    > label {
      font-size: 1.2rem;
      padding-bottom: 0.5rem;
      span {
        color: #8a8a8a;
        padding-left: 0.5rem;
        font-size: 1rem;
      }
    }

    .p-multiselect {
      width: 100%;

      span.p-multiselect-token-label {
        font-size: 0.8rem;
      }
    }
  }

  .option-container {
    display: flex;
    align-items: center;
    padding-bottom: 0.5rem;
  }

  .single-term-container {
    .term-subtitle {
      padding-bottom: 0.5rem;
      display: flex;
      align-items: center;
      gap: 0.5rem;

      span {
        padding-top: 0.2rem;
      }

      img {
        width: 25px;
        height: 25px;
      }
    }

    .term-content {
      padding-left: 0.2rem;
    }
  }

  .financial-goals-container {
    > div {
      padding-bottom: 1rem;
    }

    > div:last-child {
      padding-bottom: 0rem;
    }
  }
}

.IB-form-dialog {
  width: 60vw;
  @media screen and (max-width: 1130px) {
    width: 70vw;
  }
  @media screen and (max-width: 1000px) {
    width: 90vw;
  }
}

.ib-questionnaire-container {
  .p-calendar,
  .p-dropdown,
  .p-inputnumber {
    width: 100%;
  }

  .input-container {
    padding-bottom: 1rem;

    > label {
      padding-bottom: 0.5rem;
      margin-bottom: 0rem;
    }
  }

  .p-panel {
    .p-panel-header {
      // padding: 1.5rem 1.25rem;
      // margin-bottom: 1rem;
      border: 1px solid #dee2e6;
      background: #f8f9fa;
      color: #343a40;
    }
    .p-panel-title {
      font-size: 1.1rem;
    }
  }
  .p-panel-toggleable {
    margin-bottom: 1rem !important;
  }
  .p-panel-content {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .checkbox-container {
    display: flex;
    align-items: start;
    gap: 0.5rem;
    // padding-bottom: 0.5rem;

    // padding-bottom: 1rem;
    background-color: #eee;
    padding: 12px;
    border-radius: 10px;
    label,
    li {
      color: #454545 !important; 
      font-size: 14px !important;
    }
    > label {
      margin-bottom: 0rem;
      padding-bottom: 0rem;
    }
    ul {
      list-style-position: inside;
    }
    ul ol{
      margin-left: 12px;
    }
    .p-checkbox.p-component {
      margin-top: 6px;
    }
    .letter-list {
      list-style-type: lower-alpha;
      list-style-position: inside;
    }
  }

  .multiple-checkbox {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: 20px;
  }

  .investment-single-group {
    .p-selectbutton {
      padding-bottom: 1rem;
    }
    .investment-inputs-container {
      padding-left: 1rem;
    }
  }

  .multiple-radio-buttons {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .radio-button-container {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      padding-bottom: 0.5rem;

      > label {
        margin-bottom: 0rem;
        padding-bottom: 0rem;
      }
    }
  }

  .calendar-input {
    .p-button-icon {
      font-size: 1.2rem !important;
    }
  }

  .p-selectbutton .p-button.p-highlight {
    background: rgb(54, 39, 228) !important;
    color: white !important;
  }

  .source-wealth-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    > div:not(:first-child) {
      border: 1px solid #757575;
      padding: 1rem;
      background: #f8f9fa;
    }
    .source-wealth-options-container {
      display: flex;
      gap: 0.5rem;

      .p-dropdown {
        flex: 1;
      }
    }
  }

  .review-ib-form-section {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    .review-block-container {
      padding: 1rem 0rem;
      padding-top: 0rem;
      border-bottom: 2px solid rgba(0, 0, 0, 0.06);

      label {
        color: #272748;
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 1rem;
      }

      p {
        display: flex;
        flex-direction: column;
        color: #272748;
        font-size: 12px;
        font-weight: 400;
        margin-bottom: 1rem;

        span {
          font-size: 16px;
        }
      }
    }

    .agreement-container {
      label {
        color: #272748;
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 1rem;
      }

      p {
        color: #272748;
        font-size: 13px;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 1rem;
      }

      a {
        display: flex;
        width: 100%;
        margin-bottom: 1rem;
      }
    }
  }
}

.post-submitted-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  p {
    font-size: 1.5rem;
    font-weight: 700;
    text-align: center;
  }

  span {
    font-size: 1.2rem;
    text-align: center;
    width: 80%;

    @media screen and (max-width: 700px) {
      width: 100%;
    }
  }

  button {
    margin-bottom: 3rem;
    background: rgb(54, 39, 228);
  }

  .p-button:enabled:active,
  .p-button:enabled:focus {
    background: rgb(54, 39, 228);
    color: white;
  }
}

.p-dialog {
  .p-dialog-content {
    padding: 0 1.5rem 0rem 1.5rem !important;
  }
}

.p-steps {
  padding-bottom: 0rem !important;
  .p-steps-item {
    &.p-highlight {
      .p-steps-number {
        background: rgb(54, 39, 228) !important;
      }
    }

    .p-menuitem-link {
      flex-direction: column !important;
      gap: 0.5rem;

      .p-steps-title {
        @media screen and (max-width: 1300px) {
          display: none;
        }
      }
    }

    .p-menuitem-link:not(.p-disabled):focus {
      background: none !important;
    }
  }
}
// .p-steps-item .p-menuitem-link:not(.p-disabled):focus {
//   background: none !important;
// }

// .p-steps .p-steps-item.p-highlight .p-steps-number {
//   background: rgb(54, 39, 228) !important;
// }

// .p-steps .p-steps-item .p-menuitem-link {
//   flex-direction: column;
// }

.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  padding: 0.5rem 1rem !important;
}

.p-multiselect-panel .p-multiselect-header {
  padding: 0.5rem 1rem !important;
}

.dialog-footer-content {
  .p-button:enabled:active,
  .p-button:enabled:focus {
    background: rgb(54, 39, 228);
    color: white;
  }
  .no-icon {
    margin-right: 0rem !important;
  }
}

.ib-dialog-footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .english-characters-msg {
    font-size: 12px;
  }
  .error-message {
    color: red;
  }
  .p-button:enabled:active,
  .p-button:enabled:focus {
    background: rgb(54, 39, 228);
    color: white;
  }
  .no-icon {
    margin-right: 0rem !important;
  }

  .p-button {
    background: rgb(54, 39, 228);
  }

  button {
    // margin: 0rem !important;
    @media screen and (max-width: 1000px) {
      width: 100% !important;
    }
  }
}

.p-datepicker .p-datepicker-header .p-datepicker-title {
  margin: 0 !important;
  // order: 2;
  display: flex;
  gap: 1rem;
}

.p-datepicker .p-datepicker-header .p-datepicker-prev {
  order: 1 !important;
}

.p-datepicker table th {
  text-align: center;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  font-size: initial !important;
}

.p-component-overlay {
  backdrop-filter: blur(20px);
}
