@import '../../../reuse/mixins.scss';
@import '../../../reuse/variables.scss';

.goal-results-container {
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  @media only screen and (max-width: 769px) {
    height: 100vh;
  }
  .header {
    font-weight: bold;
    font-size: 25px;
  }
  .return-info-container {
    padding: 20px;
    background: rgba(128, 128, 128, 0.1);
    border-radius: 15px;
    .return-info {
      display: flex;
      align-items: center;
      margin-top: 15px;
      .return-label {
        font-size: 15px;
        margin-right: 15px;
      }
      .return-value {
        color: white;
        background-color: $primary-color;
        border-radius: 10px;
        padding: 5px;
      }
    }
  }

  .strategy-description-container {
    padding: 15px;
    background: rgba(128, 128, 128, 0.1);
    border-radius: 15px;
    border: 1px solid rgba(128, 128, 128, 0.5);
    margin-top: 25px;
    .description {
      font-size: 15px;
      font-weight: normal;
      color: rgba(128, 128, 128, 1);
    }
  }

  .button-container {
    text-align: right;
    margin-top: 25px;
    .submit-button {
      border-radius: 15px !important;
      color: white !important;
      background: $goals-color !important;
      .next-button-container {
        display: flex;
        justify-content: center;
        align-items: center;
        .next-text {
          margin: 0;
          margin-right: 5px;
        }
      }
    }
  }
}
