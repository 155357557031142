@import '../../../reuse/mixins.scss';
@import '../../../reuse/variables.scss';

.investment-form-container {
  width: 100%;
  padding: 15px;
  @media screen and (max-width: 769px) {
    width: 100%;
  }
  .header {
    font-weight: bold;
    font-size: 20px;
  }
  .graph-container {
    .graph-data {
      .value {
        margin-bottom: 0;
        font-weight: bold;
        font-size: 20px;
        color: $robo-color;
        .static-text {
          margin: 0;
          text-align: center;
          font-weight: normal;
          margin-left: 5px;
          color: rgba(128, 128, 128, 1);
        }
      }
      .percentage {
        color: $robo-color;
        .growth {
          color: black;
          margin-left: 5px;
        }
      }
      .gips {
        color: grey;
        text-align: right;
        font-size: 12px;
      }
    }
  }
  .initial-investment-form-container {
    padding: 15px;
    margin: 25px 0;
    .info {
      text-align: left;
    }
    .row {
      border-radius: 25px;
      background: rgba(128, 128, 128, 0.2);
      padding: 15px;
      display: flex;
      justify-content: space-between;
      .label {
        font-weight: bold;
        display: flex;
        margin-top: 0;
        align-items: center;
      }

      .p-inputnumber {
        width: 40% !important;
        .p-inputnumber-input {
          border-radius: 10px !important;
        }
      }
    }
  }

  .periodic-form-container {
    margin: 25px 0;
    .header {
      text-align: center;
      font-size: 18px;
    }
    .input-container {
      border-radius: 25px;
      background: rgba(128, 128, 128, 0.2);
      padding: 15px;
      .monthly-form-container {
        display: flex;
        justify-content: space-between;
        padding: 15px;
        .label {
          font-weight: bold;
          display: flex;
          margin-top: 0;
          align-items: center;
        }
        .p-inputnumber {
          width: 40% !important;
          .p-inputnumber-input {
            border-radius: 10px !important;
          }
        }
      }
      .checkbox-container {
        padding: 15px 15px 0px 15px;
        .row {
          display: flex;
          padding: 15px;
          align-items: center;
          .label {
            font-weight: bold;
            display: flex;
            margin-top: 0;
            margin-left: 15px;
          }
        }
      }
    }
  }
  .bank-accounts-form-container {
    width: 60%;
    margin: 25px auto;
    .p-dropdown {
      border-radius: 15px !important;
      width: 100%;
    }
  }
  .requirement-message-container {
    border-radius: 15px;
    background: rgba(0, 255, 0, 0.2);
    border: 1px solid rgba(0, 255, 0, 1);
    padding: 10px 10px 10px 0;
    .message {
      font-size: 15px;
      margin-bottom: 0;
      text-align: center;
    }
  }
  .button-container {
    margin: 15px;
    display: flex;
    justify-content: right;
    .submit-button {
      border-radius: 25px;
      background: $robo-color;
      color: white;
    }
  }

  .error-message {
    color: red;
    text-align: center;
  }

  .deposit-container {
    .input-container {
      display: flex;
    }
  }
}
