@import '../reuse/mixins.scss';
@import '../reuse/variables.scss';

.default__button {
  background-color: $primary-color;
  height: 50px;
  @include flex(column, center, center);
  color: white;
  padding: 0 40px;
  border-radius: 50px;
  p {
    margin: 0;
  }
  &:hover {
    cursor: pointer;
    text-decoration: none;
    color: white;
  }
  &.purple {
    background: #7b61ff;
  }
}
.get-the-app-container {
  @include flex(row, space-evenly, center);
  width: 100%;
  .default__button {
    background-color: $primary-color;
    height: 50px;
    text-transform: uppercase;
    @include flex(column, center, center);
    color: white;
    padding: 0 40px;
    border-radius: 50px;
    p {
      margin: 0;
    }
    &:hover {
      cursor: pointer;
      text-decoration: none;
      color: white;
    }
    &.purple {
      background: #7b61ff;
    }
  }
  .default__button-v2 {
    background-color: $primary-color;
    text-transform: uppercase;
    height: 50px;
    @include flex(column, center, center);
    color: white;
    width: 40%;
    border-radius: 50px;
    p {
      margin: 0;
      font-size: 12px;
    }
    &:hover {
      cursor: pointer;
      text-decoration: none;
      color: white;
    }
    &.purple {
      background: #7b61ff;
    }
  }
}
