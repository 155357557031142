@import '../reuse/mixins.scss';
@import '../reuse/variables.scss';

.transfer-funds-container {
  padding: 50px 0;
  .tabs-container {
    background-color: transparent;
    width: 100% !important;
    margin: auto;
    box-shadow: -3px -2px 4px rgba(255, 255, 255, 0.92),
      3px 1px 5px rgba(86, 86, 86, 0.2),
      inset 0px 0px 2px rgba(255, 255, 255, 0.21);
    border-radius: 19px;
    padding: 5px;
    .from-account-form-container {
      padding: 25px;
      display: block;
      justify-content: center;
      align-content: center;
      border-radius: 15px;
      background: rgba(128, 128, 128, 0.1);
      width: 60%;
      margin: auto;
      margin-bottom: 25px;

      @media only screen and (max-width: 769px) {
        width: 90%;
      }

      .label {
        font-size: 20px;
        font-weight: 500;
      }

      .input {
        border-radius: 15px;
        width: 80%;
        padding: 15px;
        border: 1px solid rgba(128, 128, 128, 0.5);
      }

      .p-dropdown {
        border-radius: 10px;
        width: 100%;
      }

      .balance {
        font-weight: bold;
        font-size: 20px;

        .dollar-amount {
          color: $primary-color;
        }
      }

      .failure {
        width: 100%;
      }
      .edit-bank-button-container {
        display: flex;
        justify-content: right;
        padding: 5px 0;
        .p-button {
          border-radius: 25px;
          color: white;
          background-color: $primary-color;
          margin: 0;
          font-size: 12px;
        }
      }
    }

    .to-account-form-container {
      padding: 25px;
      display: block;
      justify-content: center;
      align-content: center;
      border-radius: 15px;
      background: rgba(128, 128, 128, 0.1);
      width: 60%;
      margin: 25px auto;
      margin-bottom: 25px;
      @media only screen and (max-width: 769px) {
        width: 90%;
      }

      .label {
        font-size: 20px;
        font-weight: 500;
      }

      .input {
        border-radius: 15px;
        width: 80%;
        padding: 15px;
        border: 1px solid rgba(128, 128, 128, 0.5);
      }

      .p-dropdown {
        border-radius: 10px;
        width: 100%;
      }

      .balance {
        font-weight: bold;
        font-size: 20px;

        .dollar-amount {
          color: $primary-color;
        }
      }

      .failure {
        width: 100%;
      }

      .edit-bank-button-container {
        margin-top: 5px;
        display: flex;
        justify-content: right;
        .p-button {
          border-radius: 25px;
          color: white;
          background-color: $primary-color;
        }
      }
    }

    .amount-form-container {
      padding: 25px;
      display: block;
      justify-content: center;
      align-content: center;
      border-radius: 15px;
      background: rgba(128, 128, 128, 0.1);
      width: 60%;
      margin: 25px auto;
      @media only screen and (max-width: 769px) {
        width: 90%;
      }
      .label {
        font-size: 20px;
        font-weight: 500;
      }

      .p-inputnumber-input {
        border-radius: 10px;
      }

      .amount-limit-message {
        color: red;
        text-align: center;
      }
    }

    .submit-button-container {
      padding: 15px;
      width: 60%;
      display: flex;
      margin: auto;
      justify-content: center;
      .p-button {
        border-radius: 25px;
        color: white;
        background-color: $primary-color;
        width: 80%;
      }
    }

    .message-container {
      width: 60%;
      margin: auto;
      border-radius: 15px;
      background: rgba(255, 255, 0, 0.1);
      border: 1px solid rgb(255, 255, 0);
      color: #f0e68c;
      padding: 15px;
    }

    .time-message-container {
      width: 60%;
      margin: auto;
      border-radius: 15px;
      background: rgba(255, 0, 0, 0.1);
      border: 1px solid red;
      color: red;
      padding: 15px;
      margin-bottom: 25px;
    }

    .success {
      font-size: 15px;
      width: 60%;
      text-align: center;
      color: $primary-color;
      margin: auto;
    }

    .failure {
      font-size: 15px;
      width: 60%;
      text-align: center;
      color: red;
      margin: auto;
    }
    .p-tabview .p-tabview-nav {
      background-color: transparent;
      .p-tabview .p-tabview-nav {
        background-color: transparent;

        li .p-tabview-nav-link {
          background-color: transparent;
          background: transparent;
          border-bottom: 0;
        }
      }

      li .p-tabview-nav-link {
        background: transparent !important;
      }
    }

    .p-tabview-panels {
      background-color: transparent;
      background: transparent !important;
      height: 100%;
    }
  }
}

//Not nested

.label {
  margin-top: 25px;
  margin-bottom: 0;
}

.buttons-row {
  display: flex;
  margin-top: 25px;
  justify-content: left;
}

.buttons-row-double {
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  justify-content: space-evenly;
}

.failure {
  color: red;
  margin-top: 15px;
  text-align: center;
}

.status-message {
  width: 60%;
  text-align: center;
  margin: auto;
  color: $primary-color;
}
