@import '../reuse/mixins.scss';
@import '../reuse/variables.scss';

.password-msg {
  margin: 15px 0;
  .password-msg-header {
    font-size: 12px;
    margin-bottom: 5px;
  }

  .password-msg-item {
    font-size: 12px;
  }

  .password-error {
    color: red;
  }

  .password-success {
    color: $primary-color;
  }

  ul {
    list-style-position: inside;
  }
}
