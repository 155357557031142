.options-container {
  .last-price-container {
    display: flex;
    flex-direction: column;
    .decrease {
      color: red;
    }
    .increase {
      color: green;
    }
  }

  .selection-container {
    display: flex;
    gap: 2rem;
    margin: 1rem 0rem;

    @media screen and (max-width: 500px) {
      gap: 1rem;
    }
  }

  .p-selectbutton {
    border-radius: 5px;

    .p-button {
      width: 100px;
      &.p-highlight,
      &:focus.p-highlight,
      &.p-highlight:hover {
        background: rgb(54, 39, 228);
        color: white;
      }

      @media screen and (max-width: 500px) {
        width: 70px;
      }
    }
  }

  .p-datatable-tbody {
    td {
      .p-button {
        // min-width: 120px;
        width: 100px;
        background: rgb(54, 39, 228);
        color: white;
      }
    }

    & > tr > td {
      padding: 0.5rem 1rem !important;
    }
  }
  .p-menuitem-link {
    width: max-content;
  }

  .p-column-title {
    width: max-content;
  }
}

.trading-container {
  .info-title {
    font-size: 1.2rem;
    padding-bottom: 1rem;
    @media screen and (max-width: 500px) {
      font-size: 1rem;
    }
  }

  > div:nth-child(3) {
    padding-top: 1rem;
  }
  .info-content {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    p {
      color: gray;
      font-size: 1rem;
      display: flex;
      justify-content: space-between;
    }
  }

  .trade-subheader {
    display: flex;
    justify-content: space-between;
    font-size: 1.2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    flex-wrap: wrap;
    gap: 0.5rem;

    @media screen and (max-width: 500px) {
      font-size: 1rem;
    }
  }

  .back-arrow {
    display: flex;
    align-items: center;
    font-size: 0.8rem !important;
    cursor: pointer;
  }

  .buying-info {
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    padding: 1rem 0rem;

    p {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      // span {
      //   @media screen and (max-width: 500px) {
      //     width: 100%;
      //   }
      // }
    }
  }

  .input-info-container {
    padding-top: 1rem;

    .single-input {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 1rem;
      flex-wrap: wrap;

      span:last-child {
        @media screen and (max-width: 500px) {
          width: 100%;
        }
      }

      input {
        padding: 0.5rem 1rem;
      }
    }
  }

  .total-result-container {
    display: flex;
    justify-content: space-between;
  }

  .trade-confirmation-container {
    border-top: 1px solid black;
    p {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  }

  .error-container {
    text-align: center;
  }

  .submitted-container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    .submitted-stock-title {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      p:first-child {
        font-size: 2rem;
        font-weight: 600;
      }
      p:last-child {
        font-size: 0.8rem;
      }
    }

    .submitted-info-detail {
      p {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #ccc1c1;
        padding: 0.5rem 0rem;

        span {
          @media screen and (max-width: 500px) {
            text-align: end;
          }
        }
      }

      p:last-child {
        border-bottom: none;
      }
    }
  }
}

.footer-button {
  margin: auto;
  .step-button {
    background: rgb(54, 39, 228);
    color: white;
    width: 200px !important;
    margin: 0 !important;

    @media screen and (max-width: 500px) {
      width: 100% !important;
    }

    &.submit {
      .pi {
        font-size: 1.4rem !important;
      }
    }
  }

  .p-button:enabled:active,
  .p-button:enabled:focus {
    background: rgb(54, 39, 228);
    color: white;
  }
}

.p-dialog {
  // width: 40vw;
  .p-dialog-header {
    .p-dialog-title {
      font-size: 1.8rem !important;

      @media screen and (max-width: 500px) {
        font-size: 1.3rem !important;
      }
    }
  }
}

.p-toast-message-content {
  > span {
    display: none;
  }
}

.p-datatable-thead {
  background-color: transparent !important;
}

.p-datatable {
  .p-datatable-thead {
    > tr > th {
      background-color: transparent !important;
    }
  }
  .p-datatable-tbody {
    > tr {
      background-color: transparent !important;
    }
  }

  .p-column-title {
    font-weight: 600;
  }
}

.current-price-row {
  display: none !important;
}

.current-price-row-header {
  position: absolute;
  left: 35%;
  color: green;
  font-weight: bold;
}
