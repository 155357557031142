$breakpoints: (
  'phone': 450px,
  // "small-tablet": 550px,
  // "tablet": 850px,
  'desktop': 1000px,
);

@mixin respond-to($breakname) {
  $bp: map-get(
    $map: $breakpoints,
    $key: $breakname,
  );

  @media screen and (max-width: $bp) {
    @content;
  }
}

@mixin flex($direction, $justify-content, $align-items) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify-content;
  align-items: $align-items;
}

.main-body-container {
  height: 100%;

  p {
    margin-bottom: 1rem;
  }

  // for "sectors" and "unusual activity" tab styles
  .searchbar-container {
    background-color: #fff;
    border-radius: 20px;

    padding-top: 1rem;
    padding-bottom: 1rem;
    max-width: 900px;
    box-shadow: rgba(255, 255, 255, 0.92) -3px -2px 4px,
      rgba(86, 86, 86, 0.2) 3px 1px 5px,
      rgba(255, 255, 255, 0.21) 0px 0px 2px inset;
  }

  .company-cards-container {
    background-color: #fff;
    border-radius: 20px;
    box-shadow: rgba(255, 255, 255, 0.92) -3px -2px 4px,
      rgba(86, 86, 86, 0.2) 3px 1px 5px,
      rgba(255, 255, 255, 0.21) 0px 0px 2px inset;
  }

  .card {
    background-color: #fff;
    border-radius: 20px;
    max-width: 900px;
    box-shadow: rgba(255, 255, 255, 0.92) -3px -2px 4px,
      rgba(86, 86, 86, 0.2) 3px 1px 5px,
      rgba(255, 255, 255, 0.21) 0px 0px 2px inset;

    .ua-content {
      margin: 0.5rem;
    }
  }

  // end for "sectors" and "unusual activity" tab styles

  .p-tabview {
    .p-tabview-nav {
      background: transparent;
      li {
        .p-tabview-nav-link {
          /* Your styles here */
          background: transparent;
          &:hover {
            text-decoration: none;
          }
        }
      }
      li.p-highlight {
        .p-tabview-nav-link {
          // Your styles for the highlighted tab nav link
          background: transparent;
          &:hover {
            text-decoration: none;
          }
        }
      }
    }

    .p-tabview-panels {
      background: transparent;
    }
  }
}

// Sreener tab styles
.screener-body-content {
  background: #fff;
  border-radius: 20px;
  padding: 2rem;
  max-width: 1440px;
  box-shadow: rgba(255, 255, 255, 0.92) -3px -2px 4px,
    rgba(86, 86, 86, 0.2) 3px 1px 5px,
    rgba(255, 255, 255, 0.21) 0px 0px 2px inset;

  @include respond-to('phone') {
    padding: 1rem;
  }

  section {
    margin-bottom: 1.8rem;
    @include respond-to('phone') {
      margin-bottom: 3rem;
    }
  }

  // universal style for same classname
  .filter-options-container {
    @include flex(row, flex-start, flex-start);
    gap: 2rem;
    flex-wrap: wrap;

    @include respond-to('phone') {
      gap: 3rem;
    }
  }

  .dropdown-container {
    width: 180px;

    @include respond-to('phone') {
      width: 100%;
    }
  }

  .p-dropdown {
    width: 100%;
    height: 50px;
  }

  .p-paginator {
    justify-content: center;
  }

  .p-input-icon-right {
    @include respond-to('phone') {
      width: 100%;
    }

    .single-range-input {
      @include respond-to('phone') {
        width: 100%;
      }
    }

    .p-inputtext {
      width: 180px;
      height: 50px;
      @include respond-to('phone') {
        width: 100%;
      }
    }
  }

  .input-active {
    label {
      background-color: #3f46f6 !important;
      color: white;
    }

    .p-inputtext {
      background-color: #3f46f6;
      color: white;
      font-size: 0.9rem;
    }

    .pi-chevron-down {
      color: white;
    }

    input:focus ~ label {
      color: white !important;
    }
  }

  .dropdown-container-active {
    .p-dropdown {
      background-color: #3f46f6;

      .p-inputtext {
        color: white;
        font-size: 0.9rem;
      }
    }

    .p-float-label {
      label {
        background-color: #3f46f6;
        color: white;
      }
    }
    .pi-chevron-down {
      color: white;
    }
  }

  .single-range-input {
    @include flex(column, flex-start, flex-start);
    gap: 0.5rem;
    position: relative;
    .error-message {
      position: absolute;
      color: red;
      font-size: 0.8rem;
      bottom: -25px;
    }

    @include respond-to('phone') {
      width: 100%;
    }
  }

  // p {
  //   padding-bottom: 0.5rem;
  // }

  //end of common styles

  .forecast-container {
    @include flex(column, center, flex-start);
    width: 100%;

    span:first-child {
      font-size: 0.8rem;
    }

    .decrease {
      color: red;
    }
    .increase {
      color: green;
    }
  }

  .forecasts-section,
  .reset-section {
    .p-button {
      width: 180px;
      height: 50px;
      @include respond-to('phone') {
        width: 100%;
      }
    }
  }

  .forecasts-section {
    .price-button {
      color: rgba(0, 0, 0, 0.5);
      background: #ffffff;
      border: 1px solid rgba(0, 0, 0, 0.38);

      &:enabled:hover,
      &:enabled:focus,
      &:enabled:active,
      &:enabled:focus-visible {
        color: rgba(0, 0, 0, 0.5);
        background: #ffffff;
        border: 1px solid rgba(0, 0, 0, 0.38);
      }

      .p-button-label {
        text-align: left;
      }
    }
  }

  .table-section {
    .p-column-header-content {
      .p-column-title {
        min-width: max-content;
      }
    }

    p {
      font-size: 1.3rem;
      padding-bottom: 0rem;
      margin-bottom: 0rem;
    }

    .table-title-container {
      @include flex(row, space-between, center);
      gap: 1rem;
      padding-bottom: 1rem;
      border-top: 2px solid #877f7f;
      padding-top: 2rem;
    }
  }

  tr {
    &:hover {
      background-color: #cbcbcb !important;
      cursor: pointer;
    }
  }
}

// override the default css style of prime react library
.range-container {
  @include flex(row, center, center);
  gap: 1rem;
}

.range-input {
  .p-inputnumber {
    .p-inputtext {
      width: 100px;
      padding: 0.5rem 1rem;

      @include respond-to('phone') {
        width: 100%;
      }
    }
  }
}

.p-dropdown-panel {
  .p-dropdown-items {
    .p-dropdown-item {
      justify-content: flex-start !important;
      // margin-left: 0.5rem !important;
      padding-left: 0.5rem !important;

      font-size: 0.8rem;
    }
  }
}

.radio-group-container {
  padding: 1rem 0rem;

  &:first-child {
    padding-top: 0rem;
  }
  &:last-child {
    padding-bottom: 0rem;
  }
  .radio-buttons {
    @include flex(row, flex-start, flex-start);
    // gap: 2rem;
    flex-wrap: wrap;
    width: 100%;
    // gap: 0.5rem;

    label {
      margin-bottom: 0rem;
    }
    .single-radio-button {
      @include flex(row, flex-start, center);
      flex-basis: 30%;
      margin-bottom: 0.5rem;

      @include respond-to('phone') {
        flex-basis: 50%;
      }
      // width: 25%;
      // flex-basis: 25%;
    }
  }
}

.table-section {
  .p-datatable {
    .p-sortable-column {
      .p-sortable-column-icon {
        margin-right: 1rem;
      }
    }
    .p-datatable-tbody > tr > td {
      padding: 0.5rem 1rem;
    }
  }
}
