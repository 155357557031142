.password-container {
  width: 100%;
  position: relative;
  .password-toggle-icon {
    width: 20px;
    height: 20px;
    position: absolute;
    // top: 20px;
    top: 22px;
    right: 18px;
    cursor: pointer;
  }
}
