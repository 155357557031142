@import '../../reuse/mixins.scss';
@import '../../reuse/variables.scss';

.budgeting-notifications {
  .budgeting-notification {
    border-bottom: 1px solid rgba(128, 128, 128, 0.3);
    padding: 15px 0;
    .budgeting-notifications-row {
      @include flex(row, space-between, flex-start);
    }
    .budgeting-notifications-bold {
      font-family: 'Akkurat-Bold';
      font-size: 17px;
      margin: 0;
    }
    .budgeting-notifications-light {
      color: rgba(128, 128, 128, 0.8);
      font-size: 15px;
    }
    .budgeting-notifications-regular {
      font-size: 17px;
      margin: 0;
    }
  }
}
