ol {
  margin: 0;
  padding: 0;
}
table td,
table th {
  padding: 0;
}
.policy-container {
  padding: 40px;
}
.c14 {
  margin-left: 0.7pt;
  padding-top: 16pt;
  text-indent: -0.6pt;
  padding-bottom: 0pt;
  line-height: 1.1015489101409912;
  text-align: left;
  margin-right: 8.6pt;
}
.c3 {
  margin-left: 0.1pt;
  padding-top: 37pt;
  text-indent: 0.1pt;
  padding-bottom: 0pt;
  line-height: 1.1015489101409912;
  text-align: left;
  margin-right: 23.3pt;
}
.c15 {
  margin-left: 0.2pt;
  padding-top: 16pt;
  text-indent: -0.1pt;
  padding-bottom: 0pt;
  line-height: 1.1015489101409912;
  text-align: left;
  margin-right: 8.2pt;
}
.c1 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: 'Arial';
  font-style: normal;
}
.c28 {
  margin-left: 0.4pt;
  padding-top: 15.1pt;
  text-indent: 0.6pt;
  padding-bottom: 0pt;
  line-height: 1.1015385389328003;
  text-align: left;
  margin-right: 24.9pt;
}
.c11 {
  margin-left: 0.4pt;
  padding-top: 0.6pt;
  text-indent: 0.5pt;
  padding-bottom: 0pt;
  line-height: 1.1015489101409912;
  text-align: left;
  margin-right: 8.2pt;
}
.c8 {
  margin-left: 0.1pt;
  padding-top: 16pt;
  text-indent: 0.2pt;
  padding-bottom: 0pt;
  line-height: 1.1015492677688599;
  text-align: left;
  margin-right: 12.7pt;
}
.c9 {
  margin-left: 0.3pt;
  padding-top: 16pt;
  text-indent: -0.2pt;
  padding-bottom: 0pt;
  line-height: 1.1015489101409912;
  text-align: left;
  margin-right: 2.9pt;
}
.c2 {
  color: #3f4174;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 10pt;
  font-family: 'Arial';
  font-style: italic;
}
.c20 {
  margin-left: 0.1pt;
  padding-top: 16pt;
  text-indent: 0.4pt;
  padding-bottom: 0pt;
  line-height: 1.101549744606018;
  text-align: left;
  margin-right: 1pt;
}
.c26 {
  margin-left: 0.1pt;
  padding-top: 0.6pt;
  text-indent: -0.1pt;
  padding-bottom: 0pt;
  line-height: 1.1015489101409912;
  text-align: left;
  margin-right: 58.9pt;
}
.c17 {
  margin-left: 0.2pt;
  padding-top: 16pt;
  text-indent: -0.1pt;
  padding-bottom: 0pt;
  line-height: 1.1015489101409912;
  text-align: left;
  margin-right: 11.6pt;
}
.c27 {
  margin-left: 0.4pt;
  padding-top: 16pt;
  text-indent: -0.1pt;
  padding-bottom: 0pt;
  line-height: 1.1015512943267822;
  text-align: left;
  margin-right: 10.6pt;
}
.c31 {
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 15pt;
  font-family: 'Arial';
  font-style: normal;
}
.c0 {
  margin-left: 0.7pt;
  padding-top: 15.1pt;
  text-indent: -0.6pt;
  padding-bottom: 0pt;
  line-height: 1.1015489101409912;
  text-align: left;
  margin-right: 19.8pt;
}
.c13 {
  margin-left: 0.2pt;
  padding-top: 16pt;
  text-indent: 0.3pt;
  padding-bottom: 0pt;
  line-height: 1.1015489101409912;
  text-align: left;
  margin-right: 18.8pt;
}
.c29 {
  margin-left: 0.1pt;
  padding-top: 0.6pt;
  text-indent: -0.1pt;
  padding-bottom: 0pt;
  line-height: 1.101549506187439;
  text-align: left;
  margin-right: 14.5pt;
}
.c24 {
  margin-left: 0.1pt;
  padding-top: 0.6pt;
  text-indent: 0.8pt;
  padding-bottom: 0pt;
  line-height: 1.1015489101409912;
  text-align: left;
}
.c5 {
  margin-left: 0.1pt;
  padding-top: 16pt;
  padding-bottom: 0pt;
  line-height: 1.1015489101409912;
  text-align: left;
  margin-right: 5.9pt;
}
.c19 {
  margin-left: 0.4pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
}
.c6 {
  margin-left: 1.2pt;
  padding-top: 15.2pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
}
.c12 {
  margin-left: 0.8pt;
  padding-top: 1.7pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
}
.c30 {
  margin-left: 0.7pt;
  padding-top: 15.2pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
}
.c18 {
  margin-left: 1.1pt;
  padding-top: 15.2pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
}
.c4 {
  margin-left: 0.5pt;
  padding-top: 0.6pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
}
.c25 {
  margin-left: 0.5pt;
  padding-top: 1.7pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
}
.c23 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  margin-right: 101.3pt;
}
.c16 {
  padding-top: 16pt;
  padding-bottom: 0pt;
  line-height: 1.1015535593032837;
  text-align: justify;
  margin-right: 10.4pt;
}
.c21 {
  margin-left: 0.5pt;
  padding-top: 15.2pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
}
.c10 {
  margin-left: 0.4pt;
  padding-top: 15.2pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
}
.c7 {
  margin-left: 0.9pt;
  padding-top: 15.2pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
}
.c22 {
  background-color: #ffffff;
  max-width: 421pt;
  padding: 71.1pt 119pt 76.3pt 72pt;
}
.title {
  padding-top: 24pt;
  color: #000000;
  font-weight: 700;
  font-size: 36pt;
  padding-bottom: 6pt;
  font-family: 'Arial';
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
// .subtitle {
//   padding-top: 18pt;
//   color: #666666;
//   font-size: 24pt;
//   padding-bottom: 4pt;
//   font-family: 'Georgia';
//   line-height: 1.15;
//   page-break-after: avoid;
//   font-style: italic;
//   orphans: 2;
//   widows: 2;
//   text-align: left;
// }
li {
  color: #000000;
  font-size: 11pt;
  font-family: 'Arial';
}
p {
  margin: 0;
  // color: #000000;
  font-size: 11pt;
  font-family: 'Arial';
}
h1 {
  padding-top: 24pt;
  color: #000000;
  font-weight: 700;
  font-size: 24pt;
  padding-bottom: 6pt;
  font-family: 'Arial';
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h2 {
  padding-top: 18pt;
  color: #000000;
  font-weight: 700;
  font-size: 18pt;
  padding-bottom: 4pt;
  font-family: 'Arial';
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h3 {
  padding-top: 14pt;
  color: #000000;
  font-weight: 700;
  font-size: 14pt;
  padding-bottom: 4pt;
  font-family: 'Arial';
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h4 {
  padding-top: 12pt;
  color: #000000;
  font-weight: 700;
  font-size: 12pt;
  padding-bottom: 2pt;
  font-family: 'Arial';
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h5 {
  padding-top: 11pt;
  color: #000000;
  font-weight: 700;
  font-size: 11pt;
  padding-bottom: 2pt;
  font-family: 'Arial';
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h6 {
  padding-top: 10pt;
  color: #000000;
  font-weight: 700;
  font-size: 10pt;
  padding-bottom: 2pt;
  font-family: 'Arial';
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
