.new-subscription-dialog {
  width: 500px;

  @media screen and (max-width: 600px) {
    width: 90vw;
  }

  .p-dialog-header {
    background: #3627e4 !important;
    color: white !important;
  }

  .p-dialog-content {
    background: linear-gradient(
      180deg,
      #3627e4 30.73%,
      #0096c6 100%
    ) !important;
    color: white !important;

    > p {
      color: #fff;
      font-size: 1.5625rem;
      font-weight: 700;
      padding: 2rem 0rem;

      @media screen and (max-width: 800px) {
        font-size: 1.5rem;
      }
    }

    > p:nth-child(1) {
      padding-top: 0rem;
    }

    .single-card-container {
      display: flex;
      flex-direction: column;
      // gap: .5rem;
      color: black;
      border-radius: 1.5rem;
      background: #fffeff;
      padding: 1rem 1.5rem;
    }

    .build-ai-container {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      .single-card-container {
        > p {
          color: #070508;
          font-size: 1.1875rem;
          font-weight: 400;
        }
        > div {
          display: flex;
          align-items: center;
          gap: 1.5rem;

          p {
            color: #070508;
            font-size: 0.875rem;
            font-weight: 400;
          }

          img {
            width: 60px;
            height: 60px;
          }
        }
      }
    }

    .stocks-container {
      border-radius: 24px;
      background: #fffeff;
      padding: 1rem 1.5rem;
      color: #070508;

      > p {
        padding-left: 1rem;
      }

      .single-card-container {
        border-radius: 8px;
        background: #fff;
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.13);
        margin: 0.8rem;

        svg {
          width: 40px;
          height: 40px;
          border: 0.5px solid gray;
          padding: 0.5rem;
          margin-bottom: 0.5rem;
        }

        .price-box {
          border-radius: 2px;
          color: white;
          width: fit-content;
          padding: 0.3rem 0.5rem;

          p {
            display: flex;
            gap: 0.5rem;
          }
        }
      }

      .p-carousel .p-carousel-indicators {
        padding-bottom: 0rem;
        @media screen and (max-width: 1000px) {
          display: none;
        }
      }
      .p-carousel-prev {
        display: none;
      }

      .p-carousel-next {
        display: none;
      }
    }

    .dynmaic-header-section {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .new-subscription-card-container {
        border-radius: 24px;
        background: #fffeff;
        color: #070508;
        padding: 1rem;
        display: flex;
        align-items: center;
        gap: 1.5rem;
        // margin: .5rem;
        height: 100%;
        margin: 0rem 0.5rem;
        min-height: 120px;

        .subscription-info-container {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          p {
            color: #070508;
            font-size: 1.1875rem;
            font-weight: 400;
          }
          span {
            color: #070508;
            font-size: 0.875rem;
            font-weight: 400;
          }
        }
      }
      .p-carousel .p-carousel-indicators {
        // display: none;
        @media screen and (max-width: 1000px) {
          display: none;
        }
      }
      .p-carousel-prev {
        display: none;
      }

      .p-carousel-next {
        display: none;
      }
    }

    .custom-insight-container {
      .single-card-container {
        height: 100%;
        margin: 0rem 0.5rem;

        > p {
          color: #070508;
          text-align: center;
          font-size: 1.1875rem;
          font-style: italic;
          font-weight: 400;

          padding-bottom: 0.5rem;
        }

        > div {
          color: #070508;

          font-size: 0.875rem;
          font-style: italic;
          font-weight: 400;

          p {
            display: flex;
            justify-content: space-between;
            gap: 1rem;
          }
        }
      }
      .p-carousel .p-carousel-indicators {
        // display: none;
        @media screen and (max-width: 1000px) {
          display: none;
        }
      }
      .p-carousel-prev {
        display: none;
      }

      .p-carousel-next {
        display: none;
      }
    }

    .build-ai-container {
      .single-card-container {
        height: 100%;
        margin: 0rem 0.5rem;
        gap: 0.5rem;
      }
      .p-carousel .p-carousel-indicators {
        // display: none;
        @media screen and (max-width: 1000px) {
          display: none;
        }
      }
      .p-carousel-prev {
        display: none;
      }

      .p-carousel-next {
        display: none;
      }
    }

    .new-subscription-bottom-section {
      .future-finance {
        border-radius: 24px;
        background: #fffeff;
        color: #070508;
        padding: 1rem;
        margin-top: 2rem;

        > p {
          color: #070508;
          text-align: center;
          font-size: 1.1875rem;
          font-weight: 400;
          padding-bottom: 1rem;
        }

        > div {
          display: flex;
          justify-content: center;
          // gap: 1rem;

          .p-divider {
            width: 2px;
            background: rgba(0, 0, 0, 0.16);
          }

          > div {
            display: flex;
            flex-direction: column;
            gap: 1rem;

            p {
              display: flex;
              flex-direction: column;
              font-size: 1.125rem;

              @media screen and (max-width: 500px) {
                font-size: 1rem;
              }
              span {
                font-size: 0.875rem;

                @media screen and (max-width: 500px) {
                  font-size: 0.75rem;
                }
              }
            }
          }
        }
      }

      .subscription-footer {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        > p {
          // color: #070508;
          color: #ffffff;
          text-align: center;
          font-size: 1.5625rem;
          font-weight: 700;
          padding-top: 2rem;
        }

        > span {
          opacity: 0.73;
          color: white;
          font-size: 0.75rem;
          font-weight: 400;
          text-align: center;
        }

        .clickable-text {
          opacity: 0.73;
          color: white;
          font-size: 0.75rem;
          font-weight: 400;
          text-align: center;

          span {
            text-decoration-line: underline;
          }
        }
      }

      .service-charge-container {
        display: flex;
        flex-direction: column;
        text-align: center;
        gap: 0.5rem;
        margin: 1rem 0rem;
        p:nth-child(1) {
          // color: #070508;
          color: #ffffff;
          font-size: 1.125rem;
          font-weight: 400;
        }
        p:nth-child(2) {
          // color: #070508;
          color: #ffffff;
          font-size: 1rem;
          font-weight: 400;
        }

        button {
          border-radius: 140px;
          background: #3728e4;
          // padding: 1rem 5rem;
          // margin-bottom: 2rem;
          font-size: 1.25rem;
          font-style: italic;

          display: flex;
          justify-content: center;
          gap: 0.5rem;

          // @media screen and (max-width: 600px) {
          //   font-size: 1rem;
          // }
        }
      }
    }
  }

  .subscribe-button {
    .pi {
      font-size: 16px !important;
    }
    .p-button-label {
      flex: inherit;
    }
  }
}

.p-component-overlay {
  backdrop-filter: blur(20px);
}
