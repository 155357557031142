@import '../reuse/mixins.scss';
@import '../reuse/variables.scss';

.login__button {
  border: 2px $primary-color solid;
  background-color: $primary-color;
  color: white;
  -webkit-transition: background-color 200ms linear;
  -moz-transition: background-color 200ms linear;
  -o-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  cursor: pointer;
  &:hover {
    text-decoration: none;
    background-color: white;
    color: $primary-color;
  }
}
.mobileButton__container {
  height: 50px;
  border-radius: 100px;
  overflow: hidden;
  img {
    height: 100%;
    object-fit: contain;
  }
}

.hero__container {
  height: 100vh;
  width: 100%;
  padding-top: 75px;
  margin-bottom: 7.5%;
  @include flex(row, space-between, center);
  .left {
    margin-top: 7%;
    height: 100%;
    flex: 1;
    position: relative;
    .phoneImage__container {
      height: 100%;
      width: 100%;
      top: 0;
      left: -10%;
      position: absolute;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .phoneImageBackground__container {
      height: 60%;
      width: 45%;
      position: absolute;
      bottom: 16%;
      left: -5%;
      z-index: -1;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  .center {
    flex: 0.15;
    height: 100%;
  }
  .right {
    height: 100%;
    flex: 1;
    text-align: right;
    @include flex(column, space-around, flex-end);
    font-size: $default-font-size;
    padding-right: 5%;
    padding-bottom: 5%;
    .format__container {
      height: 100%;
      @include flex(column, space-around, center);
      padding: 0 5%;
      @media screen and (min-width: 1920px) {
        padding-top: 10%;
      }
      .mainText__container {
        width: 100%;
        @include flex(column, flex-start, center);
        line-height: 5vw;
      }
      .row__container {
        @include flex(row, space-between, center);
        width: 100%;
        height: 50px;
        .button__filled {
          font-family: 'ProximaNova';
          cursor: pointer;
          background-color: $primary-color;
          color: white;
          border-radius: 100px;
          padding: 10px 20px;
          border: 2px $primary-color solid;
          width: 40%;
          max-width: 1000px;
          height: 100%;
          text-align: center;
          @include flex(column, center, center);
          -webkit-transition: background-color 200ms linear;
          -moz-transition: background-color 200ms linear;
          -o-transition: background-color 200ms linear;
          -ms-transition: background-color 200ms linear;
          transition: background-color 200ms linear;
          &:hover {
            text-decoration: none;
            background-color: white;
            color: $primary-color;
          }
        }
        .button__clear {
          font-family: 'ProximaNova';
          cursor: pointer;
          background-color: white;
          color: $primary-color;
          border-radius: 100px;
          padding: 10px 20px;
          border: 2px $primary-color solid;
          width: 40%;
          max-width: 1000px;
          height: 100%;
          @include flex(column, center, center);
          -webkit-transition: background-color 200ms linear;
          -moz-transition: background-color 200ms linear;
          -o-transition: background-color 200ms linear;
          -ms-transition: background-color 200ms linear;
          transition: background-color 200ms linear;
          &:hover {
            text-decoration: none;
            background-color: $primary-color;
            color: white;
          }
        }
      }
      .text__container {
        width: 75%;
        align-self: flex-end;
      }
      .title__text {
        font-size: 4rem;
        font-family: 'ProximaNova-ExtraBold';
        color: $primary-color;
      }
      .title__text2 {
        font-size: 3rem;
        font-family: 'ProximaNova-Bold';
      }

      @media screen and (min-width: 1920px) {
        font-size: 1.35vw;
        padding-bottom: 5%;
        .title__text {
          font-size: 6rem;
        }
        .title__text2 {
          font-size: 4.25rem;
        }
        .row__container {
          height: 4vw;
          max-height: 90px;
          .button__clear {
            max-width: 800px;
          }
          .button__filled {
            max-width: 800px;
          }
        }
        .text__container {
          margin-top: 10%;
        }
        .row__container {
          @include flex(row, space-between, center);
          width: 90%;
          min-height: 10%;
          .button__filled {
            font-family: 'ProximaNova';
            cursor: pointer;
            background-color: $primary-color;
            color: white;
            border-radius: 100px;
            padding: 10px 20px;
            border: 2px $primary-color solid;
            width: 40%;
            max-width: 400px;
            height: 100%;
            @include flex(column, center, center);
            -webkit-transition: background-color 200ms linear;
            -moz-transition: background-color 200ms linear;
            -o-transition: background-color 200ms linear;
            -ms-transition: background-color 200ms linear;
            transition: background-color 200ms linear;
            &:hover {
              text-decoration: none;
              background-color: white;
              color: $primary-color;
            }
          }
          .button__clear {
            font-family: 'ProximaNova';
            cursor: pointer;
            background-color: white;
            color: $primary-color;
            border-radius: 100px;
            padding: 10px 20px;
            border: 2px $primary-color solid;
            width: 40%;
            max-width: 400px;
            height: 100%;
            @include flex(column, center, center);
            -webkit-transition: background-color 200ms linear;
            -moz-transition: background-color 200ms linear;
            -o-transition: background-color 200ms linear;
            -ms-transition: background-color 200ms linear;
            transition: background-color 200ms linear;
            &:hover {
              text-decoration: none;
              background-color: $primary-color;
              color: white;
            }
          }
        }
      }
      @media screen and (min-width: 2560px) {
        .title__text {
          font-size: 7rem;
        }
        .title__text2 {
          font-size: 5rem;
          margin-bottom: 50px;
        }
        .row__container {
          min-height: 100px;
        }
      }
    }
  }
}

.hero__container__sm {
  height: 100vh;
  margin-top: 75px;
  margin-bottom: 100px;
  width: 100%;
  @include flex(column, flex-start, center);
  padding: 0 5%;
  .mainText__container {
    .title__text {
      font-size: 2.75rem;
      font-family: 'ProximaNova-ExtraBold';
      color: $primary-color;
      text-align: center;
    }
    .title__text2 {
      font-size: 2rem;
      text-align: center;
      font-family: 'ProximaNova-Bold';
    }
  }
  .phoneImage__container {
    width: 100%;
    height: 55%;
    margin-right: 10%;
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
  .row__container {
    @include flex(row, space-between, center);
    width: 100%;
    height: 50px;
    font-size: 1rem;
    margin-top: 5%;
    margin-bottom: 5%;
    .button__filled {
      font-family: 'ProximaNova';
      cursor: pointer;
      background-color: $primary-color;
      color: white;
      border-radius: 100px;
      padding: 10px 20px;
      border: 2px $primary-color solid;
      width: 45%;
      height: 100%;
      text-align: center;
      @include flex(column, center, center);
      -webkit-transition: background-color 200ms linear;
      -moz-transition: background-color 200ms linear;
      -o-transition: background-color 200ms linear;
      -ms-transition: background-color 200ms linear;
      transition: background-color 200ms linear;
      &:hover {
        text-decoration: none;
        background-color: white;
        color: $primary-color;
      }
    }
    .button__clear {
      font-family: 'ProximaNova';
      cursor: pointer;
      background-color: white;
      color: $primary-color;
      border-radius: 100px;
      padding: 10px 20px;
      border: 2px $primary-color solid;
      width: 45%;
      max-width: 400px;
      height: 100%;
      @include flex(column, center, center);
      -webkit-transition: background-color 200ms linear;
      -moz-transition: background-color 200ms linear;
      -o-transition: background-color 200ms linear;
      -ms-transition: background-color 200ms linear;
      transition: background-color 200ms linear;
      &:hover {
        text-decoration: none;
        background-color: $primary-color;
        color: white;
      }
    }
  }
  .text__container {
    text-align: center;
    font-size: $default-font-size;
  }
}

.fractionalShares__container {
  height: 100vh;
  background-color: rgb(39, 38, 50);
  @include flex(row, center, center);
  .wrapper__container {
    max-width: 2560px;
    padding-left: 5%;
    @include flex(row, center, center);
    .left {
      position: relative;
      width: 50%;
      height: 100%;
      padding-top: 5%;
      @include flex(column, flex-start, flex-start);
      .title__text {
        color: $primary-color;
        font-size: 3rem;
        margin-bottom: 10%;
        font-weight: bolder;
      }
      .general__text {
        color: white;
        width: 85%;
        font-size: 1.5rem;
        margin-bottom: 20%;
        .green__text {
          color: $primary-color;
        }
      }
      .appButtons__container {
        width: 100%;
        height: 50px;
        img {
          height: 100%;
          object-fit: fill;
        }
      }
      .button {
        background-color: $primary-color;
        color: white;
        @include flex(column, center, center);
        width: 40%;
        min-height: 7.5%;
        border-radius: 100px;
        font-size: 1.5rem;
        cursor: pointer;
        -webkit-transition: background-color 200ms linear;
        -moz-transition: background-color 200ms linear;
        -o-transition: background-color 200ms linear;
        -ms-transition: background-color 200ms linear;
        transition: background-color 200ms linear;
        &:hover {
          text-decoration: none;
          background-color: white;
          color: $primary-color;
        }
      }
    }
    .right {
      width: 55%;
      height: 90%;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
    @media screen and (min-width: 1920px) {
      .left {
        .title__text {
          font-size: 4.75rem;
        }
        .general__text {
          color: white;
          width: 85%;
          font-size: 2.5rem;
          margin-bottom: 10%;
          .green__text {
            color: $primary-color;
          }
        }
        .button {
          font-size: 2rem;
        }
      }
    }
    @media screen and (min-width: 2560px) {
      .left {
        .title__text {
          font-size: 4rem;
        }
        .general__text {
          color: white;
          width: 85%;
          font-size: 2.5rem;
          margin-bottom: 10%;
          .green__text {
            color: $primary-color;
          }
        }
        .button {
          font-size: 3rem;
        }
      }
    }
    @media screen and (min-width: 3440px) {
      .left {
        .title__text {
          font-size: 6rem;
        }
        .general__text {
          color: white;
          width: 85%;
          font-size: 3.5rem;
          margin-bottom: 10%;
          .green__text {
            color: $primary-color;
          }
        }
        .button {
          font-size: 4rem;
        }
      }
    }
  }
}

.fractionalShares__container__sm {
  height: 100vh;
  background-color: rgb(39, 38, 50);
  width: 100%;
  margin-top: 5%;
  @include flex(column, flex-start, center);
  padding-top: 50px;
  .title__text {
    color: $primary-color;
    font-size: 2rem;
    margin-bottom: 10%;
  }
  .general__text {
    color: white;
    width: 85%;
    font-size: 1rem;
    .green__text {
      color: $primary-color;
    }
  }
  .image__container {
    width: 100%;
    height: 40%;
    margin-bottom: 10%;
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
  .button {
    background-color: $primary-color;
    color: white;
    @include flex(column, center, center);
    width: 40%;
    min-height: 6%;
    border-radius: 100px;
    font-size: 1rem;
    cursor: pointer;
    -webkit-transition: background-color 200ms linear;
    -moz-transition: background-color 200ms linear;
    -o-transition: background-color 200ms linear;
    -ms-transition: background-color 200ms linear;
    transition: background-color 200ms linear;
    &:hover {
      text-decoration: none;
      background-color: white;
      color: $primary-color;
    }
  }
  .appButtons__container {
    width: 100%;
    @include flex(row, center, center);
    img {
      height: 50px;
    }
  }
}
.robo__container {
  width: 100%;
  height: 90vh;
  @include flex(column, center, center);
  .wrapper__container {
    max-width: 2560px;
    height: 100%;
    width: 100%;
    padding: 5% 0%;
    @include flex(row, space-between, center);
    .left {
      width: 60%;
      height: 100%;
      @include flex(column, flex-start, flex-start);
      padding: 0 5%;
      .top__container {
        width: 100%;
        height: 70%;
        .title__text {
          font-size: 3rem;
          line-height: 2rem;
          font-weight: bold;
          font-family: 'ProximaNova-ExtraBold';
          .green__text {
            color: $primary-color;
            font-weight: bold;
            font-family: 'ProximaNova-ExtraBold';
          }
        }
        .general__text {
          font-size: 1.25rem;
          .green__text {
            color: $primary-color;
          }
        }
      }
      .bottom__container {
        height: 40%;
        width: 100%;
        @include flex(column, center, center);
        img {
          height: 100%;
          object-fit: cover;
        }
      }
    }
    .right {
      width: 45%;
      height: 100%;
      @include flex(column, space-between, center);
      img {
        width: 100%;
        height: 90%;
        object-fit: fill;
        margin-bottom: 5%;
      }
      .largeInvestingButton__container {
        font-family: 'ProximaNova';
        font-size: 1.25rem;
        min-height: 10%;
        background-color: $primary-color;
        width: 55%;
        @include flex(column, center, center);
        color: white;
        border-radius: 50px;
        cursor: pointer;
        text-decoration: none;
        border: 2px $primary-color solid;
        -webkit-transition: background-color 200ms linear;
        -moz-transition: background-color 200ms linear;
        -o-transition: background-color 200ms linear;
        -ms-transition: background-color 200ms linear;
        transition: background-color 200ms linear;
        &:hover {
          color: $primary-color;
          background-color: white;
        }
      }
    }
    @media screen and (min-width: 1920px) {
      .left {
        .top__container {
          .title__text {
            font-size: 5rem;
            line-height: 5rem;
          }
          .general__text {
            font-size: 1.6rem;
            .green__text {
              color: $primary-color;
            }
          }
        }
      }
      .right {
        .largeInvestingButton__container {
          font-size: 2rem;
          width: 65%;
        }
      }
    }
    @media screen and (min-width: 2560px) {
      .right {
        .largeInvestingButton__container {
          font-size: 2rem;
          width: 45%;
        }
      }
    }
  }
}
.robo__container__sm {
  padding: 5%;
  @include flex(column, flex-start, center);
  .title__text {
    font-size: 2.25rem;
    line-height: 2rem;
    font-weight: bold;
    font-family: 'ProximaNova-ExtraBold';
    align-self: flex-start;
    .green__text {
      color: $primary-color;
      font-weight: bold;
      font-family: 'ProximaNova-ExtraBold';
    }
  }
  .general__text {
    font-size: 1rem;
  }
  img {
    width: 100%;
    height: 20%;
    object-fit: contain;
    margin-bottom: 15%;
  }
  .largeInvestingButton__container {
    text-decoration: none;
    width: 100%;
    @include flex(column, center, center);
    height: 50px;
    background-color: $primary-color;
    color: white;
    border: 2px $primary-color solid;
    border-radius: 25px;
    font-family: 'ProximaNova';
    max-width: 350px;
    cursor: pointer;
    &:hover {
      background-color: white;
      color: $primary-color;
    }
  }
}

// NEW LANDING PAGE STLYES BELOW
// START

.errors__container {
  color: red;
}

.navbar__container__background__white {
  @include flex(column, center, center);
  height: 50px;
  background-color: white;
  position: absolute;
  top: 0;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.08);
  width: 100%;
  -webkit-transition: background-color 0.5s ease-in-out;
  -moz-transition: background-color 0.5s ease-in-out;
  -ms-transition: background-color 0.5s ease-in-out;
  -o-transition: background-color 0.5s ease-in-out;
  transition: background-color 0.5s ease-in-out;
}
.navbar_container {
  background-color: transparent;
  position: fixed;
  top: 0;
  padding: 15px 0;
  width: 100%;
  z-index: 999;
  @media only screen and(max-width: 769px) {
    padding: 0;
  }
}
.navbar__container__background__blur {
  @include flex(column, center, center);
  height: 50px;
  background-color: rgba(248, 248, 248, 0.5);
  position: sticky;
  width: 100%;
  -webkit-transition: background-color 0.5s ease-in-out;
  -moz-transition: background-color 0.5s ease-in-out;
  -ms-transition: background-color 0.5s ease-in-out;
  -o-transition: background-color 0.5s ease-in-out;
  transition: background-color 0.5s ease-in-out;
}
.navbar__container {
  width: 100%;
  height: 100%;
  max-width: 2560px;
  padding-left: 5%;
  padding-right: 1%;
  @include flex(row, space-between, center);
  margin: auto;
  .navLink__container {
    height: 100%;
    @include flex(column, center, flex-start);
    width: 200px;
    min-width: 200px;
    text-decoration: none;
    color: black;
    &.logo {
      height: 60%;
    }
    p {
      margin: 0;
    }
    &:last-child {
      align-items: flex-end;
      padding-right: 10px;
    }
    img {
      height: 75%;
      object-fit: contain;
      cursor: pointer;
    }
    &.left {
      align-items: flex-end;
      @include flex(row, space-around, center);
    }
  }
  .left__container {
    flex: 1;
    height: 100%;
    @include flex(row, start, center);
    .menu__container {
      height: 100%;
      // width: 50%;
      max-width: 800px;
      @include flex(row, space-between, center);
      .title__container {
        @include flex(column, center, center);
        height: 100%;
        position: relative;
        // flex: 1;
        .highlight {
          height: 75%;
          width: 100%;
          border-radius: 10px;
          padding: 10px;
          @include flex(column, center, center);
          transition: 300ms;
          color: white;
          font-size: 19px;
          font-family: Akkurat-Light;
        }
        .highlight.active {
          // background: #f5f5f5;
          text-decoration: underline;
          cursor: pointer;
          transition: 300ms;
        }
      }
      .hover__container {
        position: fixed;
        top: 50px;
        width: 100vw;
        height: 200px;
        left: 0;
        border-top: 1px black solid;
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.08);
        @include flex(column, flex-start, center);
        background-color: white;
        .dropdownMenu__container {
          width: 100%;
          max-width: 1920px;
          height: 100%;
          padding: 20px 200px;
        }
        .dropdownMenu__container.active {
          opacity: 1;
          transition: 1000ms;
          z-index: 99999;
        }
        .dropdownMenu__container.trade {
          @include flex(row, space-around, center);
          div {
            &:hover {
              text-decoration: underline;
              cursor: pointer;
            }
          }
        }
        .dropdownMenu__container.products {
          @include flex(row, space-around, center);
          .column__container {
            height: 100%;
            @include flex(column, flex-start, flex-start);
            p {
              text-decoration: underline;
              margin-bottom: 10px;
            }
            .link__container {
              margin-bottom: 10px;
              text-decoration: none;
              color: black;
            }
          }
        }
        .dropdownMenu__container.tools {
          @include flex(row, space-around, center);
          .column__container {
            height: 100%;
            @include flex(column, flex-start, flex-start);
            .link__container {
              margin-bottom: 10px;
              text-decoration: none;
              color: black;
            }
          }
        }
      }
    }
    .navLink__container {
      height: 100%;
      @include flex(column, center, center);
      img {
        height: 75%;
        object-fit: contain;
        cursor: pointer;
      }
    }
  }
}

.hero__container__background {
  height: auto;
  width: 100%;
  @include flex(column, center, center);
}

.hero__container__2 {
  max-width: 1440px;
  width: 100%;
  height: 100%;
  height: 80vh;
  padding: 0 100px;
  @include flex(row, center, center);
  .left__container {
    width: 70%;
    height: 100%;
    padding-top: 10%;
    @include flex(column, flex-start, flex-start);
    .subText__container {
      margin-bottom: 10%;
      width: 550px;
      letter-spacing: 1%;
    }
    .signup__container {
      @include flex(row, flex-start, center);
      height: 50px;
      width: 100%;
      max-width: 600px;
      input {
        flex: 1;
        border: none;
        padding: 13px 10px;
        &::placeholder {
          overflow: visible;
        }
        margin-right: 20px;
      }
    }
  }
  .right__container {
    width: 570px;
    @include flex(column, flex-end, flex-end);
    img {
      width: 100%;
      object-fit: contain;
    }
  }
}

.get-the-app {
  width: 100%;
  @include flex(column, center, center);
  text-align: center;
  font-family: Akkurat-Bold;
  margin: 60px 0;
  text-transform: uppercase;
}

.products-panel-container {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 85vh;
  width: calc(100vw - 400px);
  max-height: 1080px;
  max-width: 1520px;
  background-color: #716ff6;
  @include flex(column, center, center);
  padding: 2.5%;
  @media screen and (max-width: 1000px) {
    width: 90vw;
    height: 90vh;
    overflow: scroll;
    max-height: none;
  }
  .products-card {
    height: 100%;
    width: 100%;
    background-color: darkgray;
  }
  &.ai-trader-background {
    background-color: #000000;
  }
  &.goals-background {
    background-color: #29aae3;
  }
  &.budgeting-background {
    background-color: #a5a6f6;
  }
  .products-panel-wrapper {
    @include flex(column, flex-start, center);
    height: 100%;
    width: 100%;
    color: white;
    @media screen and (max-width: 1000px) {
      height: 100%;
      padding: 25px 10px;
    }
    .back-container {
      height: 40px;
      width: 100%;
      @include flex(row, space-between, center);
      p {
        padding: 0;
        margin: 0;
        font-size: 30px;
      }
      img {
        margin: 0;
        height: 30px;
      }
      .product-title-text {
        font-size: 24px;
      }
    }
    .center-content {
      @include flex(column, center, center);
      width: 100%;
      @media screen and (max-width: 1000px) {
        padding: 0;
      }
      .content-container {
        height: 100%;
        @include flex(column, center, flex-start);
        max-width: 1000px;
        max-height: 600px;
        width: 100%;
        padding: 0 50px;
        @media screen and (max-width: 1000px) {
          padding: 0;
          max-height: none;
        }
        &.row1 {
          width: 100%;
          @include flex(row, center, center);
          padding: 0 50px;
          @media screen and (max-width: 1000px) {
            @include flex(column, center, center);
          }
          &.left-align {
            padding: 0;
          }
          .column-container {
            flex: 1;
            height: 100%;
            @include flex(column, flex-start, flex-start);
            &:first-child {
              margin-right: 5%;
            }
            &.spacing {
              p {
                margin-bottom: 25px;
              }
            }
            p {
              margin: 0;
            }
            .primary-text {
              font-size: 24px;
            }
            .small-text {
              font-size: 16px;
              margin-bottom: 15px;
              &.short {
                width: 60%;
              }
              @media screen and (max-width: 1440px) {
                font-size: 14px;
              }
            }
            .img-container {
              width: 100%;
              flex: 1;
              @include flex(column, flex-start, center);
              img {
                width: 60%;
                object-fit: contain;
              }
              @media screen and (max-width: 1000px) {
                display: none;
              }
            }

            .group-of-four {
              width: 100%;
              display: grid;
              grid-template-columns: 150px 150px;
              grid-template-rows: 40px 40px;
              grid-column-gap: 20px;
              grid-row-gap: 20px;
              margin: 20px 0;
              .single-element {
                border: 2px solid #5db6ef;
                height: 100%;
                width: 100%;
                @include flex(column, center, center);
                border-radius: 25px;
              }
            }
          }
        }
        .robo-chart-container {
          @include flex(row, center, center);
          width: 100%;
          flex: 1;
          @media screen and (max-width: 1000px) {
            @include flex(column, center, center);
            width: 100%;
          }
          .inputs {
            width: 100%;
            @include flex(column, flex-start, flex-start);
            p {
              margin: 0;
              padding: 0;
            }
            .final-amount {
              font-size: 24px;
            }
            .thin {
              font-family: 'Akkurat-Thin';
            }
            flex: 1;
            margin: 0;
            height: 100%;
            .slider-container {
              width: 100%;
            }
            input[type='range'] {
              height: 27px;
              -webkit-appearance: none;
              width: 100%;
              background: transparent;
              border-color: transparent;
              color: transparent;
            }
            input[type='range']:focus {
              outline: none;
            }
            input[type='range']::-webkit-slider-runnable-track {
              width: 100%;
              height: 3px;
              cursor: pointer;
              animate: 0.2s;
              box-shadow: 0px 0px 0px #000000;
              background: #fff;
              border-radius: 5px;
              border: 0px solid #000000;
            }
            input[type='range']::-webkit-slider-thumb {
              box-shadow: 0px 0px 0px #000000;
              border: 3px solid #ffffff;
              height: 18px;
              width: 18px;
              border-radius: 25px;
              background: #716ff6;
              cursor: pointer;
              -webkit-appearance: none;
              margin-top: -9px;
            }
            input[type='range']:focus::-webkit-slider-runnable-track {
              background: #fff;
            }
            input[type='range']::-moz-range-track {
              width: 100%;
              height: 3px;
              cursor: pointer;
              animate: 0.2s;
              box-shadow: 0px 0px 0px #000000;
              background: #fff;
              border-radius: 5px;
              border: 0px solid #000000;
            }
            input[type='range']::-moz-range-thumb {
              box-shadow: 0px 0px 0px #000000;
              border: 3px solid #ffffff;
              height: 18px;
              width: 18px;
              border-radius: 25px;
              background: #716ff6;
              cursor: pointer;
            }
            input[type='range']::-ms-track {
              width: 100%;
              height: 3px;
              cursor: pointer;
              animate: 0.2s;
              background: transparent;
              border-color: transparent;
              color: transparent;
            }
            input[type='range']::-ms-fill-lower {
              background: #fff;
              border: 0px solid #000000;
              border-radius: 10px;
              box-shadow: 0px 0px 0px #000000;
            }
            input[type='range']::-ms-fill-upper {
              background: #fff;
              border: 0px solid #000000;
              border-radius: 10px;
              box-shadow: 0px 0px 0px #000000;
            }
            input[type='range']::-ms-thumb {
              margin-top: 1px;
              box-shadow: 0px 0px 0px #000000;
              border: 3px solid #ffffff;
              height: 18px;
              width: 18px;
              border-radius: 25px;
              background: #716ff6;
              cursor: pointer;
            }
            input[type='range']:focus::-ms-fill-lower {
              background: #fff;
            }
            input[type='range']:focus::-ms-fill-upper {
              background: #fff;
            }
          }
          .graph {
            flex: 1;
            height: 100%;
            padding: 25px 100px;
            @media screen and (max-width: 1000px) {
              padding: 0;
            }
          }
        }
        .robo-text {
          width: 100%;
          @include flex(row, space-between, center);
          .text-column {
            @include flex(column, flex-start, flex-start);
            flex: 1;
            height: 100%;
            max-width: 25%;
            @media screen and (max-width: 1000px) {
              max-width: none;
            }
          }
          @media screen and (max-width: 1000px) {
            @include flex(column, center, flex-start);
            height: auto;
            text-align: flex-start;
          }
        }
        .bottom-button {
          width: 100%;
          @include flex(row, flex-end, flex-end);
          .next-button {
            height: 40px;
            border: 2px solid white;
            border-radius: 25px;
            @include flex(column, center, center);
            padding: 0 20px;
            p {
              margin: 0;
              font-size: 14px;
            }
          }
        }
      }
    }
  }
  .bottom-row {
    width: 100%;
    @include flex(row, center, center);
    position: absolute;
    bottom: 0;
    @media screen and (max-width: 1000px) {
      display: none;
    }
    .dots {
      height: 50px;
      @include flex(row, space-between, center);
      .dot {
        height: 15px;
        width: 15px;
        background-color: #ddd;
        border-radius: 35px;
        &:not(:last-child) {
          margin-right: 5px;
        }
        &.selected {
          background-color: #444;
        }
      }
    }
    .start-button {
      cursor: pointer;
      height: 50px;
      padding: 0 20px;
      border: 2px white solid;
      border-radius: 50px;
      @include flex(column, center, center);
      p {
        margin: 0;
        padding: 0;
        color: white;
      }
    }
  }
}

.features__container__background {
  height: 102.5vh;
  max-height: 1080px;
  width: 100%;
  @include flex(column, center, center);
  background-color: white;
}

.features__container {
  height: 100%;
  width: 100%;
  max-width: 1920px;
  @include flex(column, center, flex-start);
  padding-left: 200px;
  padding-right: 200px;
  .text__container {
    margin-left: 20px;
    margin-bottom: 5%;
    width: 500px;
  }
  .boxes__container {
    @include flex(row, space-between, center);
    height: 37.5%;
    min-height: 350px;
    max-height: 415px;
    width: 100%;
  }
  .featureBox {
    height: 100%;
    flex: 1;
    // box-shadow: 0px 4px 23px rgba(0, 0, 0, 0.08);
    border-radius: 25px;
    overflow: hidden;
    max-width: 300px;
    margin: 10px;
    text-decoration: none;
    color: black;
    cursor: pointer;
    .image__container {
      @include flex(column, flex-start, flex-start);
      height: 50px;
      width: 50px;
      margin: 20px;
      img {
        height: 60px;
        width: 60px;
        object-fit: contain;
      }
    }
    .info__container {
      padding: 20px;
      p {
        margin: 0;
      }
      .small__text {
        font-size: 0.85rem;
      }
    }
    @media screen and (min-width: 1920px) {
    }
  }
}

.features-background-container {
  width: 100%;
  background-color: #252835;
  max-height: 2160px;
  color: white;
  @include flex(column, flex-start, center);
  @media only screen and (max-width: 769px) {
    // height: 200vh;
    height: auto;
  }
  .features-main-container {
    width: 100%;
    max-width: 1440px;
    padding: 100px;
    @include flex(column, flex-start, flex-start);

    .small-title {
      color: #a5a6f6;
      font-size: 24px;
    }
    .large-title {
      font-size: 88px;
      font-family: 'Akkurat-Bold';
      margin-left: 40px;
    }
    .products-wrapper-container {
      width: 800px;
      align-self: center;
      margin-top: 50px;
      overflow: hidden;
    }
  }
  .products-mobile-container {
    padding: 50px;
    @include flex(column, center, flex-start);
    .product-title-mobile {
      font-size: 24px;
    }
    .product-description-mobile {
      font-size: 17px;
    }
    .learn-more-mobile {
      @include flex(row, center, center);
      align-self: flex-end;
      color: #a5a6f6;
      cursor: pointer;
      text-decoration: none;
      p {
        margin: 0;
      }
    }
  }
  .maintenance-fee-container {
    padding: 15px;
    width: 100%;
    .maintenance-fee {
      text-align: center;
      font-family: Akkurat-Light;
      font-size: 15px;
      margin: 0;
    }
  }
}

.products-tab-main-container {
  width: 100%;
  height: auto;
  .tab-navigation-container {
    width: 100%;
    border-bottom: 1px solid grey;
    @include flex(row, space-between, center);
    .product {
      font-size: 24px;
      cursor: pointer;
      .selected-tab {
        color: #a5a6f6;
      }
      .underline {
        height: 5px;
        border-radius: 5px;
        width: 100%;
        background-color: #7b61ff;
        &.hidden {
          background-color: #252835;
        }
      }
    }
  }
  .product-description-container {
    height: 500px;
    padding: 50px 0;
    width: 100%;
    @include flex(column, space-between, flex-start);
    .description {
      width: 60%;
      font-size: 25px;
    }
    .learn-more-button {
      background-color: #7b61ff;
      width: auto;
    }
  }
}

// NEW MOBILE STYLES

.navbar__container__mobile__blur {
  @include flex(column, center, center);
  height: 50px;
  position: fixed;
  width: 100%;
  @include flex(row, space-between, center);
  width: 100%;
  z-index: 999;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.08);
  -webkit-transition: background-color 0.5s ease-in-out;
  -moz-transition: background-color 0.5s ease-in-out;
  -ms-transition: background-color 0.5s ease-in-out;
  -o-transition: background-color 0.5s ease-in-out;
  transition: background-color 0.5s ease-in-out;
  .navbar {
    width: 100%;
    height: 100%;
    .logo {
      height: 100%;
      @include flex(column, center, center);
      font-weight: bold;
      img {
        height: 60%;
        object-fit: contain;
      }
    }
    .icon {
      @include flex(column, center, center);
      height: 40%;
    }
  }
}
.navbar__container__mobile__white {
  @include flex(column, center, center);
  height: 50px;
  width: 100%;
  @include flex(row, space-between, center);
  width: 100%;
  z-index: 999;
  background: white;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.08);
  -webkit-transition: background-color 0.5s ease-in-out;
  -moz-transition: background-color 0.5s ease-in-out;
  -ms-transition: background-color 0.5s ease-in-out;
  -o-transition: background-color 0.5s ease-in-out;
  transition: background-color 0.5s ease-in-out;
  .navbar {
    width: 100%;
    height: 100%;
    padding: 5px 15px;
    .logo {
      height: 100%;
      @include flex(column, center, flex-start);
      img {
        height: 60%;
        object-fit: contain;
      }
    }
    .icon {
      @include flex(column, center, center);
      height: 40%;
    }
  }
}

.dropdown {
  height: 100vh;
  max-height: 100vh;
  z-index: 0;
  width: 100%;
  opacity: 0;
  transition: 125ms;
  position: fixed;
  visibility: hidden;
  z-index: 999999;
}

.dropdown.active {
  visibility: visible;
  opacity: 1;
  background-color: white;
  transition: 125ms;
}

.navbar__dropdown__container {
  height: 100%;
  max-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
  .main__container {
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 100%;
    height: 100%;
    max-height: 100vh;
    padding-bottom: 25;
    position: relative;
    .top__container {
      @include flex(row, space-between, center);
      height: 50px;
      padding-left: 5px;
      padding-right: 10px;
      .icon {
        font-size: 35px;
      }
      .logo {
        height: calc(70% - 1px);
        @include flex(column, center, flex-start);
        img {
          height: 60%;
          object-fit: contain;
        }
      }
    }
    .bottom__container {
      flex: 1;
      background-color: #fbfbfb;
      @include flex(column, flex-start, center);
      width: 100%;
      .item__container {
        @include flex(row, flex-start, center);
        height: 50px;
        text-decoration: none;
        color: black;
        width: 100%;
        padding: 0 30px;
        &:hover {
          background-color: #e8e8e8;
        }
      }
      .button__container {
        background-color: #3181ff;
        color: white;
        margin: 10px 30px;
        height: 50px;
        width: 100%;
        @include flex(column, center, center);
      }
    }
    .login__container {
      position: fixed;
      bottom: 0;
      height: 10%;
      min-height: 80px;
      @include flex(row, center, center);
      width: 100%;
      border-top: 1px solid rgb(233, 232, 232);
      box-shadow: 0px 4px 23px rgba(0, 0, 0, 0.1);
      background-color: white;
      .left__button {
        border: 1px solid black;
        height: 40px;
        @include flex(column, center, center);
        color: black;
        padding: 0 40px;
        border-radius: 50px;
        margin-right: 10px;
        &:hover {
          cursor: pointer;
          text-decoration: none;
          color: white;
        }
      }
      .right__button {
        background-color: $primary-color;
        height: 40px;
        @include flex(column, center, center);
        color: white;
        padding: 0 40px;
        border-radius: 50px;
        text-transform: uppercase;
        &:hover {
          cursor: pointer;
          text-decoration: none;
          color: white;
        }
      }
    }
  }
}

.signupPopUp__container {
  position: fixed;
  bottom: 0;
  height: 10%;
  min-height: 80px;
  width: 100%;
  @include flex(column, flex-start, center);
  z-index: 9999999;
  border-radius: 20px 20px 0 0;
  .signup {
    background-color: white;
    height: 100%;
    width: 100%;
    padding: 0 15px;
    border-top: 1px solid rgb(233, 232, 232);
    box-shadow: 0px 4px 23px rgba(0, 0, 0, 0.1);
    @include flex(row, space-between, center);
    .left {
      height: 100%;
      white-space: nowrap;
      @include flex(column, center, flex-start);
    }
    .app__button {
      background-color: #3181ff;
      flex: 0.9;
      height: 60%;
      @include flex(column, center, center);
      color: white;
      border-radius: 50px;
      box-shadow: 0px 4px 23px rgba(0, 0, 0, 0.1);
    }
  }
  .login__container {
    position: fixed;
    bottom: 0;
    height: 10%;
    min-height: 80px;
    @include flex(row, center, center);
    width: 100%;
    border-top: 1px solid rgb(233, 232, 232);
    box-shadow: 0px 4px 23px rgba(0, 0, 0, 0.1);
    background-color: white;
    .left__button {
      border: 1px solid black;
      height: 40px;
      @include flex(column, center, center);
      color: black;
      padding: 0 40px;
      border-radius: 50px;
      margin-right: 10px;
      &:hover {
        cursor: pointer;
        text-decoration: none;
        color: white;
      }
    }
    .right__button {
      background-color: $primary-color;
      height: 40px;
      text-transform: uppercase;
      @include flex(column, center, center);
      color: white;
      padding: 0 40px;
      border-radius: 50px;
      &:hover {
        cursor: pointer;
        text-decoration: none;
        color: white;
      }
    }
  }
}

// .analytics-ref {
//   height: 30px;
//   width: 50px;
//   background-color: lightskyblue;
//   position: absolute;
//   bottom: 0;
// }

.translateSignUp {
  height: 100vh;
  z-index: 999;
  width: 100%;
  transition: 125ms;
  position: fixed;
  visibility: hidden;
  background-color: white;
  opacity: 0;
}

.translateSignUp.active {
  transition: 125ms;
  position: fixed;
  visibility: visible;
  z-index: 99999999;
  opacity: 1;
}

.popup__container {
  height: 100vh;
  width: 100%;
  z-index: 99999;
  font-size: 1.1rem;
  @include flex(column, center, center);
  background-color: white;
  position: relative;
  .top__container {
    width: 100%;
    height: 50px;
    @include flex(row, space-between, center);
    top: 0;
    position: fixed;
    .close__container {
      position: absolute;
      right: 10px;
      @include flex(column, center, center);
      .icon {
        font-size: 35px;
        cursor: pointer;
      }
    }
  }
  .form__container {
    @include flex(column, center, center);
    width: 100%;
    padding: 0 20px 0 20px;
    height: 100%;
    p {
      align-self: flex-start;
      margin: 10px 0;
    }
    .input__line {
      width: 100%;
      border: solid 1px lightgrey;
      border-radius: 5px;
      padding: 7.5px 10px;
      font-size: 1.25rem;
      height: 64px;
    }
    .phone__input__container {
      height: 50px;
      width: 100%;
    }
    .phone__input {
      width: 100%;
      height: 100%;
    }
    .check__container {
      width: 100%;
      @include flex(row, center, center);
      height: 50px;
      font-size: 14px;
      input {
        margin-right: 12.5px;
      }
    }
    .signup__button {
      cursor: pointer;
      background-color: $primary-color;
      border-radius: 50px;
      width: 100%;
      height: 50px;
      color: white;
      @include flex(column, center, center);
      &:active {
        background-color: #1b4487;
      }
    }
  }
  .codeConfirm__container {
    height: 100%;
    @include flex(column, center, center);
    padding: 0 40px 0 40px;
    text-align: center;
    font-size: 16px;
    width: 100%;
    position: relative;
    .top__container {
      position: absolute;
      top: 0;
    }
    .container {
      height: 300px;
      width: 100%;
      @include flex(column, space-around, center);
    }
    .input__line {
      width: 100%;
      margin-bottom: 10px;
      border: solid 1px lightgrey;
      border-radius: 5px;
      padding: 10px;
      font-size: 1rem;
      height: 64px;
    }
    .bold {
      font-weight: bold;
    }
    .c {
      cursor: pointer;
      color: $primary-color;
    }
    .signup__button {
      width: 100%;
      background-color: $primary-color;
      border-radius: 50px;
      padding: 10px 30px;
      color: white;
    }
  }
}

.hero__container__mobile {
  position: relative;
  height: auto;
  width: 100%;
  min-height: 600px;
  max-height: 1080px;
  @include flex(column, space-between, flex-start);
  padding: 5% 28px 50px 28px;
  .get-started {
    cursor: pointer;
    font-size: 16px;
    margin-bottom: 20px;
    margin-top: 80px;
    @include flex(row, flex-start, center);
    .arrow {
      font-size: 32px;
    }
  }
  .top__container {
    .title__text {
      font-size: 2.25rem;
      line-height: 2.75rem;
      letter-spacing: 0.25px;
    }
  }
  .subText__container {
    @include flex(column, flex-start, flex-start);
    font-size: 18px;
  }
  input {
    width: 100%;
    border: none;
    margin-bottom: 4px;
    border-radius: 4px;
    padding: 20px 10px;
    font-size: 20px;
    &::placeholder {
      overflow: visible;
    }
    background-color: #f5f5f5;
  }
  .long-button {
    width: 100%;
    background-color: $primary-color;
    height: 60px;
    border-radius: 4px;
    @include flex(column, center, center);
    color: white;
    font-weight: bold;
  }
}

.features__container__mobile {
  min-height: 500px;
  padding: 100px 30px 0 30px;
  position: relative;
  .text__container {
    margin-bottom: 36px;
    .subtitle__text {
      color: #333;
    }
  }
  .featureBox {
    border-radius: 25px;
    padding: 30px;
    margin-bottom: 50px;
    text-decoration: none;
    color: black;
  }
}

.largePhoneDisplay__container {
  background-color: rgb(243, 250, 254);
  padding: 100px 30px 100px 30px;
  .image__container {
    width: 100%;
    object-fit: contain;
    img {
      width: 100%;
    }
  }
}

// Sign up Modal Styles

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  background: lightslategray;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.landingPageMain__container {
  position: relative;
  height: 100%;
}

.modal__container {
  background-color: white;
  width: 100%;
  .top__container {
    @include flex(row, center, center);
    position: relative;
    .icon {
      position: absolute;
      right: 2.5%;
      font-size: 30px;
      cursor: pointer;
    }
  }
  .form__container {
    @include flex(column, flex-start, center);
    padding-top: 40px;
    width: 100%;
    padding: 20px;
    height: 100%;
    p {
      align-self: flex-start;
      margin: 10px 0;
    }
    .input__line {
      font-size: 1rem;
      width: 100%;
      border: solid 1px lightgrey;
      border-radius: 5px;
      padding: 15px;
      height: 64px;
    }
    .phone__input__container {
      width: 100%;
      border: none;
      border-radius: 5px;
    }
    .phone__input {
      width: 100%;
      height: 100%;
    }
    .check__container {
      width: 100%;
      @include flex(row, flex-start, center);
      margin: 10px 0;
      padding: 10px 0;
      input {
        margin-right: 5%;
      }
    }
    .signup__button {
      cursor: pointer;
      background-color: $primary-color;
      border-radius: 50px;
      width: 100%;
      height: 45px;
      color: white;
      @include flex(column, center, center);
      cursor: pointer;
      &:active {
        background-color: #1b4487;
      }
    }
    .login__button__2 {
      cursor: pointer;
      background-color: $primary-color;
      border-radius: 50px;
      width: 100%;
      height: 50px;
      color: white;
      margin: 20px 0;
      @include flex(column, center, center);
      &:active {
        background-color: #1b4487;
      }
    }
  }
  .codeConfirm__container {
    height: 100%;
    @include flex(column, flex-start, center);
    padding: 20px;
    text-align: center;
    p {
      width: 400px;
    }
    .input__line {
      height: 7.5%;
      max-height: 50px;
      width: 100%;
      margin-bottom: 10px;
      border: solid 1px lightgrey;
      border-radius: 5px;
      padding: 0 10px;
    }
    .resend__button {
      cursor: pointer;
      color: $primary-color;
    }
  }
}

.language-wrapper {
  width: 100%;
  @include flex(row, flex-end, center);
  .language-toggle-container {
    position: relative;
    padding: 15px;
    width: 150px;
    .language-header {
      margin: 0;
      text-align: center;
      font-size: 15px;
      margin-right: 15px;
      color: rgba(128, 128, 128, 0.5);
      width: 100%;
      height: 100%;
    }
    .p-dropdown {
      border-color: #f6f6f6;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07),
        -3px -2px 4px rgba(255, 255, 255, 0.92),
        3px 1px 5px rgba(86, 86, 86, 0.2),
        inset 0px 0px 2px rgba(255, 255, 255, 0.21);
      border-radius: 10px;
      padding: 5px;
      width: 100%;
      height: 100%;
      background: #f6f6f6;
    }
    .p-dropdown:hover {
      border-color: $primary-color !important;
    }
    .p-dropdown .p-inputtext {
      font-size: 12px;
      padding: 5px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      color: rgba(128, 128, 128, 0.5);
    }
  }
}

.maintenance-fee-container {
  padding: 15px 0;
  .maintenance-fee {
    text-align: center;
    font-family: Akkurat-Light;
    font-size: 15px;
  }
}
