@import '../../reuse/mixins.scss';
@import '../../reuse/variables.scss';
.credit-score-form {
    @include flex(column, center, center);
    .credit-score-form-block {
        margin: 15px 0;
        width: 320px;
        @include flex(column, center, center);
    }
    .credit-score-form-header {
        font-size: 15px;
        font-family: 'Akkurat-Bold';
    }
    .credit-score-form-btn {
        width: 150px;
        height: 50px;
        border-radius: 35px;
        background-color: $primary-color;
        color: white;
        border: none;
        margin-top: 25px;
    }
}