@import "../../../reuse/mixins.scss";
@import "../../../reuse/variables.scss";

.edit-contribution-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .amount-form-container {
    padding: 10px 0;
  }
  .round-up-form-container {
    padding: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .round-up-label {
      margin: 0;
      margin-right: 5px;
    }
  }
  .account-form-container {
    padding: 10px 0;
  }
  .message {
    text-align: center;
    color: red;
  }
  .save-button {
    background: $primary-color;
    border-radius: 25px;
    color: white;
  }
}
