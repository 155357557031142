@import '../reuse/mixins.scss';
@import '../reuse/variables.scss';
.footer-main-container {
  width: 100%;
  // height: 50vh;
  max-width: 1440px;
  // max-width: 1440px;
  // max-height: 450px;
  padding: 0 100px;
  padding-top: 50px;
  @include flex(column, flex-start, center);
  @media screen and (max-width: 1000px) {
    padding: 25px;
  }
  @media screen and (max-width: 769px) {
    height: auto;
    max-height: none;
  }
  .top-container {
    width: 100%;
    flex: 3;
    @include flex(row, center, flex-start);
    .footer-column {
      height: 100%;
      flex: 1;
      @include flex(column, flex-start, flex-start);
      .column-title {
        font-size: 27px;
        color: $primary-color;
      }
      .link {
        font-family: 'Akkurat-Light';
        color: black;
        text-decoration: none;
      }
      .motto {
        font-size: 30px;
        font-family: 'Akkurat-Thin';
        color: $primary-color;
        text-align: center;
      }
      &:last-child {
        @include flex(column, flex-start, center);
      }
      img {
        width: 60%;
        object-fit: contain;
        margin-bottom: 10%;
      }
    }
    @media screen and (max-width: 1000px) {
      @include flex(column-reverse, flex-start, center);
      .footer-column {
        width: 100%;
      }
    }
  }
  .bottom-container {
    width: 100%;
    flex: 1;
    margin-top: 25px;
    @include flex(row, space-around, center);
    p {
      // &:first-child {
      //   margin-right: 25px;
      // }
      text-align: center;
    }
    @media screen and (max-width: 1000px) {
      @include flex(column, center, center);
      font-size: 10px;
      p {
        text-align: center;
        margin: 0;
      }
    }
    @media screen and (max-width: 769px) {
      margin-top: 25px;
    }
  }
}
