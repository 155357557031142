.watchlist-edit-container {
  width: 100%;

  max-width: 1300px;
  margin-right: auto;

  .p-paginator {
    justify-content: center;
  }

  .pi {
    font-size: 1rem !important;
    padding-right: 0.3rem;
  }

  > div:last-child {
    display: flex;
    justify-content: center;
    padding-top: 1rem;
  }
}

.delete-watchlist-confirm-dialog {
  .p-confirm-dialog-reject {
    span {
      color: #d32f2f;
    }
  }
  .p-confirm-dialog-accept {
    background-color: rgb(54, 39, 228);
  }

  .p-dialog-content {
    .pi {
      font-size: 2rem !important;
      color: #d32f2f;
    }
  }
}

.p-dialog {
  margin: 0rem 1rem;

  .p-confirm-dialog-accept:enabled:hover,
  .p-confirm-dialog-accept:enabled:active,
  .p-confirm-dialog-accept:enabled:focus {
    background-color: rgb(54, 39, 228);
  }
}
