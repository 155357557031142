@import '../reuse/mixins.scss';
@import '../reuse/variables.scss';

.dialog-title {
}

.from-account-container {
  margin-bottom: 25px;
  .available-value {
    font-weight: bold;
  }
}
.withdraw-header {
  font-weight: bold;
}

.input-form {
  margin-bottom: 25px;
  .input-label {
    margin-bottom: 0;
  }
}

.error {
  text-align: center;
  color: red;
}

.success-msg {
  text-align: center;
  color: $primary-color;
}

.withdraw-btn-container {
  width: 100%;
  @include flex(row, center, center);
  .withdraw-btn {
    width: 50%;
    border-radius: 25px !important;
    background: $primary-color !important;
    color: white;
  }
}
