@import '../reuse/mixins.scss';
@import '../reuse/variables.scss';

.categorized-investments-container {
  height: 100%;
  .categorized-investments-tab {
    background-color: transparent !important;
    height: 100%;
  }

  .investments-tab {
    background-color: transparent;
    height: 100%;
  }

  .p-tabview .p-tabview-nav {
    background-color: transparent;
    margin: 0 20px;
    .p-tabview .p-tabview-nav {
      background-color: transparent;

      li .p-tabview-nav-link {
        background-color: transparent;
        background: transparent;
        border-bottom: 0;
      }
    }

    li .p-tabview-nav-link {
      background: transparent !important;
    }
  }

  .p-tabview-panels {
    background-color: transparent;
    background: transparent !important;
    height: 100%;
  }

  // ul{
  //     background-color: transparent;
  // }

  // li{
  //     background-color: transparent;

  // }
}
