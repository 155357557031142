@import '../reuse/mixins.scss';
@import '../reuse/variables.scss';

.download-banner {
  position: fixed;
  bottom: 0;
  z-index: 999;
  background-color: white;
  width: 100%;
  height: 75px;
  @include flex(row, space-evenly, center);
  .banner-logo-container {
    width: 10%;
    height: 100%;
    .banner-logo {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }

  .banner-text {
    width: 40%;
    text-align: center;
    margin: 0;
    font-size: 12px;
    font-family: Akkurat-Bold;
  }

  .banner-btn {
    width: 35%;
    border: none;
    border-radius: 25px;
    font-size: 15px;
    background-color: $primary-color;
    color: white;
    height: 70%;
    cursor: pointer;
    font-family: Akkurat-Bold;
  }

  .banner-remove-btn {
    margin: 0;
    color: rgba(128, 128, 128, 1);
    position: absolute;
    top: 0;
    right: 0;
    padding-top: 5px;
    padding-right: 5px;
  }
}
