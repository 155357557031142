@import "../reuse/mixins.scss";
@import "../reuse/variables.scss";

.mobile-disclosure-main-conatiner {
    width: 100%;
    padding: 25px;
    .title {
        font-size: 30px;
    }
    ol {
        list-style-position: inside;
    }
    ul {
        list-style-position: inside;
    }
}

.disclosoue-main-container {
    width: 100%;
    padding: 25px;
    .center-container {
        width: 100%;
        display: flex;
        justify-content: center;
    }
}