@import '../reuse/mixins.scss';
@import '../reuse/variables.scss';

.disclaimer-container {
  padding: 50px 50px;
  max-width: 1300px;
  margin-top: auto;

  .disclaimer-heading {
  }
  .disclaimer-text {
  }
}
