@import '../reuse/mixins.scss';
@import '../reuse/variables.scss';

.customized-legend {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 1rem;
  @media screen and (max-width: 1000px) {
    flex-direction: row;
    // justify-content: space-evenly;
    flex-wrap: wrap;
    gap: 0.5rem;
  }

  .value-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // margin-bottom: 1rem;

    .naming-container {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
      p {
        margin-bottom: 0rem;
      }

      .circle-icon {
        width: 20px;
        height: 20px;
        border-radius: 50%;
      }
    }

    p {
      margin-bottom: 0rem;
    }

    @media screen and (max-width: 1000px) {
      flex-basis: 100%;
      border-bottom: 1px solid darkgray;
      padding-bottom: 0.2rem;
      margin-bottom: 0.5rem;
      p {
        margin-bottom: 0rem;
      }
    }

    .custom-legend-label {
      text-align: right;
    }
  }
}
