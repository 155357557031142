@import '../reuse/mixins.scss';
@import '../reuse/variables.scss';

.transfer-funds-dw-container {
  padding: 50px 0;
  .tabs-container {
    background-color: transparent;
    width: 100% !important;
    margin: auto;
    box-shadow: -3px -2px 4px rgba(255, 255, 255, 0.92),
      3px 1px 5px rgba(86, 86, 86, 0.2),
      inset 0px 0px 2px rgba(255, 255, 255, 0.21);
    border-radius: 19px;
    padding: 5px;
    .wire-deposit-container {
      padding: 15px;
    }
    .wire-withdraw-container {
      padding: 15px;
      max-height: 100%;
      overflow: auto;
    }
    .from-account-form-container {
      padding: 25px;
      display: block;
      justify-content: center;
      align-content: center;
      border-radius: 15px;
      background: rgba(128, 128, 128, 0.1);
      width: 60%;
      margin: 25px auto;
      @media only screen and (max-width: 769px) {
        width: 90%;
      }

      .label {
        font-size: 20px;
        font-weight: 500;
      }

      .input {
        border-radius: 15px;
        width: 80%;
        padding: 15px;
        border: 1px solid rgba(128, 128, 128, 0.5);
      }

      .p-dropdown {
        border-radius: 10px;
        width: 100%;
      }

      .balance {
        font-weight: bold;
        font-size: 20px;

        .dollar-amount {
          color: $primary-color;
        }
      }

      .failure {
        width: 100%;
        text-align: center;
      }

      .link-plaid-btn {
        display: flex;
        padding-top: 5px;
        justify-content: right;
      }
    }

    .to-account-form-container {
      padding: 25px;
      display: block;
      justify-content: center;
      align-content: center;
      border-radius: 15px;
      background: rgba(128, 128, 128, 0.1);
      width: 60%;
      margin: 25px auto;
      margin-bottom: 25px;
      @media only screen and (max-width: 769px) {
        width: 90%;
      }
      .label {
        font-size: 20px;
        font-weight: 500;
      }

      .input {
        border-radius: 15px;
        width: 80%;
        padding: 15px;
        border: 1px solid rgba(128, 128, 128, 0.5);
      }

      .p-dropdown {
        border-radius: 10px;
        width: 100%;
      }

      .balance {
        font-weight: bold;
        font-size: 20px;

        .dollar-amount {
          color: $primary-color;
        }
      }

      .failure {
        width: 100%;
        text-align: center;
      }
      .link-plaid-btn {
        display: flex;
        padding-top: 5px;
        justify-content: right;
      }
    }

    .amount-form-container {
      padding: 25px;
      display: block;
      justify-content: center;
      align-content: center;
      border-radius: 15px;
      background: rgba(128, 128, 128, 0.1);
      width: 60%;
      margin: 25px auto;
      @media only screen and (max-width: 769px) {
        width: 90%;
      }
      .label {
        font-size: 20px;
        font-weight: 500;
      }

      .p-inputnumber {
      }

      .p-inputnumber-input {
        border-radius: 10px;
      }

      .amount-limit-message {
        color: red;
        margin: auto;
        text-align: center;
      }
    }

    .submit-button-container {
      padding: 15px;
      width: 60%;
      display: flex;
      margin: auto;
      justify-content: center;
      .p-button {
        border-radius: 25px;
        color: white;
        background-color: $primary-color;
        width: 80%;
      }
    }

    .message-container {
      width: 60%;
      margin: auto;
      border-radius: 15px;
      background: rgba(63, 70, 246, 0.1);
      border: 1px solid $primary-color;
      padding: 15px;
      .message {
        color: $primary-color;
        text-align: center;
      }
    }

    .time-message-container {
      width: 60%;
      margin: auto;
      border-radius: 15px;
      background: rgba(63, 70, 246, 0.1);
      border: 1px solid $primary-color;
      padding: 15px;
      margin-bottom: 25px;
      .message {
        color: $primary-color;
        text-align: center;
      }
    }

    .success {
      font-size: 15px;
      width: 60%;
      text-align: center;
      color: $primary-color;
      margin: auto;
    }

    .failure {
      font-size: 15px;
      width: 60%;
      text-align: center;
      color: red;
      margin: auto;
    }

    .p-tabview .p-tabview-nav {
      background-color: transparent;
      .p-tabview .p-tabview-nav {
        background-color: transparent;

        li .p-tabview-nav-link {
          background-color: transparent;
          background: transparent;
          border-bottom: 0;
        }
      }

      li .p-tabview-nav-link {
        background: transparent !important;
        border: none;
      }
    }

    .p-tabview-panels {
      background-color: transparent;
      background: transparent !important;
      height: 100%;
    }

    .p-tabview-nav-container {
      .p-tabview-nav-content {
        .p-tabview-nav {
          // border: none;
          li {
            border: none;
          }
        }
      }
    }
  }

  .wire-deposit-btn-container {
    .wire-deposit-btn {
      text-align: center;
      cursor: pointer;
      margin: 0;
    }
  }

  .wire-withdraw-btn-container {
    .wire-withdraw-btn {
      text-align: center;
      cursor: pointer;
    }
  }
}

.tab-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.logo-loader {
  position: absolute;
  left: 50%;
  top: 30%;
  transform: translateX(-50%);
  z-index: 999;
}

.option-buttons {
  @include flex(column, space-evenly, center);
  .option-btn {
    background-color: $primary-color;
    border: none;
    color: white;
    font-size: 15px;
    border-radius: 10px;
    height: 100px;
    width: 350px;
    position: relative;
    margin: 25px 0;
  }
}

.white-arrow {
  position: absolute;
  right: 5%;
  top: 40%;
  width: 20px;
  height: 20px;
}
