//Dialog
.p-dialog {
  .p-dialog-header {
    .p-dialog-title {
      text-align: center !important;
      font-size: 20px !important;
    }
  }
}

.change-category.p-dialog {
  .p-dialog-header {
    .p-dialog-title {
      text-align: start !important;
      font-size: 16px !important;
    }
  }
}

//DROPDOWN
// .p-dropdown {
//   width: 100% !important;
// }

//INPUT TEXT
// .p-inputtext {
//     width: 100% !important;
//     @media screen and (max-width: 769px) {
//       width: 100% !important;
//     }
//   }
