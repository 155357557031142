@import '../../scss/reuse/mixins.scss';
@import '../../scss/reuse/variables.scss';

.ib-status-main-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;
  .ib-status-main-text {
    font-size: 30px;
  }
  .ib-pending-icons {
    display: flex;
    gap: 16px;
  }
  .ib-status-additional-info-input {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  .dw-accounts-card {
    max-width: 500px;
  }
  button {
    padding: 12px 0;
    min-width: 350px;
    background-color: $primary-color;
    color: white;
    border: none;
    border-radius: 999px;
  }
}

.additional-documents-dialog {
  width: 90%;
  max-width: 700px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .upload-documents-container {
    padding-bottom: 40px;
  }
  .submit-additional-documents-btn {
    margin-bottom: 40px;
    width: 100%;
    justify-content: center;
  }
}
.additional-documents-dialog .success-message {
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding-bottom: 48px;
  color: black;
  .message-main {
    font-size: 24px;
    color: $primary-color;
    font-weight: bold;
  }
}

.ib-migration-container {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding-bottom: 40px;
  .main-title {
    font-size: 40px;
  }
  .title-text {
    font-size: 32px;
  }
  .small-text {
    font-size: 12px;
  }
  .onboarding-btn {
    width: 325px;
    height: 56px;
    border-radius: 10px;
    background: #603afa;
    color: white;
    border: none;
    font-size: 22px;
    font-weight: bold;
    align-self: center;
  }
  .checkmark-content {
    display: flex;
    gap: 12px;
  }
  .ib-logo {
    width: 100%;
    max-width: 600px;
    object-fit: contain;
    align-self: center;
  }
  .stats-wrapped-row,
  .awards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px;
    p {
      width: 120px;
    }
  }
  .awards {
    text-align: center;
  }
}

.ib-liquidate-dw-account-card {
  width: 100%;
  background-color: crimson;
  color: white;
  cursor: pointer;
  padding: 10px;
  border-radius: 10px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 12px;
  text-align: center;
  margin-bottom: 20px;
}
.ib-liquidate-account-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  .back-btn {
    cursor: pointer;
    font-size: 12px;
    align-self: start;
    margin-bottom: 10px;
    &:hover {
      text-decoration: underline;
    }
  }
  .withdraw-btn {
    width: 100%;
    padding: 5px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px 0;
    &:hover {
      background-color: #eee;
    }
  }
  .section-title {
    width: 100%;
    .title-text {
      font-size: 24px;
      font-weight: bold;
    }
  }
  .total-portofolio-value-container {
    background-color: #603afa;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100px;
    color: white;
    border-radius: 10px;
    .portofolio-value {
      font-size: 24px;
      font-weight: bold;
    }
  }
  .portofolio-positions {
    display: flex;
    flex-direction: column;
    width: 100%;
    .position-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #eeeeeeb8;
      padding: 5px;
      &:hover {
        background-color: #eee;
        cursor: pointer;
      }
      &.header {
        p {
          font-size: 12px;
        }
        &:hover {
          background-color: transparent;
          cursor: default;
        }
      }
      .pos-share {
        font-size: 12px;
      }
    }
  }
}
.liquidate-position {
  width: 400px;
  display: flex;
  .p-dialog-header {
    padding: 0 !important;
  }
  p {
    margin-bottom: 14px;
  }
  .liquidate-position-title {
    font-size: 24px;
    text-align: center;
    margin-top: 10px;
  }
  .liquidate-position-buttons {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
    button {
      border: none;
      width: 100px;
      padding: 10px 0;
      border-radius: 10px;
      &:hover {
        background-color: #603afa;
        color: white;
      }
    }
  }
  .liquidate-position-finished {
    text-align: center;
    padding: 20px 0;
    p {
       font-size: 18px;
    }
    .liquidate-position-buttons {
      justify-content: center;
    }
  }
  .liquidate-position-loader-container {
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-direction: column;
    p {
      font-size: 24px;
      font-weight: bold;
    }
    .liquidate-position-loader {
      display: inline-block;
      position: relative;
      width: 80px;
      height: 80px;
    }
    .liquidate-position-loader div {
      animation: liquidate-position-loader 2.4s cubic-bezier(0.5, 0, 0.5, 1)
        infinite;
      transform-origin: 40px 40px;
    }
    .liquidate-position-loader div:after {
      content: ' ';
      display: block;
      position: absolute;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background: #603afa;
      margin: -4px 0 0 -4px;
    }
    .liquidate-position-loader div:nth-child(1) {
      animation-delay: -0.036s;
    }
    .liquidate-position-loader div:nth-child(1):after {
      top: 63px;
      left: 63px;
    }
    .liquidate-position-loader div:nth-child(2) {
      animation-delay: -0.072s;
    }
    .liquidate-position-loader div:nth-child(2):after {
      top: 68px;
      left: 56px;
    }
    .liquidate-position-loader div:nth-child(3) {
      animation-delay: -0.108s;
    }
    .liquidate-position-loader div:nth-child(3):after {
      top: 71px;
      left: 48px;
    }
    .liquidate-position-loader div:nth-child(4) {
      animation-delay: -0.144s;
    }
    .liquidate-position-loader div:nth-child(4):after {
      top: 72px;
      left: 40px;
    }
    .liquidate-position-loader div:nth-child(5) {
      animation-delay: -0.18s;
    }
    .liquidate-position-loader div:nth-child(5):after {
      top: 71px;
      left: 32px;
    }
    .liquidate-position-loader div:nth-child(6) {
      animation-delay: -0.216s;
    }
    .liquidate-position-loader div:nth-child(6):after {
      top: 68px;
      left: 24px;
    }
    .liquidate-position-loader div:nth-child(7) {
      animation-delay: -0.252s;
    }
    .liquidate-position-loader div:nth-child(7):after {
      top: 63px;
      left: 17px;
    }
    .liquidate-position-loader div:nth-child(8) {
      animation-delay: -0.288s;
    }
    .liquidate-position-loader div:nth-child(8):after {
      top: 56px;
      left: 12px;
    }
    @keyframes liquidate-position-loader {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}
