@import '../reuse/mixins.scss';
@import '../reuse/variables.scss';

.ua-content {
  margin: 25px;
  .p-tabview .p-tabview-nav {
    background-color: transparent;
    margin: 0 20px;
    .p-tabview .p-tabview-nav {
      background-color: transparent;
      border: none !important;
      li .p-tabview-nav-link {
        background-color: transparent;
        background: transparent;
        border-bottom: 0;
      }
    }

    li .p-tabview-nav-link {
      background: transparent !important;
      border: none;
    }
  }

  .p-tabview-nav-container {
    .p-tabview-nav-content {
      .p-tabview-nav {
        border: none;
        li {
          border-bottom: 1px solid rgba(128, 128, 128, 0.5);
        }
      }
    }
  }

  .p-tabview-panels {
    background-color: transparent;
    background: transparent !important;
    height: 100%;
  }
}
