.left-budgeting {
  // width: 70%;
  // height: 100%;
  // display: flex;
  // flex-direction: column;
  // // justify-content: space-between;
  // overflow: auto;
  // gap: 1rem;
  // padding-right: 1rem;

  width: 100%;
  height: 100%;
  padding-right: 1rem;
  max-width: 900px;

  .netural .p-progressbar-value {
    background: #606060;
  }
  .negative-bar .p-progressbar-value {
    background: red;
  }
  .positive-bar .p-progressbar-value {
    background: #18ba18;
  }

  .p-progressbar {
    border-radius: 10px;
    height: 6px;
    background: #d9d9d9;
  }

  .budget-back-button {
    font-size: 1.2rem;
    cursor: pointer;
  }

  // @media screen and (max-width: 769px) {
  //   width: 100%;
  // }

  .budgeting-insight {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    > section {
      margin-bottom: 1rem;
      padding: 1rem;
      border-radius: 10px;
      background: #fff;
      // margin-right: 1rem;
    }

    > p {
      text-align: center;
      margin-bottom: -1rem;
      font-size: 1.3rem;
    }

    .header-section {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 1rem;
      > p:nth-child(1) {
        font-size: 1.7rem;
      }
      > p:nth-child(2) {
        font-size: 0.875rem;
      }
      > p:last-child {
        color: #3f46f6;
        font-size: 2.25rem;
      }
    }

    .summary-section {
      .syntax-text {
        text-align: right;
      }
      .progress-bar-container {
        margin: 1rem 0rem;
        cursor: pointer;
        > p {
          display: flex;
          justify-content: space-between;
          gap: 1rem;
          padding-bottom: 0.5rem;

          span {
            span {
              color: #0fa268;
            }
          }
        }
      }
    }

    .summary-sub-section {
      > p {
        font-size: 0.8rem;
        margin-bottom: 1rem;
        color: #272748;
      }
      .summary-sub-content {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 1rem;
        // margin-right: 1rem;

        .cash-flow-container,
        .transfer-flow-container {
          padding: 1rem;
          border-radius: 10px;
          background: #fff;
          flex: 1;
          display: flex;
          gap: 1rem;
          // align-items: center;
          align-items: flex-start;

          .pi {
            font-size: 2rem !important;
          }
          i.pi.pi-angle-down {
            color: red;
          }
          i.pi.pi-angle-up {
            color: #0fa268;
          }

          i {
            background: #efefef;
            border-radius: 50%;
            padding: 0.5rem;
          }

          > div {
            p {
              color: #272748;
            }
            span {
              font-size: 1.125rem;
            }
          }
        }

        .transfer-flow-container {
          cursor: pointer;

          .notification {
            background: RED;
            border-radius: 50%;
            width: 35px;
            height: 35px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
          }
        }
      }
    }

    .subscription-section {
      .subscription-header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;

        > span:first-child {
          font-size: 1.875rem;
        }

        > span:last-child {
          color: gray;
        }
      }

      .subscription-info {
        .filter-buttons {
          padding: 1rem 0rem;
          display: flex;
          gap: 1rem;

          button.p-button.p-component.p-button-text {
            color: #3f46f6;
          }
        }

        .subscription-info-container {
          display: flex;
          flex-direction: column;
          gap: 2rem;
          padding-bottom: 1rem;

          .single-subscription-info {
            border-radius: 19px;
            background: #f6f6f6;

            box-shadow: 3px 1px 5px 0px rgba(86, 86, 86, 0.2),
              0px 0px 2px 0px rgba(255, 255, 255, 0.21) inset,
              -3px -2px 4px 0px rgba(255, 255, 255, 0.92);
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 1rem;

            p {
              display: flex;
              flex-direction: column;
              gap: 0.2rem;
              span {
                font-size: 0.875rem;
                color: gray;
              }
            }

            .first-part {
              display: flex;
              align-items: center;
              gap: 0.5rem;

              img {
                width: 60px;
                height: 60px;
                object-fit: none;
              }
            }
          }
        }
      }
    }

    .external-section {
      .add-account-container {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        width: max-content;
        background: #e2e2e2;
        color: black;
        padding: 1rem;
        margin-left: auto;
        border-radius: 19px;
      }
      .pi {
        &.pi-plus-circle {
          font-size: 1rem !important;
        }
      }

      .p-button {
        background: #3f46f6;
        color: white;
        display: flex;
        margin: 1rem 0rem;
        margin-left: auto;
        margin-right: 1rem;
        padding: 1rem 2rem;
        font-size: 1.1rem;
      }
    }
  }

  .budgeting-home {
    // width: 100%;
    // height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    // padding-right: 1rem;
    // max-width: 900px;

    // @media screen and (max-width: 769px) {
    //   width: 100%;
    // }
    > section {
      margin-bottom: 1rem;
      padding: 1rem;
      border-radius: 10px;
      background: #fff;
      // margin-right: 1rem;
    }

    > span {
      color: #272748;
      font-size: 0.8rem;
    }
    .relink-notification-container {
      padding: 16px;
      background-color: #ff6b81;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      font-size: 20px;
    }

    .time-period {
      margin-bottom: -0.8rem;
      font-size: 0.9rem;
      padding-left: 0.2rem;
    }

    .financial-yoga-container {
      // border-top: 1px solid #d9d9d9;

      gap: 1rem;
      padding-top: 1rem;

      display: flex;
      flex-direction: column;

      > div:first-child {
        color: #333;
        font-size: 1.4375rem;
        font-weight: 400;
        cursor: pointer;

        p {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 1.5rem;

          span {
            font-size: 2.5rem;
          }
        }
      }

      > div:last-child {
        color: #7b61ff;
        font-size: 0.875rem;
        font-weight: 700;
        cursor: pointer;
        border-top: 1px solid rgba(0, 0, 0, 0.33);
        padding-top: 1rem;

        span {
          display: flex;
          align-items: center;
          gap: 0.5rem;
        }
      }
    }
    .relink-btn {
      margin-left: auto;
      cursor: pointer;
      color: #757575;
    }
    .section-title {
      color: #000;
      font-size: 1.3125rem;
      // font-style: italic;
      font-weight: 400;
      display: flex;
      justify-content: space-between;

      > span {
        display: flex;
        align-items: center;
        gap: 0.2rem;
        // margin-right: 1rem;
        font-size: 0.875rem;
        cursor: pointer;

        img {
          margin-bottom: 0.2rem;
        }
      }
    }

    // > p:first-child {
    //   font-size: 1.625rem;
    // }

    .syntax-text {
      font-size: 0.8rem;
      text-align: right;

      span {
        font-weight: bold;
      }
    }

    .dynamic-spend-section {
      .single-dynamic-spending-container {
        padding: 1.2rem 0rem;
        border-bottom: 1px solid #d9d9d9;
        cursor: pointer;

        .spending-container {
          display: flex;
          justify-content: space-between;
          padding-bottom: 0.5rem;
          align-items: center;
          gap: 0.5rem;
          font-size: 1.1rem;

          @media screen and (max-width: 700px) {
            font-size: 0.875rem;
          }

          .spending-img {
            display: flex;
            gap: 0.5rem;
            align-items: center;

            img,
            svg {
              margin-bottom: 0.2rem;
              transform: scale(1.2);
            }
          }

          .spending-text {
            span {
              color: #0fa268;
            }
          }
        }
      }

      > div:last-child {
        border-bottom: none;
      }
    }

    .income-reserves-section,
    .wealth-fund-section,
    .charitable-section {
      img {
        margin-bottom: 0.2rem;
      }
    }

    .income-reserves-section {
      cursor: pointer;
      > span:nth-child(1) {
        display: flex;
        gap: 0.5rem;
        color: #0fa268;
      }

      > span:last-child {
        color: #272748;
        font-size: 0.8rem;
        font-style: italic;
        font-weight: 400;
      }

      .money-text {
        color: #000;
        font-size: 1.3125rem;
        font-weight: 400;
        padding-left: 1.8rem;
        // padding-top: 0.5rem;
      }

      > div {
        padding-bottom: 0.5rem;
        > span {
          display: flex;
          justify-content: end;
          padding-bottom: 0.5rem;
          color: #0fa268;
        }
      }
    }

    .wealth-fund-section,
    .charitable-section {
      cursor: pointer;
      span {
        display: flex;
        gap: 0.5rem;
        color: #7b61ff;
      }

      p {
        color: #000;
        font-size: 1.3125rem;
        font-weight: 400;

        padding-left: 1.5rem;
      }
    }
  }

  .budget-income-reserve {
    max-width: 800px;

    .budget-income-reserve-container {
      .subtitle {
        font-size: 1.3125rem;
      }

      .highlight-container,
      .previous-highlight-container {
        cursor: pointer;
        padding: 1rem 1.5rem;
        border-radius: 10px;
        background: #fff;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        > p {
          color: gray;
          display: flex;
          justify-content: space-between;
          gap: 1rem;
        }

        > span {
          font-size: 1.625rem;
        }
      }

      .previous-highlight-container {
        margin-bottom: 2rem;
        > p {
          padding-bottom: 1rem;
          border-bottom: 0.5px solid gray;
        }
        > p:last-child {
          border-bottom: none;
          padding-top: 1rem;
          padding-bottom: 0rem;
        }
      }
    }
  }

  .budget-dynamic-spend {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    .dynamic-header-title {
      text-align: center;
      font-size: 1.8rem;
    }

    .dynamic-summary-container {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      padding: 1rem;
      border-radius: 10px;
      background: #fff;

      .available-spend {
        display: flex;
        flex-direction: row;
        gap: 2rem;
        justify-content: space-between;
        flex-wrap: wrap;

        @media screen and (max-width: 400px) {
          flex-direction: column-reverse;
          gap: 0.5rem;
        }

        > p:first-child {
          display: flex;
          flex-direction: column;
          color: gray;
          font-size: 1.125rem;
          span {
            font-size: 1.625rem;
          }
        }

        > p:last-child {
          color: #4d4b4b;
          font-size: 1.125rem;

          @media screen and (max-width: 400px) {
            font-size: 1rem;
          }
        }
      }

      .warning-msg {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        color: gray;

        .pi {
          color: red;
          font-size: 1rem !important;
        }
      }
      .other-financial-info {
        display: flex;
        flex-direction: row;
        gap: 1.5rem;
        flex-wrap: wrap;
        justify-content: space-between;

        p {
          display: flex;
          flex-direction: column;
          color: gray;

          span {
            color: black;
          }
        }
      }
    }
  }

  .budget-transfer,
  .budget-income,
  .budget-expense {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
}

.line-graph-container {
  padding: 1rem;
  border-radius: 10px;
  background: #fff;
  .legend-container {
    display: flex;
    justify-content: center;
    gap: 4rem;
    margin-bottom: 0.5rem;
    .month-legend,
    .avg-legend {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      > p:first-child {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        font-size: 0.875rem;
      }
    }
    .legend-color-symbol {
      border-radius: 50%;
      display: inline-flex;
      width: 15px;
      height: 15px;
    }
    .month-legend {
      p {
        color: #007bff;
      }
      .legend-color-symbol {
        background-color: #007bff;
      }
    }

    .avg-legend {
      p {
        color: #ff6b81;
      }
      .legend-color-symbol {
        background-color: #ff6b81;
      }
    }
  }
}

.transaction-container {
  padding: 1rem;
  border-radius: 10px;
  background: #fff;

  .single-transaction-container {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    border-bottom: 1px solid #d9d9d9;
    padding: 1rem 0rem;
    align-items: center;

    .transaction-part {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      flex: 1;
    }
    .transaction-name {
      font-size: 0.875rem;
    }

    .top-part {
      .first-row,
      .second-row {
        display: flex;
        justify-content: space-between;

        span {
          color: gray;
          font-size: 0.875rem;
        }
      }

      .first-row {
        p {
          color: #7b61ff;
        }
      }

      .second-row {
        p {
          font-weight: bold;
          font-size: 0.875rem;
        }
      }
    }
  }

  .filter-transaction-buttons {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    border-bottom: 1px solid gray;
    padding-bottom: 2rem;

    .user-filter {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      .search-bar {
        span {
          width: 100%;
        }
      }

      .filters {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        flex-wrap: wrap;
        @media screen and (max-width: 550px) {
          flex-direction: column;
        }

        .filter-dropdown {
          width: 225px;
          border-radius: 30px;
          @media screen and (max-width: 550px) {
            width: 100%;
          }
        }
      }
    }

    .change-categories-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .p-float-label {
        @media screen and (max-width: 550px) {
          flex: 1;
        }
      }

      .p-dropdown {
        width: 14rem;

        @media screen and (max-width: 550px) {
          width: 100%;
        }
      }
    }
  }

  > div:first-child {
    padding-top: 0rem;
  }

  .p-paginator {
    justify-content: center;
  }
}

.budget-wealth-dialog,
.budget-charity-dialog,
.budget-income-reserve {
  .budget-wealth,
  .budget-charity,
  .budget-income-reserve-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    .wealth-text,
    .charity-text {
      max-width: 500px;
    }
  }

  .p-dialog-header,
  .p-dialog-content {
    background-color: rgb(246, 246, 246) !important;
  }
}

.budget-highlight-dialog {
  width: 400px;

  .p-dialog-header {
    padding: 0.5rem !important;
  }

  .budget-highlight {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    .highlight-header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      gap: 0.5rem;

      > p:last-child {
        color: #0fa268;

        font-size: 1.8125rem;
      }
    }
    .highlight-content {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      overflow: auto;
      // max-height: 500px;
      padding-right: 1rem;
      margin-bottom: 2rem;
      p {
        display: flex;
        justify-content: space-between;
        gap: 1rem;
        flex-wrap: wrap;
      }
    }
  }
}

.subdetail-summary-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  padding: 2rem;
  border-radius: 10px;
  background: #fff;
  margin-bottom: 2rem;

  .first-row {
    color: gray;
    span {
      font-size: 26px;
      color: #0fa268;
    }

    .percentage-text {
      text-align: right;
      padding-bottom: 0.5rem;
    }
  }

  .second-row {
    > div {
      display: flex;
      gap: 1rem;
      flex-wrap: wrap;

      @media screen and (max-width: 450px) {
        flex-direction: column;
      }

      .p-dropdown {
        // width: 130px;
        flex-basis: 60%;
      }

      button {
        background-color: #3f46f6;
        padding: 0rem 1.5rem;
        flex: 1;

        @media screen and (max-width: 450px) {
          padding: 1rem 1.5rem;
        }
      }
    }

    > p {
      padding-bottom: 0.5rem;
    }
  }
  .third-row {
    display: flex;

    gap: 8rem;

    @media screen and (max-width: 450px) {
      gap: 2rem;
    }
  }

  .second-row,
  .third-row {
    color: gray;
    font-size: 14px;
  }

  .third-row {
    p {
      display: flex;
      flex-direction: column;
      gap: 0.2rem;

      span {
        color: black;
      }
    }
  }
}

.wealth-text,
.charity-text,
.income-reserve-text,
.transfer-text {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  text-align: center;

  > div {
    width: 100px;
    height: 100px;
    padding: 1rem;
    border-radius: 50%;
    background: white;

    img {
      width: 100%;
      height: 100%;
    }
  }

  p {
    font-weight: bold;
    font-size: 1.5625rem;
  }
}

.dynamic-title {
  font-size: 1.5rem;
  margin-bottom: -1rem;
}

.p-dropdown-panel {
  max-width: 13rem;
}

// Financial Yoga UI

.financial-yoga-component-container {
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 40px;
  padding-bottom: 40px;
  p.gray {
    color: #757575;
  }
  .title {
    font-size: 21px;
    font-weight: 700;
    text-align: start;
  }
  .instructions {
    font-size: 19px;
  }

  .rounded-button {
    height: 40px;
    font-size: 14px;
    color: white;
    background-color: #007bff;
    border-radius: 40px;
    border: 2px solid #007bff;
    width: 100%;
    &.inverse {
      color: #007bff;
      background-color: white;
    }
    &:disabled,
    &[disabled] {
      cursor: not-allowed;
      color: #d9d9d9;
      border: 2px solid #d9d9d9;
      background-color: white;
    }
  }

  .content-container-box {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    .progress-label-right {
      align-self: end;
      font-size: 12px;
    }
    .progress-container {
      display: flex;
      flex-direction: column;
      gap: 20px;
      .progress-bar-numbers {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .title {
          font-size: 16px;
        }
        .numbers {
          font-size: 22px;
        }
      }
    }
    .transaction-card {
      padding-right: 20px;
      @media screen and (max-width: 769px) {
        padding-right: 0px;
      }
      .transaction-header-row {
        padding-left: 42px;
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        margin-bottom: 15px;
      }
      .transaction-info {
        display: flex;
        justify-content: space-between;
        gap: 10px;
        min-width: 0;
        .icon-container {
          height: 32px;
          min-height: 32px;
          width: 32px;
          min-width: 32px;
          background-color: #f6f6f6;
          border-radius: 32px;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 60%;
            height: 60%;
            object-fit: contain;
          }
        }
        .info {
          font-size: 12px;
          flex-grow: 1;
          min-width: 0;
          .transaction-title {
            font-size: 14px;
            font-weight: 700;
            margin-bottom: 7px;
            .change-category-btn {
              margin-right: 7px;
              cursor: pointer;
            }
            .edit-highlight {
              color: #3f46f6;
            }
          }
          .transaction-description {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
      .read-btns {
        display: flex;
        gap: 10px;
        justify-content: end;
        margin-top: 10px;
        .read-btn {
          cursor: pointer;
          &.correct {
            color: #0fa268;
          }
          &.incorrect {
            color: #ff2445;
          }
        }
      }
      .steal-container {
        padding-left: 42px;
        margin-top: 20px;
        .steal-row {
          display: flex;
          justify-content: space-between;
          p:last-child {
            color: #ff2445;
          }
        }
      }
    }
  }
  .transactions {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .transaction-review-btns {
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
    p {
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 6px;
    }
  }
  .linked-accounts-title {
    text-align: center;
    font-size: 30px;
    font-weight: 700;
  }
  .linked-accounts-subtitle {
    font-size: 14px;
    text-align: center;
  }
  .linked-accounts-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    .account-type-container {
      width: 100%;
      padding: 20px 40px;
      text-align: center;
      max-width: 500px;
      .account-type {
        font-size: 20px;
        font-weight: 700;
      }
      .account-row {
        display: flex;
        justify-content: space-between;
        p:first-child {
          font-weight: 700;
        }
        p:last-child {
          font-weight: 400;
        }
      }
    }
  }
}
.change-category-modal-content {
  width: 90vw;
  max-width: 400px;
  height: 100vh;
  max-height: 400px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 20px;
  .middle {
    align-self: center;
  }
  .category-title {
    font-size: 16px;
  }
  .category-value {
    font-size: 18px;
    font-weight: 700;
    color: #3f46f6;
  }
  .rounded-button {
    height: 40px;
    font-size: 14px;
    color: white;
    background-color: #007bff;
    border-radius: 40px;
    border: 2px solid #007bff;
    width: 100%;
    &.inverse {
      color: #007bff;
      background-color: white;
    }
    &:disabled,
    &[disabled] {
      cursor: not-allowed;
      color: #d9d9d9;
      border: 2px solid #d9d9d9;
      background-color: white;
    }
    &.end {
      margin-top: auto;
    }
  }
}
.relink-accounts-container {
  .title {
    font-size: 28px;
    font-weight: 700;
  }
  .relink-accounts-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    .account-container {
      width: 100%;
      padding: 40px;
      max-width: 600px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      border-radius: 10px;
      border: 1px solid #4d4b4b;
      .account-row {
        display: flex;
        justify-content: space-between;
        font-size: 20px;
        .account-name {
          font-weight: 700;
        }
      }
      .relink-button {
        background-color: #007bff;
        border-radius: 20px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        cursor: pointer;
        width: 100%;
      }
    }
  }
}
