@import '../../reuse/mixins.scss';
@import '../../reuse/variables.scss';

.analysis-container {
  .analysis-sub-header {
    font-family: 'Akkurat-Bold';
    color: #3627e4;
    text-align: center;
    font-size: 25px;
  }
  .analysis-header {
    display: flex;
    justify-content: center;
    .header-label {
      font-size: 20px;
      margin-right: 5px;
    }
    .header-value {
      font-size: 20px;
      font-family: Akkurat-Bold;
      margin-left: 5px;
    }
    .positive {
      color: $primary-color;
    }
    .negative {
      color: $negative-color;
    }
  }
  .analysis-row {
    display: flex;
    justify-content: space-between;
    .analysis-label {
      font-size: 16px;
      font-family: Akkurat-Light;
    }
    .analysis-value {
      font-family: Akkurat-Bold;
      font-size: 16px;
    }
    .positive {
      color: $primary-color;
    }
    .negative {
      color: $negative-color;
    }
    .neutral {
      color: grey;
    }
    .bold {
      font-family: Akkurat-Bold;
    }
    .shift {
      margin-left: 15px;
    }
  }

  .analysis-technical-levels {
    margin: 25px 0;

    .header-label {
      font-size: 17px;
      text-align: center;
    }

    .techical-levels-tab-container {
      max-width: 300px;
      margin: auto;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      height: 100%;
    }

    .technical-levels-table {
      width: 100%;
      padding: 5px;
      border: 1px solid rgba(128, 128, 128, 0.5);
      border-radius: 15px;
      margin: 25px 0;
      @include flex(row, space-between, flex-start);

      .technical-levels-left {
        flex: 1;
        text-align: center;
        padding: 5px 10px;
      }
      .vertical-line {
        width: 1px;
        color: black;
        height: 100%;
      }
      .technical-levels-right {
        flex: 1;
        padding: 5px 10px;
        text-align: center;
      }

      .technical-levels-table-header {
        font-size: 15px;
        font-family: Akkurat-Bold;
        margin-bottom: 25px;
      }

      .technical-levels-table-row {
        border-bottom: 1px solid rgba(128, 128, 128, 0.5);
        @include flex(row, space-between, center);
        margin-bottom: 10px;
        padding-bottom: 10px;
        .technical-levels-row-value {
          margin: 0;
          font-size: 15px;
        }
      }
    }
  }
}
