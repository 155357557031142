@import '../../../reuse/mixins.scss';
@import '../../../reuse/variables.scss';

.goals-activation-container {
  width: 80%;
  height: 80vh;
  @media screen and (max-width: 769px) {
    width: 100%;
  }
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
}
