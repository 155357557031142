@import "../reuse/mixins.scss";
@import "../reuse/variables.scss";

.change-password-modal-container {
  .signup__button {
    cursor: pointer;
    background-color: #3181ff;
    border-radius: 50px;
    width: 100%;
    height: 50px;
    margin-top: 15px;
    color: white;
    @include flex(column, center, center);
    &:active {
      background-color: #1b4487;
    }
  }

}
.success-message {
  color: $primary-color;
  text-align: center;
}

.login-button{
  text-align: center;
  color: $black-color;
  cursor: pointer;
}