@import '../../reuse/mixins.scss';
@import '../../reuse/variables.scss';

// .stats-container{
//     .stats-row{
//         display: flex;
//         justify-content: space-between;
//         .stats-label{
//             font-size: 16px;
//             font-family: Akkurat-Light;
//         }
//         .stats-value{
//             font-size: 16px;
//             font-family: Akkurat-Light;
//         }
//     }
// }

.stats-container {
  width: '100%';

  .key-stats {
    margin-bottom: 15px;
  }

  .stats-sub-header {
    font-family: 'Akkurat-Bold';
    color: $primary-color;
    font-size: 25px;
    @media only screen and (max-width: 769px) {
      text-align: center;
    }
  }

  .info-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    @media only screen and (max-width: 769px) {
      justify-content: center;
    }
    .stats-info {
      display: flex;
      justify-content: space-between;
      width: 30%;
      min-width: 200px;
      margin-right: 1.5%;
      border-bottom: 1px solid rgba(128, 128, 128, 0.5);
      margin-bottom: 20px;
      @media only screen and (max-width: 769px) {
        width: 100%;
      }
      .stats-label {
        font-size: 15;
        color: grey;
        margin-bottom: 5px;
      }

      .stats-value {
        font-size: 15;
        font-family: 'Akkurat-Bold';
        margin-bottom: 5px;
      }
    }
  }
}
