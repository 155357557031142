@import '../reuse/mixins.scss';
@import '../reuse/variables.scss';

.parnership-bg {
  // background: #f2f2f2;
}

.parnership-page {
  margin: auto;
  margin-top: 100px !important;
  padding: 1rem 2rem 2rem 2rem;
  max-width: 1440px;
  // border-radius: 1647px;
  // background: #fff;

  p {
    @media screen and (max-width: 1000px) {
      letter-spacing: normal !important;

      line-height: normal !important;
    }
  }
  button {
    border-radius: 35px;
    background: #3627e4;
    color: #fff;
    text-align: center;
    font-size: 1.625rem;
    font-weight: 700;
    border: none;
    padding: 0.5em 2rem;
    width: 60%;
    margin-top: 4rem;
  }

  .header-button {
    @media screen and (max-width: 1000px) {
      margin-top: 22rem;
      width: 100%;
    }
  }

  .header-section {
    border-radius: 30px;
    background: linear-gradient(10deg, #fff 0%, #3f46f6 43.75%, #61c6ff 100%);
    display: flex;
    padding: 2rem;

    @media screen and (max-width: 1000px) {
      flex-direction: column;
      position: relative;
      min-height: 500px;
    }

    @media screen and (max-width: 700px) {
      min-height: 580px;
    }

    @media screen and (max-width: 515px) {
      min-height: 650px;
    }
    @media screen and (max-width: 475px) {
      min-height: 750px;
    }
    @media screen and (max-width: 400px) {
      min-height: 850px;
    }

    .info-container {
      flex-basis: 50%;
      display: flex;
      flex-direction: column;
      gap: 2rem;
      margin-top: 3rem;

      > p:nth-child(1) {
        color: #fff;
        font-size: 3.1875rem;
        font-weight: 900;
        line-height: 133%; /* 73.15px */
        letter-spacing: -1.65px;

        @media screen and (max-width: 1000px) {
          font-size: 2.5rem;
        }
      }

      > p:nth-child(2) {
        color: #fff;
        font-size: 1.3125rem;
        font-weight: 400;
        line-height: 133%; /* 27.93px */
        letter-spacing: -0.63px;

        @media screen and (max-width: 1000px) {
          font-size: 1.3125rem;
        }
      }

      @media screen and (max-width: 1000px) {
        margin-top: 0rem;
      }
    }

    .image-container {
      flex: 1 1;
      text-align: center;

      @media screen and (max-width: 1000px) {
        position: absolute;
        bottom: -20rem;
        left: 0;
        right: 0;
      }
    }
  }

  .options-section {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    padding: 7rem 1rem;

    @media screen and (max-width: 1000px) {
      gap: 3rem;
      padding: 5rem 0rem;
      flex-direction: column;
    }

    .option-container {
      .option-title {
        color: #6066f8;
        font-size: 2.25rem;
        font-weight: 700;
        text-align: center;
        line-height: 133%; /* 42.56px */
        letter-spacing: -0.96px;
        padding-bottom: 0.5rem;

        @media screen and (max-width: 1000px) {
          font-size: 1.875rem;
        }
      }

      .option-info {
        color: #7d7d7d;
        font-size: 1rem;
        font-weight: 400;
        text-align: center;
        max-width: 380px;
        line-height: 133%;
        letter-spacing: -0.48px;
        @media screen and (max-width: 1000px) {
          max-width: none;
        }
      }
    }
  }

  hr {
    width: 90%;
    border-top: 2px solid rgba(0, 0, 0, 0.33);
    margin: auto;
    margin-bottom: 3rem;
  }

  .book-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 5rem;
    // border-top: 2px solid rgba(0, 0, 0, 0.33);
    // padding-top: 3rem;

    > p:nth-child(1) {
      background: linear-gradient(90deg, #496bf9 0%, #5db5fe 100%);
      background-clip: text;
      color: transparent;
      font-size: 2.875rem;
      font-weight: 900;
      text-align: center;
      line-height: 91px; /* 197.826% */
      letter-spacing: -1.38px;
    }

    > p:nth-child(2) {
      color: #333;
      font-size: 1.0625rem;
      font-weight: 400;
      text-align: center;

      @media screen and (max-width: 1000px) {
        margin-top: 3rem;
      }
    }

    button {
      width: 35%;

      @media screen and (max-width: 1000px) {
        width: 100%;
      }
    }
  }
}
