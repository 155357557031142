// default font size
$default-font-size: 1.25rem;

$primary-color: #3627e4;
// $negative-color: #67333F;
$negative-color: #3d5062;
$positive-color: #3f46f6;
$black-color: #272748;
$robo-color: #7147e9;
$goals-color: #3d32e7;
$group-color: #5c21d8;

$grey-font: rgba(128, 128, 128, 0.8);
