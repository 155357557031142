@import '../../reuse/mixins.scss';
@import '../../reuse/variables.scss';

.forecast-chart-wrapper {
  .custom-tooltip {
    padding: 15px;
  }
  .custom-tooltip-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .value-label {
    margin-right: 15px;
    margin-bottom: 0;
  }

  .value-predicted {
    text-align: right;
    margin: 0;
  }

  .value-actual {
    margin: 0;
    text-align: right;
  }

  .custom-tooltip-date {
    margin: 0;
    margin-top: 15px;
    text-align: center;
    font-size: 12px;
  }

  .purple {
    color: #3627e4;
  }

  .green {
    color: #82ca9d;
  }
}
