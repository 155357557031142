@import '../reuse/mixins.scss';
@import '../reuse/variables.scss';

// .language-toggle-container {
// position: absolute;
// bottom: 0;
//     padding-bottom: 25px;
//   .language-header {
//     margin: 0;
//     text-align: left;
//     font-size: 15px;
//     margin-right: 15px;
//     color: rgba(128, 128, 128, 0.5);
//     width: 100%;
//     height: 100%;
//   }
//   .p-dropdown {
//     border-color: #f6f6f6;
//     box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07),
//       -3px -2px 4px rgba(255, 255, 255, 0.92), 3px 1px 5px rgba(86, 86, 86, 0.2),
//       inset 0px 0px 2px rgba(255, 255, 255, 0.21);
//     border-radius: 10px;
//     padding: 5px;
//     width: 100%;
//     height: 100%;
//     background: #f6f6f6;
//   }
//   .p-dropdown:hover{
//       border-color: $primary-color !important;
//   }
//   .p-dropdown .p-inputtext {
//     font-size: 12px;
//     padding: 5px;
//     text-align: center;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     color: rgba(128, 128, 128, 0.5);
//   }
// }

.p-dropdown-panel.p-component.p-connected-overlay-enter-done {
  z-index: 3001 !important;
}

.language-toggle-container {
  position: absolute;
  margin-bottom: 15px;
  bottom: 0;
  .language-header {
    margin: 0;
    text-align: center;
    font-size: 15px;
    margin-right: 15px;
    color: rgba(128, 128, 128, 0.5);
    width: 100%;
    height: 100%;
  }
  .p-dropdown {
    border-color: #cccccc;
    // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.07),
    //   -3px -2px 4px rgba(255, 255, 255, 0.92),
    //   3px 1px 5px rgba(86, 86, 86, 0.2),
    //   inset 0px 0px 2px rgba(255, 255, 255, 0.21);
    box-shadow: none;
    border-radius: 25px;
    padding: 5px;
    width: 90px;
    height: 30px;
    // background: #f6f6f6;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999;
  }
  .p-dropdown:hover {
    border-color: $primary-color !important;
  }
  .p-dropdown .p-inputtext {
    font-size: 12px;
    padding-left: 15px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(128, 128, 128, 1);
  }
  .pi {
    font-size: 12px !important;
  }
  .p-dropdown-trigger {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 1;
  }
}
