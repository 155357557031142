@import '../../../reuse/mixins.scss';
@import '../../../reuse/variables.scss';

.choose-goal-container {
  text-align: center;
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  @media only screen and (max-width: 769px) {
    height: 100vh;
  }
  .short-term {
    box-shadow: 0px 5px 15px 0px #c4c4c4;
    padding: 15px;
    border-radius: 15px;
    cursor: pointer;
    margin: 10px 0;
    background: rgba(0, 0, 0, 0.1);
    .header {
      font-weight: bold;
      font-size: 20px;
    }
    .info {
      font-size: 15px;
    }
  }
  .medium-term {
    padding: 15px;
    box-shadow: 0px 5px 15px 0px #c4c4c4;
    border-radius: 15px;
    margin: 10px 0;
    cursor: pointer;
    background: rgba(138, 43, 226, 0.1);
    .header {
      font-weight: bold;
      font-size: 20px;
    }
    .info {
      font-size: 15px;
    }
  }
  .long-term {
    padding: 15px;
    border-radius: 15px;
    box-shadow: 0px 5px 15px 0px #c4c4c4;
    margin: 10px 0;
    cursor: pointer;
    background: rgba(3, 201, 169, 0.1);
    .header {
      font-weight: bold;
      font-size: 20px;
    }
    .info {
      font-size: 15px;
    }
  }
}
