@import "../reuse/mixins.scss";
@import "../reuse/variables.scss";

.title {
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  margin-top: 0;

  span {
    color: $primary-color;
    font-weight: 500;
  }
}

.success-message-container {
  display: flex;
  justify-content: center;
  box-shadow: 0px 2px 2px 0px #c4c4c4;
  border-radius: 15px;
  padding: 15px;
  margin: 10px 0;
  align-items: center;
  .succes-messages {
    width: 90%;
    .success-message {
      margin: 0;
      font-size: 15px;
      color: $primary-color;
    }
  }

  .success-message-delete-btn {
    width: 10%;
    color: black;
    background-color: transparent;
  }
  .success-message-delete-btn:hover {
    background-color: transparent !important;
    color: red !important;
  }
}

.error-message-container {
  display: flex;
  justify-content: center;
  box-shadow: 0px 2px 2px 0px #c4c4c4;
  border-radius: 15px;
  padding: 15px;
  margin: 10px 0;
  .error-message {
    width: 90%;
    margin: 0;
    font-size: 15px;
    color: red;
  }
  .error-message-delete-btn {
    width: 10%;
    color: black;
    background-color: transparent;
  }
  .error-message-delete-btn:hover {
    background-color: transparent !important;
    color: red !important;
  }
}
