@import '../../../reuse/mixins.scss';
@import '../../../reuse/variables.scss';

.inactive-basic-info-container {
  text-align: center;
  padding: 50px 0;
  .inactive-name {
    font-family: Akkurat-Bold;
    font-size: 16px;
    margin: 0;
  }
  .inactive-value {
    font-size: 43px;
    font-family: Akkurat-Light;
    margin: 0;
    color: $group-color;
  }
  .GOALS {
    color: $goals-color !important;
  }
  .ROBO {
    color: $robo-color !important;
  }

  .details-container {
    border-bottom: 0.25px solid rgba(0, 0, 0, 0.1);
    border-top: 0.25px solid rgba(0, 0, 0, 0.1);
    padding: 15px;
    margin-top: 15px;
    margin-bottom: 15px;
    .inner-container {
      display: block;
      width: 60%;

      margin: auto;
      @media screen and (max-height: 1000px) {
        width: 100%;
      }
      @media screen and (max-height: 1000px) {
        width: 100%;
      }
      .inner-row {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        .inner-label {
          margin: 0;
        }
        .inner-value {
          margin: 0;
        }
      }
    }
  }
}
