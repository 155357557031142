@import '../reuse/mixins.scss';
@import '../reuse/variables.scss';

.top-stocks-cryptos {
  width: 100%;
  @include flex(column, center, center);
  @media screen and (max-width: 1000px) {
    width: 95%;
    margin: auto;
  }
  text-align: center;
  .wrapper-container {
    @include flex(column, center, center);
    width: 100%;
    max-width: 1920px;
    padding: 0 200px;
    @media screen and (max-width: 1000px) {
      padding: 0;
    }
  }
  .row-main-container {
    width: 100%;
  }
  .data-row {
    width: 100%;
    @include flex(row, space-between, center);
    margin-bottom: 25px;
    padding: 5px 0;
    background-color: #f9fafb;
    &:first-child {
      font-weight: bold;
    }
    &.first {
      background-color: white;
    }
    .data-column {
      @include flex(column, center, center);
      flex: 1;
      &:first-child {
        font-weight: bold;
      }
      .highlight {
        padding: 5px 20px;
        border-radius: 25px;
        &.green {
          background-color: #37a226;
          color: white;
        }
        &.purple {
          background-color: #7b61ff;
          color: white;
          cursor: pointer;
        }
      }
    }
  }
}

.analytics-table {
  width: 100%;
  @include flex(column, center, center);
  .table-head {
    width: calc(100% - 100px);
    @include flex(row, center, center);
    height: 80px;
    text-align: center;
    max-width: 960px;
    font-size: 20px;
    @media screen and (max-width: 1000px) {
      width: 100%;
      height: 60px;
      font-size: 12px;
    }
    .element {
      flex: 1;
      p {
        margin: 0;
        @media screen and (max-width: 1000px) {
          width: 90%;
        }
      }
      @include flex(column, center, center);
    }
    .element-2 {
      flex: 2;
      p {
        margin: 0;
        @media screen and (max-width: 1000px) {
          width: 90%;
        }
      }
      @include flex(column, center, center);
    }
  }
  .analysis-title {
    max-width: 1060px;
    width: 100%;
    height: 80px;
    font-size: 25px;
    @include flex(row, space-between, center);
    .element {
      flex: 1;
      &:first-child {
        @include flex(column, center, flex-start);
        p {
          cursor: pointer;
          margin: 0;
          font-size: 20px;
        }
        @media screen and (max-width: 1000px) {
          @include flex(column, center, center);
          p {
            font-size: 15px;
          }
        }
      }
    }
  }
  .table-body {
    background-color: rgba(196, 196, 196, 0.07);
    border-radius: 15px;
    width: 100%;
    max-width: 1060px;
    height: 500px;
    padding: 25px 50px;
    overflow: hidden;
    box-shadow: -3px -2px 4px rgba(255, 255, 255, 0.92),
      3px 1px 5px rgba(86, 86, 86, 0.2),
      inset 0px 0px 2px rgba(255, 255, 255, 0.21);
    @include flex(column, center, center);
    @media screen and (max-width: 1000px) {
      padding: 0;
      height: auto;
      border-radius: 0;
    }
  }
  .table-row {
    width: 100%;
    @include flex(row, space-between, center);
    height: 80px;
    border-bottom: 1px solid lightgrey;
    text-align: center;
    cursor: pointer;
    &:hover {
      background-color: #eee;
    }
    &:not(:last-child) {
      margin-bottom: 10px;
    }
    @media screen and (max-width: 1000px) {
      &:last-child {
        border-bottom: none;
      }
    }
    .element {
      width: 17.5%;
      @include flex(column, center, center);
      height: 100%;
      font-size: 25px;
      @media screen and (max-width: 1000px) {
        font-size: 14px;
      }
      &:first-child {
        @include flex(column, center, flex-start);
        @media screen and (max-width: 1000px) {
          @include flex(column, center, center);
          font-size: 18px;
        }
      }
      &:last-child {
        cursor: pointer;
        font-size: 18px;
        font-weight: bold;
        color: $primary-color;
      }
      &.highlight {
        p {
          background-color: rgba(63, 70, 246, 0.07);
          height: 100%;
          width: 100%;
          border-radius: 5px;
          margin: 0;
          font-family: 'Akkurat-Bold';
          color: $primary-color;
          @include flex(column, center, center);
          @media screen and (max-width: 1000px) {
            width: 100%;
            height: 75%;
          }
        }
      }
    }
  }
}

.robust-data-analysis {
  padding: 0 20px;
  width: 100%;
  @include flex(row, space-between, flex-start);
  max-height: 500px;
  @media screen and (max-width: 1000px) {
    @include flex(column, center, center);
    max-height: none;
  }
  .data-container {
    height: 100%;
    flex: 1;
    .space-top {
      margin-top: 15px !important;
    }
    &:first-child {
      margin-right: 5%;
    }
    @include flex(column, flex-start, center);
    p {
      margin: 0;
    }
    .analysis-row {
      margin: 0;
      width: 100%;
      @include flex(row, space-between, center);
      border-bottom: 1px lightgray solid;
      p {
        margin: 0;
        padding: 0;
        flex: 1;
        &:first-child {
          text-align: left;
        }
        &:last-child {
          text-align: right;
        }
        &:not(:first-child):not(:last-child) {
          text-align: center;
        }
      }
      .inner-row {
        flex: 1;
        @include flex(column, flex-start, center);
        &:not(:last-child) {
          margin-right: 10%;
        }
      }
      .highlight {
        padding: 10px 20px;
        background-color: rgba(53, 37, 228, 0.07);
        border-radius: 25px;
        margin-bottom: 10px;
      }
    }
    .centered-row {
      width: 100%;
      @include flex(column, center, center);
      font-size: 16px;
      font-weight: bold;
      margin: 10px 0;
      .highlight {
        padding: 10px 20px;
        color: $primary-color;
        background-color: rgba(53, 37, 228, 0.07);
        border-radius: 25px;
        margin-bottom: 10px;
      }
    }
  }
}
