.content-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      color: #3f46f6 !important;
    }
  }

  .p-inputgroup-addon {
    padding: 0.8rem;
    background: #e1e1e1;
    svg {
      height: 25px;
      width: 25px;
    }
  }

  .wire-deposit-text-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    p:last-child {
      font-weight: 600;
    }
  }

  .basic-wire-deposit-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    > p {
      text-align: center;
      font-size: 1.5rem;
      font-weight: 600;
    }

    > div:last-child {
      border-bottom: none;
    }

    .wire-deposit-content {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      border-bottom: 1px solid #cac8c8;
      padding-bottom: 1rem;
    }

    .wire-deposit-subtitle {
      color: gray;
    }

    .wire-deposit-info {
      font-size: 0.9rem;
      font-weight: 600;
    }

    .wire-deposit-subInfo {
      border-top: 1px dashed black;
      padding-top: 0.5rem;
      margin-top: 0.5rem;
      color: #6c6262;
    }
  }

  .wire-deposit-button {
    width: 100%;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;

    .p-button-icon {
      font-size: 1rem !important;
    }

    .p-button-label {
      flex: none;
    }
  }
}

.group-header-container {
  display: flex;
  gap: 0.5rem;
  align-items: center;

  &.approved {
    color: green;
  }

  &.rejected {
    color: red;
  }
  &.pending {
    color: orange;
  }

  i {
    font-size: 1rem !important;
  }
}

// popup and third library css override

.p-dropdown-panel {
  .p-dropdown-items {
    .p-dropdown-item-group {
      padding: 1rem 0.5rem 0.5rem 0.5rem !important;
    }
  }
}

.add-bank-account-model {
  .add-bank-submit-button {
    &.p-button {
      @media screen and (max-width: 500px) {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .p-button-label {
        @media screen and (max-width: 500px) {
          flex: none;
        }
      }
    }
  }
  .radios-container {
    display: flex;
    gap: 2rem;
    .single-radio-button {
      display: flex;
      justify-content: center;
      align-items: center;
      label {
        margin-bottom: 0rem;
      }
    }
  }

  .input-container {
    input,
    span {
      width: 100%;
    }
    label {
      margin-bottom: 0.3rem;
    }
  }

  .p-dialog-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .p-dialog-footer {
    button {
      margin: 0rem !important;
      background: rgb(54, 39, 228);
      color: white;
    }

    .p-button:enabled:active,
    .p-button:enabled:focus {
      background: rgb(54, 39, 228);
      color: white;
    }

    .p-button-icon {
      font-size: 1rem !important;
    }

    .p-button {
      padding: 0.714rem 2.8rem;
    }
  }

  .p-toast-message-content {
    padding: 0.5rem !important;
  }
}

.confirm-model {
  .confirm-container {
    padding-bottom: 2rem;
    p {
      display: flex;
      justify-content: space-between;
      font-weight: 600;

      span {
        font-weight: normal;
      }
    }
  }

  .text-container {
    text-align: center;
    color: #3627e4;
    padding-bottom: 1rem;
  }
}

.transfer-model {
  @media screen and (max-width: 1000px) {
    width: 75vw !important;
  }
  .success-text {
    text-align: center;
    font-size: 1rem;
    padding-bottom: 1rem;
  }
  .step-one-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-align: center;
    .phone-text {
      font-weight: 600;
      font-size: 1.2rem;
    }
  }

  .step-two-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-align: center;
    // > p {
    //   text-align: center;
    // }

    .phone-text {
      font-weight: 600;
      font-size: 1.2rem;
    }
  }

  .step-one-container,
  .step-two-container {
    .no-icon {
      margin-right: 0rem;
    }
  }

  .submit-button {
    display: flex;
    justify-content: center;
    align-items: center;

    .pi {
      font-size: 1rem !important;
    }
    .p-button-label {
      flex: none;
    }
  }
}

.validation-code-container {
  .vi__wrapper {
    width: 100%;
  }
  .container {
    margin-bottom: 20px;
  }
  .character {
    border: none;
    border-bottom: 2px solid black;
    background-color: white;
    outline: none;
    &.selected {
      border: none;
      border-bottom: 2px solid blue;
    }
    &.inactive {
      border: none;
      border-bottom: 2px solid gray;
    }
  }
}
.bank-account-template-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.link-external-account-dialog {
  max-width: 540px;
  .buttons {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin: 10px 0;
  }
}