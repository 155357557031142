.linked-accounts-dialog {
  @media screen and (max-width: 600px) {
    width: 90vw;
  }

  .p-dialog-header {
    background: #3627e4 !important;
    color: white !important;
    display: none;
  }

  .p-dialog-content {
    background: linear-gradient(
      180deg,
      #3627e4 30.73%,
      #0096c6 100%
    ) !important;
    color: white !important;

    display: flex;
    flex-direction: column;
    gap: 1rem;

    > h1,
    > p {
      text-align: center;
    }

    > h1 {
      padding-top: 1rem;
    }

    > span {
      color: white;
      text-align: center;
      font-size: 0.9375rem;
      font-weight: 400;
      line-height: normal;
      text-decoration-line: underline;
      margin-bottom: 1rem;
      cursor: pointer;
    }

    .same-accounts-container {
      border-radius: 24px;
      background: #fff;
      color: black;
      padding: 1rem;

      .header {
        color: #393939;
        text-align: center;
        font-size: 1.1875rem;
        font-weight: 400;
        padding-bottom: 1rem;
      }
      .single-type-account {
        .acct-info {
          display: flex;
          justify-content: space-between;
          gap: 0.5rem;
        }

        .subtype-name {
          color: #393939;
          font-size: 0.75rem;
          font-weight: 400;
        }
      }

      > div {
        border-bottom: 1px solid gray;
        padding: 0.5rem 0rem;
      }

      > div:last-child {
        border-bottom: none;
      }

      > div:first-child {
        padding-top: 0rem;
      }
    }

    .add-more-account-container {
      border-radius: 999px;
      border: 0.25px solid rgba(0, 0, 0, 0.35);
      background: #3728e4;
      text-align: center;
      padding: 1rem;
      color: #fffeff;
      font-size: 1.25rem;
      font-weight: 400;
      line-height: normal;
      cursor: pointer;
      // margin-bottom: 2rem;
    }
  }
}

.p-component-overlay {
  backdrop-filter: blur(20px);
}
