@import '../reuse/mixins.scss';
@import '../reuse/variables.scss';

.account-main-container {
  height: auto;
  width: 100%;
  padding: 25px 25px;
  .account-info-container {
    flex: 1;
    width: 100%;
    margin-top: 50px;
    @include flex(column, center, center);
    .account-wrapper {
      width: 100%;
      max-width: 1066px;
      flex: 1;
      @include flex(column, center, center);
      .account-side-bar {
        width: 100%;
        height: auto;
        margin-bottom: 50px;
        @include flex(row, space-between, center);
        // box-shadow: 0px 4px 14px -5px rgba(0, 0, 0, 0.3);
        // -webkit-box-shadow: 0px 4px 14px -5px rgba(0, 0, 0, 0.3);
        // -moz-box-shadow: 0px 4px 14px -5px rgba(0, 0, 0, 0.3);
        // border-radius: 10px;
        // overflow: hidden;
        // margin-right: 25px;
        @media screen and (max-width: 769px) {
          width: 100%;
          overflow-x: scroll;
          // scrollbar-width: none; /* for Firefox */
          // &::-webkit-scrollbar {
          //   display: none; /* for Chrome, Safari, and Opera */
          // }
          white-space: nowrap;
        }
      }
      .bar {
        height: 30px;
        width: auto;
        border-radius: 25px;
        @include flex(column, center, flex-start);
        padding: 0 10px;
        cursor: pointer;
        &:not(:last-child) {
          border-bottom: 2px solid #f6f6f6;
        }
        &:hover {
          background-color: #ddd;
        }
        p {
          padding: 0;
          margin: 0;
        }
        &.selected {
          background-color: #ddd;
        }
      }
      .account-main-section {
        width: 100%;
        height: 100%;
        padding: 0 50px;
        .loading-spinner {
          height: 100%;
          width: 100%;
          @include flex(column, center, center);
        }
        .personal-info-row {
          display: flex;
          justify-content: space-between;
          @media screen and (max-width: 769px) {
            flex-direction: column;
          }

          .personal-info-value {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .section-title {
          font-size: 28px;
          font-weight: bold;
        }
        .link-list-container {
          flex: 1;
          .link-container {
            height: 50px;
            width: 100%;
            border-bottom: 1px solid black;
            @include flex(row, flex-start, center);
            text-decoration: none;
            color: black;
            padding: 0 10px;
            p {
              margin: 0;
              padding: 0;
            }
            &:hover {
              background-color: #ddd;
            }
          }
        }
        .link-accounts-container {
          width: 100%;
          @include flex(column, center, center);
          .top-container {
            @include flex(row, center, center);
            height: 40px;
            width: 100%;
            margin-bottom: 25px;
            .toggle-container {
              @include flex(row, center, center);
              height: 100%;
              width: 300px;
              background-color: #ddd;
              border-radius: 10px;
              overflow: hidden;
              div {
                flex: 1;
                height: 100%;
                padding: 0 10px;
                @include flex(column, center, center);
                &.selected {
                  background-color: $primary-color;
                  color: white;
                }
              }
            }
            .add-remove-container {
              @include flex(row, space-between, center);
            }
          }
          .linked-accounts {
            .account-block {
              // width: 100%;
              // max-width: 350px;
              width: 348px;
              height: 83px;
              box-shadow: -3px -2px 4px rgba(255, 255, 255, 0.92),
                3px 1px 5px rgba(86, 86, 86, 0.2),
                inset 0px 0px 2px rgba(255, 255, 255, 0.21);
              border-radius: 19px;
              background-color: rgba(255, 255, 255, 0.4);
              padding: 16px;
              margin: 25px 0;
              display: flex;
              justify-content: space-between;
              align-items: center;
              p {
                &:first-child {
                  font-weight: bold;
                  font-size: 20px;
                  margin: 0;
                }
              }
              .remove-account-icon {
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
}

//DIALOG CONTENT CANNOT BE NESTED
.link-or-unlink-dialog {
  display: flex;
  justify-content: space-evenly;
}

.faq-link {
  width: 100%;
  text-align: center;
  padding: 15px;
}
