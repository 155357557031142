@import '../reuse/mixins.scss';
@import '../reuse/variables.scss';

.dw-apex-questionnaire {
  width: 100%;
  .dw-apex-columns-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    .dw-apex-question-container {
      margin-bottom: 25px;
      .dw-apex-header {
        font-size: 15px;
      }
      .dw-apex-subheader {
        font-size: 12px;
        color: rgba(128, 128, 128);
      }
      .input-row {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .half-input {
          width: 48%;

          .p-inputnumber {
            width: 100%;
          }
        }
      }
      .disclosure {
        padding: 15px 0;
        .disclosure-header {
          font-size: 15px;
        }
        .disclosure-info {
          font-size: 12px;
          color: rgba(128, 128, 128, 1);
        }
        .radio-input-row {
          display: flex;
          margin-bottom: 15px;
          .radio-input-container {
            display: flex;
            .radio-input-label {
              margin: 0 25px 0 5px;
            }
            .p-radiobutton .p-radiobutton-box.p-highlight {
              border-color: $primary-color;
            }
            .p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
              background: $primary-color;
            }
          }
        }
        .upload-success {
          color: $primary-color;
        }
        .upload-error {
          color: red;
        }
      }
      .radio-input-row {
        display: flex;
        margin-bottom: 15px;
        .radio-input-container {
          display: flex;
          .radio-input-label {
            margin: 0 25px 0 5px;
          }
          .p-radiobutton .p-radiobutton-box.p-highlight {
            border-color: $primary-color;
          }
          .p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
            background: $primary-color;
          }
        }
      }
      .agreement-row {
        display: flex;
        margin: 10px 0;
        align-items: center;
        .agreement-label {
          margin: 0 0 0 15px;
          span {
            text-decoration: underline;
            font-family: Akkurat-Bold;
            cursor: pointer;
          }
        }
        .p-checkbox .p-checkbox-box.p-highlight {
          border-color: $primary-color;
          background: $primary-color;
        }
      }
      .spaced-container {
        margin-bottom: 15px;
      }
      .p-inputtext {
        width: 100%;
      }
      .p-calendar {
        width: 100%;
      }
      .p-dropdown {
        width: 100%;
      }
    }
    .dw-apex-submit-btn-container {
      width: 50%;
      margin: auto;
      .dw-apex-submit-btn {
        width: 100%;
        border-radius: 25px;
        background: $primary-color;
        color: white;
      }
    }
  }
  .error-msg {
    color: red;
    text-align: center;
    margin-top: 15px;
  }
  .success-msg {
    color: $primary-color;
    text-align: center;
    margin-top: 15px;
  }
}

.disclosures-sidebar {
  .pi {
    font-size: 1.5rem !important;
  }
}
