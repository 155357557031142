@import '../reuse/mixins.scss';
@import '../reuse/variables.scss';

.buy-or-sell-form-container {
  box-shadow: 0px 2px 5px 0px #c4c4c4;
  border-radius: 15px;

  @media screen and (max-width: 769px) {
    margin: 25px 0;
  }

  .recur-buy-msg {
    text-align: center;
    color: $primary-color;
    font-size: 15px;
    cursor: pointer;
    padding: 10px;
    margin: 0;
  }
  .header-container {
    border-bottom: 1px solid rgba(128, 128, 128, 0.2);
    display: flex;
    .back-arrow-btn {
      padding: 10px 10px 10px 15px;
      cursor: pointer;
    }
    .header {
      font-weight: bold;
      font-size: 18px;
      margin-bottom: 0;
      padding: 20px 25px 20px 25px;
      color: grey;
      cursor: pointer;
    }
    .selected {
      color: $primary-color;
      font-size: 25px;
    }
    .not-selected {
      font-size: 25px;
    }
  }
  .form-container {
    padding: 5px 25px 25px 25px;

    .extended-hours-checkbox-container {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
      p {
        margin: 0 0 0 10px;
      }
    }

    .order-type-form-container {
      display: flex;
      align-items: center;
      margin: 15px 0;
      .label {
        margin: 0;
        width: 40%;
        font-size: 17px;
      }
      .order-type-form {
        width: 60%;
        .p-dropdown {
          width: 100%;
        }
      }
    }
    .limit-price-form-container {
      display: flex;
      align-items: center;
      margin: 15px 0;
      .label {
        margin: 0;
        width: 40%;
        font-size: 17px;
      }
      .limit-price-form {
        width: 60%;
      }
    }
    .investment-type-form-container {
      display: flex;
      align-items: center;
      margin: 15px 0;
      .label {
        margin: 0;
        width: 40%;
        font-size: 17px;
      }
      .investment-form {
        width: 60%;
        .p-dropdown {
          width: 100%;
        }
      }
    }
    .amount-form-container {
      display: flex;
      align-items: center;
      margin: 15px 0;
      .label {
        margin: 0;
        width: 40%;
        font-size: 17px;
      }
      .amount-form {
        width: 60%;
        .p-inputnumber {
          width: 100%;
        }
      }
    }
    .shares-form-container {
      display: flex;
      align-items: center;
      margin: 15px 0;
      .label {
        margin: 0;
        font-size: 17px;
        width: 40%;
      }
      .shares-form {
        width: 60%;
        .p-inputnumber {
          width: 100%;
        }
      }
    }
    .estimated-cost-container {
      display: flex;
      margin: 20px 0;
      justify-content: space-between;
      .label {
        font-size: 17px;
        font-weight: bold;
        margin: 0;
        width: 60%;
      }
      .value {
        width: 40%;
        text-align: right;
        margin: 0;
      }
    }
    .estimated-quantity-container {
      margin: 20px 0;
      display: flex;
      .label {
        font-size: 17px;
        font-weight: bold;
        margin: 0;
        width: 60%;
      }
      .value {
        width: 40%;
        text-align: right;
        margin: 0;
      }
    }
    .submit-btn-container {
      margin: 15px 0 0 0;
      width: 100%;
      display: flex;
      justify-content: center;
      .review-btn {
        width: 80%;
        border-radius: 25px;
        background: $primary-color;
        color: white;
      }
    }
    .error {
      text-align: center;
      color: red;
    }
  }
  .buying-power-container {
    border-top: 1px solid rgba(128, 128, 128, 0.2);
    padding: 20px 25px 20px 25px;
    display: flex;
    justify-content: center;
    .buying-power {
      text-align: center;
      color: $primary-color;
      margin: 0;
      font-size: 15px;
      .buying-power-label {
        color: black;
        margin-right: 5px;
      }
      .dash {
        margin: 0px 3px 0px 7px;
      }
    }
    .sell-all-btn {
      border: none;
      background-color: transparent;
      color: $primary-color;
      padding: 0;
      font-size: 15px;
    }
  }
  .limit-order-message-container {
    text-align: center;
    padding: 15px 0 0 0;
    margin: 0;

    .limit-order-message {
      margin: 0;
    }
  }
  .after-hours-container {
    text-align: center;
    font-size: 13px;
  }
  .summary-container {
    padding: 15px;
    .summary-row {
      display: flex;
      justify-content: space-between;
      margin: 15px 0;
    }
    .summary-label {
      margin: 0;
      font-size: 15px;
    }
    .summary-value {
      margin: 0;
      font-size: 15px;
      font-weight: normal;
    }
    .blue-text {
      color: $primary-color;
    }
    .final-action-btn-container {
      margin: 25px 0 15px 0;
      width: 100%;
      display: flex;
      justify-content: space-evenly;
      .edit-btn {
        width: 40%;
        border-radius: 25px;
        background: $primary-color;
        color: white;
      }
      .final-submit-btn {
        width: 80%;
        border-radius: 25px;
        margin: auto;
        background: $primary-color;
        color: white;
      }
    }
    .show-details {
      text-align: center;
      color: $primary-color;
      cursor: pointer;
      font-size: 15px;
    }
  }
}

.gfv-message {
  width: 100%;
  font-size: 15px;
}
