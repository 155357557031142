@import "../reuse/mixins.scss";
@import "../reuse/variables.scss";

.largeScreenWrapper__container {
  width: 100%;
  max-width: 1920px;
  height: 100%;
  @include flex(column, center, center);
}

.fullscreen__container {
  width: 100%;
  height: 100vh;
  max-height: 1920px;
  @include flex(column, center, center);
  @media screen and (max-width: 1000px) {
    min-height: 120vh;
  }
}

.center-container {
  width: 100%;
  @include flex(column, center, center);
}

.gradientBackground {
  background: linear-gradient(
    45deg,
    rgba(255, 249, 217, 1) 0%,
    rgba(222, 168, 198, 1) 100%
  );
}

.hero-title {
  font-size: 88px;
  font-family: "Akkurat-Light";
  margin: 0;
  line-height: 98px;
  letter-spacing: -2px;
  @media screen and (max-width: 1366px) {
    font-size: 36px;
    line-height: 30px;
  }
}

.hero-sub-text {
  margin-top: 5%;
  font-size: 25px;
  font-family: "Akkurat-Thin";
  @media screen and (max-width: 1440px) {
    font-size: 20px;
  }
}

.title-text {
  font-size: 40px;
  @media screen and (max-width: 1440px) {
    font-size: 42px;
  }
  @media screen and (max-width: 1000px) {
    font-size: 30px;
  }
}

.large-text {
  font-size: 30px;
  @media screen and (max-width: 1440px) {
    font-size: 24px;
  }
  @media screen and (max-width: 1000px) {
    font-size: 24px;
  }
}

.logo-text {
  font-size: 35px;
  // color: black;
  font-family: "Akkurat-Thin";
  margin: 0;
  font-weight: bold;
  text-decoration: none;
  // @media screen and (max-width: 600px) {
  //   font-family: "Akkurat-";
  // }
}

.bold-text {
  font-weight: bold;
}

.primary-color-text {
  color: $primary-color;
}


// .title__text {
//   font-size: 3rem;
//   line-height: 3.25rem;
//   @media screen and (max-width: 1000px) {
//     font-size: 2rem;
//     line-height: 2.5rem;
//   }
// }
// .subtitle__text {
//   font-size: 1.5rem;
//   width: 100%;
// }