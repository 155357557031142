@import '../reuse/mixins.scss';
@import '../reuse/variables.scss';

.signup-container {
  min-height: 100vh;
  min-width: 100vw;
  background-color: $primary-color;
  @include flex(row, center, center);

  .signup-form-container {
    @media only screen and (max-width: 769px) {
      width: 100%;
    }
    .signup-header {
      line-height: 43px;
      font-size: 53px;
      color: white;
      text-align: center;
      margin-bottom: 35px;

      @media only screen and (max-width: 769px) {
        line-height: 23px;
        font-size: 30px;
        margin-bottom: 30px;
        margin-top: 18%;
      }
    }
    .signup-form {
      padding: 35px;
      background-color: white;
      border-radius: 5px;
      width: 500px;
      overflow: auto;
      max-height: 700px;
      @media only screen and (max-width: 769px) {
        min-width: auto;
        width: 90%;
        padding: 20px;
        margin: auto;
      }
      .or-text {
        font-size: 17px;
        color: black;
        text-align: center;
      }
      .signup-input {
        font-size: 17px;
        padding: 10px;
        border-radius: 5px;
        border: 1px solid rgba(128, 128, 128, 0.5);
        width: 100%;
        height: 64px;
      }
      .recaptcha-container {
        @include flex(row, center, center);
        width: 100%;
        margin-top: 20px;
      }
      .signup-btn-container {
        @include flex(row, flex-end, center);
        @media only screen and (max-width: 769px) {
          justify-content: center;
        }
        .signup-button {
          border: none;
          width: 163px;
          height: 64px;
          background-color: $primary-color;
          color: white;
          font-weight: bold;
          font-size: 22px;
          border-radius: 5px;
          text-transform: uppercase;
          margin-top: 25px;
        }
      }
      .signup-footer {
        width: 100%;
        text-align: center;
        margin-bottom: 0;
        .signin-link {
          color: $primary-color;
          cursor: pointer;
        }
      }
      .phone-input-container {
        width: 100%;

        .phone-input {
          width: 100%;
          height: 64px;
          font-size: 17px;
          border-radius: 5px;
        }
      }
      .agreement-checkbox {
        margin-right: 10px;
      }
      .check__container {
        a {
          color: $primary-color;
        }
      }
    }
    .error {
      margin: 0;
      margin-top: 30px;
    }
    .success {
      margin: 0;
      margin-top: 30px;
    }
  }
  .space-top {
    margin-top: 15px;
  }
  .space-bottom {
    margin-bottom: 15px;
  }
}
