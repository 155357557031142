@import '../../reuse/mixins.scss';
@import '../../reuse/variables.scss';
.add-account-forms {
    @include flex(column, center, center);
    .add-account-header {
        font-size: 25px;
        font-family: 'Akkurat-Bold';
    }
    .add-account-card {
        cursor: pointer;
        margin-bottom: 25px;
    }
    .vehicle-option {
        width: 250px;
        height: 50px;
        border-radius: 25px;
        background-color: $primary-color;
        margin-bottom: 35px;
        cursor: pointer;
        @include flex(row, center, center);
        .vehicle-label {
            font-size: 25px;
            color: white;
            margin: 0;
        }
    }
    .input-container {
        margin: 15px 0;
        width: 100%;
    }
    .property-input-container {
        margin: 15px 0;
        width: 100%;
        position: relative;
        @include flex(column, center, center);
        .small-height {
            height: 30px;
        }
        .big-height {
            height: 60px;
        }
        .suggestions {
            background-color: white;
            border-radius: 19px;
            padding: 25px;
            box-shadow: rgb(255 255 255 / 92%) -3px -2px 4px, rgb(86 86 86 / 20%) 3px 1px 5px, rgb(255 255 255 / 21%) 0px 0px 2px inset;
            width: 100%;
            max-height: 300px;
            overflow-y: auto;
            position: absolute;
            top: 90px;
            z-index: 999;
            .suggestions-close {
                position: absolute;
                top: 0;
                right: 0;
                margin: 15px;
                font-size: 20px;
                cursor: pointer;
            }
            .suggestions-row {
                cursor: pointer;
                border-bottom: 1px solid rgba(128, 128, 128, 0.3);
                padding: 15px 0;
                .suggestion-item {
                    font-size: 15px;
                    margin: 0;
                }
            }
        }
    }
    .input-label {
        font-size: 20px;
        margin: 0;
        text-align: left;
        width: 100%;
    }
    .add-account-form-btn {
        width: 150px;
        height: 50px;
        border-radius: 35px;
        background-color: $primary-color;
        color: white;
        border: none;
        margin-top: 25px;
    }
    .add-account-input {
        width: 100%;
    }
    .add-account-input-fixed {
        width: 100%;
    }
}