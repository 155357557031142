@import '../reuse/mixins.scss';
@import '../reuse/variables.scss';
.watchlist-selection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.selection-button {
    width: 150px;
    height: 50px;
    font-size: 12;
    color: white;
    background-color: $primary-color;
    border-radius: 35px;
    border: none;
    margin-bottom: 15px;
    margin-top: 25px;
}

.success {
    position: relative !important;
    top: 0 !important;
}