@import '../reuse/mixins.scss';
@import '../reuse/variables.scss';

// Standard styles

// Spacing
.spacer {
  width: 100%;
  height: 400px;
}
.navbar-spacer {
  height: 50px;
  width: 100%;
  @media screen and (min-width: 1000px) {
    height: 75px;
  }
}
.section-title {
  font-size: 30px;
  font-family: 'Akkurat-Bold';
  @media screen and (min-width: 1440px) {
    font-size: 40px;
  }
}

.full-screen-container {
  width: 100%;
  .background {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    &.white {
      background-color: white;
    }
    &.turquoise {
      background-color: #0498c7;
    }
    &.gray {
      background-color: #f5f5f5;
    }
    &.dark-blue {
      background-color: #1a1d35;
    }
    .center-container {
      width: 100%;
      max-width: 1400px;
    }
  }
  .content {
    font-size: 17px;
  }
  .desktop-row {
    display: flex;
    flex-direction: column;
    &.reverse {
      flex-direction: column-reverse;
    }
    @media screen and (min-width: 1000px) {
      flex-direction: row;
      justify-content: space-between;
      &.reverse {
        flex-direction: row;
      }
      &.short {
        padding: 0 100px;
      }
    }
  }
  .icon-title-container {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    img {
      height: 60px;
      width: 60px;
      object-fit: contain;
    }
    @media screen and (min-width: 1000px) {
      justify-content: start;
    }
  }
  .text-content {
    text-align: center;
    @media screen and (min-width: 1000px) {
      text-align: left;
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: center;
      &.end {
        align-items: end;
      }
      p {
        max-width: 450px;
      }
    }
  }
  .single-screenshot {
    width: 100%;
    @include flex(row, center, center);
    img {
      max-width: 300px;
    }
    @media screen and (min-width: 1000px) {
      max-width: 300px;
    }
  }
  .side {
    &.center {
      @include flex(row, center, center);
    }
  }
  .carousel-container {
    width: 100%;
    max-width: 300px;
    .image-container {
      width: 100%;
      img {
        width: 100%;
        object-fit: contain;
      }
    }
    .p-carousel-prev {
      display: none;
    }
    .p-carousel-next {
      display: none;
    }
    .p-carousel .p-carousel-indicators .p-carousel-indicator {
      margin-right: 0.25rem;
      margin-left: 0.25rem;
    }
    .p-carousel-indicator button {
      width: 0.5rem !important;
      height: 0.5rem !important;
      border-radius: 0.5rem !important;
      margin-left: 0.25rem !important;
      margin-right: 0.25rem !important;
    }
    .p-carousel
      .p-carousel-indicators
      .p-carousel-indicator.p-highlight
      button {
      background-color: #1a1d35;
    }
    @media screen and (min-width: 1000px) {
      max-width: 300px;
      .image-container {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      img {
        max-width: 300px;
      }
    }
  }
}

.my-finances-section {
  width: 100%;
  padding: 40px;
  width: 100%;
  text-align: center;
  @include flex(column, start, center);
  gap: 45px;
  .investall-logo {
    height: 60px;
    width: 60px;
    object-fit: contain;
  }
  .banner-image {
    width: 100%;
    object-fit: contain;
  }
  @media screen and (min-width: 1000px) {
    padding: 70px 280px;
    gap: 45px;
    .investall-logo {
      height: 90px;
      width: 90px;
      object-fit: contain;
    }
  }
}

.dynamic-spending-section {
  color: white;
  padding: 40px;
  width: 100%;
  text-align: center;
  .scale-image {
    width: 100%;
    object-fit: contain;
  }
  @media screen and (min-width: 1000px) {
    padding: 100px 200px;
  }
}

.insights-section {
  padding: 40px;
  width: 100%;
  @media screen and (min-width: 1000px) {
    padding: 100px 200px;
  }
}

.unwanted-subscriptions-section {
  padding: 40px;
  width: 100%;
  img {
    width: 100%;
    object-fit: contain;
  }
  @media screen and (min-width: 1000px) {
    padding: 100px 200px;
  }
}

.auto-trade-section {
  text-align: center;
  padding: 40px;
  width: 100%;
  color: white;
  .icon-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    max-width: 400px;
    margin-top: 40px;
    .grid-element {
      width: 150px;
      height: 175px;
      gap: 20px;
      text-align: center;
      @include flex(column, start, center);
      font-size: 18px;
      img {
        height: 80px;
        width: 80px;
        object-fit: contain;
      }
    }
  }
  @media screen and (min-width: 1000px) {
    padding: 230px 200px;
    text-align: left;
    .text {
      text-align: left;
      max-width: 450px;
    }
  }
}

.self-trading-section {
  text-align: center;
  padding: 40px;
  width: 100%;
  .feature-lists {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    .feature-list {
      list-style: none;
      .list-header {
        font-size: 21px;
      }
      li p {
        font-size: 14px !important;
      }
    }
  }
  @media screen and (min-width: 1000px) {
    .feature-lists {
      flex-direction: row;
    }
    padding: 100px 200px;
  }
}

.personal-ai-section {
  padding: 40px;
  width: 100%;
  text-align: center;
  .screenshot-w-caption {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    p {
      font-size: 24px;
      color: #3627e4;
    }
    img {
      width: 100%;
      object-fit: contain;
    }
  }
  @media screen and (min-width: 1000px) {
    padding: 100px 200px;
  }
}

.ai-trader-section {
  padding: 40px;
  width: 100%;
  color: white;
  text-align: center;
  img {
    width: 100%;
  }
  @media screen and (min-width: 1000px) {
    padding: 100px 200px;
  }
}

.robo-section {
  padding: 40px;
  width: 100%;
  text-align: center;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  img {
    width: 100%;
  }
  .robo-cards {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .card {
      border-radius: 34px;
      background-color: white;
      border: none;
      padding: 20px;
      width: 100%;
      p {
        font-size: 17px;
      }
      .card-title {
        font-weight: 700;
        font-size: 30px;
      }
    }
  }

  .slider-side {
    flex: 2;
    height: 400px;
    text-align: left;
    .label-text {
      font-size: 22px;
      font-family: 'Akkurat-Bold';
    }
    .amount {
      font-size: 28px;
      margin-bottom: 10px;
    }
  }
  .graph-side {
    flex: 4;
    max-height: 300px;
    position: relative;
    .custom-legend {
      position: absolute;
      bottom: 10px;
      left: 0;
      font-size: 20px;
      .legend-row {
        display: flex;
        @include flex(row, start, center);
        gap: 8px;
        .color {
          height: 16px;
          width: 16px;
          border-radius: 16px;
          &.purple {
            background-color: #716FF6;
          }
          &.gray {
            background-color: #888888;
          }
        }
      }
    }
  }
  .chart-disclaimer {
    font-size: 12px;
  }

  .slider-container {
    width: 100%;
    margin-bottom: 20px;
  }
  input[type='range'] {
    height: 27px;
    -webkit-appearance: none;
    width: 100%;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
  input[type='range']:focus {
    outline: none;
  }
  input[type='range']::-webkit-slider-runnable-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px #000000;
    background: rgba(128, 128, 128, 0.5);
    border-radius: 5px;
    border: 0px solid #000000;
  }
  input[type='range']::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px #000000;
    border: 2px solid black;
    height: 18px;
    width: 18px;
    border-radius: 25px;
    background: #716FF6;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -9px;
  }
  input[type='range']:focus::-webkit-slider-runnable-track {
    background: rgba(128, 128, 128, 0.5);
  }
  input[type='range']::-moz-range-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px #000000;
    background: rgba(128, 128, 128, 0.5);
    border-radius: 5px;
    border: 0px solid #000000;
  }
  input[type='range']::-moz-range-thumb {
    box-shadow: 0px 0px 0px #000000;
    border: 2px solid black;
    height: 18px;
    width: 18px;
    border-radius: 25px;
    background: #716FF6;
    cursor: pointer;
  }
  input[type='range']::-ms-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
  input[type='range']::-ms-fill-lower {
    background: black;
    border: 0px solid #000000;
    border-radius: 10px;
    box-shadow: 0px 0px 0px #000000;
  }
  input[type='range']::-ms-fill-upper {
    background: black;
    border: 0px solid #000000;
    border-radius: 10px;
    box-shadow: 0px 0px 0px #000000;
  }
  input[type='range']::-ms-thumb {
    margin-top: 1px;
    box-shadow: 0px 0px 0px #000000;
    border: 2px solid black;
    height: 18px;
    width: 18px;
    border-radius: 25px;
    background: #716FF6;
    cursor: pointer;
  }
  input[type='range']:focus::-ms-fill-lower {
    background: rgba(128, 128, 128, 0.5);
  }
  input[type='range']:focus::-ms-fill-upper {
    background: rgba(128, 128, 128, 0.5);
  }
  .space-bottom {
    margin-bottom: 15px;
  }
  .space-bottom-big {
    margin-bottom: 35px;
  }

  @media screen and (min-width: 1000px) {
    padding: 100px 100px;
    .robo-cards {
      flex-direction: row;
    }
    .graph-side {
      min-height: 400px;
      max-height: 400px;
    }
  }
}

.goals-section {
  padding: 40px;
  width: 100%;
  @media screen and (min-width: 1000px) {
    padding: 100px 200px;
  }
}

.disclaimer-section {
  color: #333333;
  display: flex;
  flex-direction: column;
  padding: 40px;
  width: 100%;
  gap: 15px;
  p {
    font-size: 12px !important;
    margin-top: 0 !important;
    line-height: 14px !important;
    padding-top: 0 !important;
    letter-spacing: 0;
  }
  @media screen and (min-width: 1000px) {
    padding: 100px 200px;
  }
}
