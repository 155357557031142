@import '../reuse/mixins.scss';
@import '../reuse/variables.scss';

.deposit-ach-form {
  .ach-container {
    width: 100%;
    margin: 15px 0;
    @include flex(row, space-between, center);
    .ach-label {
      flex: 1;
      margin: 0;
    }
    .ach-input {
      flex: 3;
    }
    .ach-input-number {
      flex: 3.3;
    }
  }
  .bank-btn-container {
    @include flex(row, flex-end, center);
    .bank-btn {
      border-radius: 25px;
      background-color: $group-color;
      font-size: 12px;
    }
  }
  .btn-container {
    .submit-btn {
      border: none;
      background-color: $group-color;
      color: white;
      border-radius: 25px;
      text-align: center;
      padding: 10px;
    }
  }
  .succes-msg {
    text-align: center;
    color: $primary-color;
  }
}
